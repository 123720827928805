import { Outlet, ScrollRestoration } from '@tanstack/react-router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { Suspense } from 'react';
import {
  canExpandSidebarAtom,
  closeSidebarAtom,
  collapseSidebarAtom,
  expandSidebarAtom,
  openSidebarAtom,
  sidebarAtom
} from 'src/atoms';
import ErrorBoundary from '../ErrorBoundary';
import OfflineIndicator from '../OfflineIndicator';
import SidebarFloatingDrawer from '../Sidebar/SidebarFloatingDrawer';
import Sidebar from '../Sidebar/index';
import TopNav from '../TopNav';
import PageLoader from '../common/loading/PageLoader';

export default function MainPageLayout() {
  const { expanded, open } = useAtomValue(sidebarAtom);
  const [canExpand] = useAtom(canExpandSidebarAtom);
  const expandSidebar = useSetAtom(expandSidebarAtom);
  const collapseSidebar = useSetAtom(collapseSidebarAtom);
  const openSidebar = useSetAtom(openSidebarAtom);
  const closeSidebar = useSetAtom(closeSidebarAtom);

  return (
    <div
      className="grid h-full max-h-full min-h-0 w-full"
      style={
        !expanded
          ? {
              gridTemplateAreas: `'topNav' 'main'`,
              gridTemplateRows: 'auto 1fr',
              gridTemplateColumns: '1fr'
            }
          : {
              gridTemplateAreas: `'topNav topNav' 'sidebar main'`,
              gridTemplateRows: 'auto 1fr',
              gridTemplateColumns: 'auto 1fr'
            }
      }>
      <ScrollRestoration />
      <OfflineIndicator />
      <TopNav
        gridArea="topNav"
        onMenuClick={() => {
          if (canExpand && !expanded) expandSidebar();
          else if (canExpand && expanded) collapseSidebar();
          else if (!open) openSidebar();
          else closeSidebar();
        }}
        showSidebarToggle
      />
      {!expanded ? (
        <SidebarFloatingDrawer>
          <Sidebar adjustable />
        </SidebarFloatingDrawer>
      ) : (
        <Sidebar adjustable />
      )}
      <main
        style={{ gridArea: 'main' }}
        className="flex h-full w-full flex-col items-stretch overflow-y-auto overflow-x-hidden">
        <ErrorBoundary>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </main>
    </div>
  );
}
