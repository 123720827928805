import { BaseFieldTypeDesc, getDateFormatter } from './common';
export default class YearDesc extends BaseFieldTypeDesc {
    typeLabel = 'Year';
    dataLabel = 'Year';
    unitLabels = {
        epochMS: 'Epoch Milliseconds'
    };
    defaultScale = 'time';
    tickFormatter = getDateFormatter('yyyy');
    dataPointFormatter = getDateFormatter('yyyy');
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
