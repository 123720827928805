import { atom } from 'jotai';
import { routingAtom } from './history.atoms';
import { Dashboard } from 'src/types/entities';

type GlobalDialogType =
  | 'CreateDashboard'
  | 'UpdateDashboard'
  | 'CreateDataSource'
  | 'AccountPreferences';

type GlobalDialogPayload = {
  CreateDashboard: null;
  UpdateDashboard: Dashboard;
  CreateDataSource: {
    initialConnector: string;
  } | null;
  AccountPreferences: null;
};

type GlobalDialogState<T extends GlobalDialogType> = T extends keyof GlobalDialogPayload
  ? GlobalDialogPayload[T] extends null | undefined
    ? { type: T; payload?: null }
    : { type: T; payload: GlobalDialogPayload[T] }
  : never;

export const dialogAtom = atom<GlobalDialogState<GlobalDialogType> | null>(null);

export const updateDialogAtom = atom(
  null,
  (get, set, newDialog: GlobalDialogState<GlobalDialogType>) => set(dialogAtom, newDialog)
);

export const addWidgetAtom = atom(null, (get, set, dashboardId: string) => {
  const router = get(routingAtom);
  router?.navigate({ to: '/dash/$dashboardId/add', params: { dashboardId } });
});

export const editWidgetAtom = atom(
  null,
  (get, set, params: { dashboardId: string; widgetId: string }) => {
    const router = get(routingAtom);
    router?.navigate({ to: '/dash/$dashboardId/$widgetId/edit', params });
  }
);

export const closeDialogAtom = atom(null, (get, set) => set(dialogAtom, null));
