import { Widget, WidgetByType } from 'daydash-data-structures';
import React, { lazy } from 'react';
import { ViewWidgetProps } from '../types';

import {
  AreaChartIcon,
  BarChart3Icon,
  BarChartHorizontal,
  HashIcon,
  LineChartIcon,
  LucideIcon,
  PieChartIcon,
  SheetIcon,
  StickyNoteIcon
} from 'lucide-react';
import SankeyChartIcon from '../../common/icons/SankeyChartIcon';

/**
 * NOTE: Using lazy around Edit components ends up not being worth it because they are typically so
 * small and not having them causes weird layout jumps in certain spots. If an Edit
 * field is super bulky, then either use lazy for just that component or try to chunk out
 * what makes that Edit component bulky
 */
export default {
  LineChart: {
    View: lazy(() => import(/*webpackChunkName:'LineChartView'*/ './LineChart/View')),
    Edit: lazy(() => import('./LineChart/Edit')),
    Icon: LineChartIcon,
    defaults: {
      width: 4,
      height: 2
    }
  },
  AreaChart: {
    View: lazy(() => import(/*webpackChunkName:'AreaChartView'*/ './AreaChart/View')),
    Edit: lazy(() => import('./AreaChart/Edit')),
    Icon: AreaChartIcon,
    defaults: {
      width: 4,
      height: 2
    }
  },
  BarChart: {
    View: lazy(() => import(/*webpackChunkName:'BarChartView'*/ './BarChart/View')),
    Edit: lazy(() => import('./BarChart/Edit')),
    Icon: BarChart3Icon,
    defaults: {
      width: 4,
      height: 2
    }
  },
  CatBarChart: {
    View: lazy(() => import(/*webpackChunkName:'CatBarChartView'*/ './CatBarChart/View')),
    Edit: lazy(() => import('./CatBarChart/Edit')),
    Icon: BarChartHorizontal,
    defaults: {
      width: 3,
      height: 4
    }
  },
  DataTable: {
    View: lazy(() => import(/*webpackChunkName:'DataTableView'*/ './DataTable/View')),
    Edit: lazy(() => import('./DataTable/Edit')),
    Icon: SheetIcon,
    defaults: {
      width: 4,
      height: 2
    }
  },
  SingleValue: {
    View: lazy(() => import(/*webpackChunkName:'SingleValueView'*/ './SingleValue/View')),
    Edit: lazy(() => import('./SingleValue/Edit')),
    Icon: HashIcon,
    defaults: {
      width: 2,
      height: 2
    }
  },
  PieChart: {
    View: lazy(() => import(/*webpackChunkName:'PieChartView'*/ './PieChart/View')),
    Edit: lazy(() => import('./PieChart/Edit')),
    Icon: PieChartIcon,
    defaults: {
      width: 2,
      height: 2
    }
  },
  SankeyChart: {
    View: lazy(() => import(/*webpackChunkName:'SankeyChartView'*/ './SankeyChart/View')),
    Edit: lazy(() => import('./SankeyChart/Edit')),
    Icon: SankeyChartIcon,
    defaults: {
      width: 4,
      height: 2
    }
  },
  Note: {
    View: lazy(() => import(/*webpackChunkName:'NoteView'*/ './Note/View')),
    Edit: null,
    Icon: StickyNoteIcon,
    defaults: {
      width: 2,
      height: 2
    }
  }
} satisfies {
  [T in Widget['type']]: {
    View: React.ComponentType<ViewWidgetProps<WidgetByType<T>>>;
    Edit: React.ComponentType<void> | null;
    Icon: LucideIcon;
    defaults: {
      width: number;
      height: number;
    };
  };
};

export const NullComponents = {
  View: (...params: any[]) => null,
  Edit: (...params: any[]) => null
};
