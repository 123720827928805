import { FieldApi } from '@tanstack/react-form';

export const getFieldDisplayErrors = (
  fieldApi: FieldApi<any, any, any, any>,
  allowTouched = false
) => {
  if (
    fieldApi.state.meta.isBlurred ||
    fieldApi.form.state.isSubmitted ||
    (allowTouched && fieldApi.state.meta.isTouched)
  ) {
    return fieldApi.state.meta.errors;
  }
  return null;
};
