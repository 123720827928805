import { createLucideIcon } from 'lucide-react';

const SankeyChartIcon = createLucideIcon('SankeyChartIcon', [
  ['path', { key: '1', stroke: 'none', d: 'M0 0h24v24H0z', fill: 'none' }],
  ['path', { key: '2', d: 'M3 3v18h18' }],
  ['path', { key: '3', d: 'M3 6h18' }],
  ['path', { key: '4', d: 'M3 8c10 0 8 9 18 9' }]
]);

export default SankeyChartIcon;
