import { Dispatch, useCallback, useEffect, useState } from 'react';

// Originally copied from https://github.com/dance2die/react-use-localstorage
export function useStorage(
  key: string,
  initialValue = '',
  enabled = true,
  storage: Storage = window.localStorage
): [string, Dispatch<string>] {
  const [value, setValue] = useState(() => (enabled && storage.getItem(key)) || initialValue);

  const setItem = (newValue: string) => {
    if (newValue !== value) {
      setValue(newValue);
      storage.setItem(key, newValue);
    }
  };

  useEffect(() => {
    if (!enabled) return;
    const newValue = storage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  });

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [value]
  );

  useEffect(() => {
    if (!enabled) return;
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage, enabled]);

  return [value, setItem];
}

export function useStorageBoolean(
  key: string,
  initialValue: boolean,
  enabled?: boolean,
  storage: Storage = window.localStorage
): [boolean, Dispatch<boolean>] {
  const [val, setVal] = useStorage(key, String(initialValue), enabled, storage);
  const setBool = useCallback(
    (newVal: boolean) => {
      setVal(String(newVal));
    },
    [setVal]
  );
  return [val === 'true', setBool];
}

export function useStorageNumber(
  key: string,
  initialValue: number,
  enabled?: boolean,
  storage: Storage = window.localStorage
): [number, Dispatch<number>] {
  const [val, setVal] = useStorage(key, String(initialValue), enabled, storage);
  const setBool = useCallback(
    (newVal: number) => {
      setVal(String(newVal));
    },
    [setVal]
  );
  return [parseFloat(val), setBool];
}
