import React, { useEffect, useState } from 'react';
import {
  CommandDialog,
  CommandList,
  CommandInput,
  CommandEmpty,
  CommandGroup,
  CommandItem
} from 'axil-web-ui';
import { useAtom } from 'jotai';
import { commandsAtom } from 'src/atoms';

export default function CommandPalette() {
  const [open, setOpen] = React.useState(false);
  const [commands] = useAtom(commandsAtom);
  const commandInput = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (open) commandInput.current?.focus();
    else setInputValue('');
  }, [open]);
  return (
    <CommandDialog isOpen={open} onClose={() => setOpen(false)} modal={false}>
      <CommandInput
        placeholder="Type a command or search..."
        ref={commandInput}
        value={inputValue}
        onValueChange={setInputValue}
      />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        {commands.map(group => (
          <CommandGroup heading={group.label} key={group.id}>
            {group.commands.map(command => (
              <CommandItem
                key={command.id}
                keywords={command.keywords}
                onSelect={() => {
                  command.handler();
                  setOpen(false);
                }}>
                {command.label}
              </CommandItem>
            ))}
          </CommandGroup>
        ))}
      </CommandList>
    </CommandDialog>
  );
}
