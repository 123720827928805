import { FormApi, FormState } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { isNotEmpty } from 'axil-utils';
import { DataSourceBuilder } from 'daydash-data-structures';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { atomWithMutation } from 'jotai-tanstack-query';
import { cloneDeep, isEmpty, get as lget, set as lset } from 'lodash';
import { getSubmitBuilderMutationOptions } from 'src/hooks';
import log from 'src/log';
import { routingAtom } from './history.atoms';
import { z } from 'zod';

export const dataSourceFormSchema = z.object({
  accessCredentialId: z.string().optional(),
  connectorId: z.string(),
  name: z.string().optional(),
  staticFileId: z.string().optional(),
  // There isn't exactly optional but it will be either defaulted or the connector will throw a validation error on submission
  sections: z.array(z.string()).optional(),
  config: z.any().optional(),
  clientData: z.any().optional() // TODO: Figure out proper types for this
});

export type DataSourceFormValues = z.infer<typeof dataSourceFormSchema>;

export const dataSourceFormAtom = atom<FormApi<
  DataSourceFormValues,
  ReturnType<typeof zodValidator>
> | null>(null);

export const dataSourceFormStateAtom = atom<FormState<DataSourceFormValues> | null>();

export const submitCurrentBuilderMutationAtom = atomWithMutation(() =>
  getSubmitBuilderMutationOptions()
);

type Step = 'pick-data-source' | 'success' | string;

export const currentDataSourceFormStepIdAtom = atom<Step>('pick-data-source');

export const resetDataSourceFormAtom = atom(null, (get, set) => {
  set(dataSourceFormAtom, null);
  set(currentDataSourceFormStepIdAtom, 'pick-data-source');
  set(dataSourceFormStateAtom, null);
  set(createdDataSourceIdAtom, null);
  set(buildResponseStepsAtom, []);
});

export const _syncDataSourceFormStateAtom = atomEffect((get, set) => {
  const dataSourceForm = get(dataSourceFormAtom);
  if (!dataSourceForm) return;
  return dataSourceForm.store.subscribe(() => {
    set(dataSourceFormStateAtom, dataSourceForm.store.state);
  });
});

export const _autoRefreshAtom = atomEffect((get, set) => {
  const dataSourceForm = get(dataSourceFormAtom);
  if (!dataSourceForm) return;
  let prevValues: DataSourceFormValues | null = null;
  return dataSourceForm.store.subscribe(() => {
    const values = dataSourceForm.store.state.values;
    if (isEmpty(values)) return; // Tanstack form sets the values briefly to empty during reset. Skip reset updates
    const step = get.peek(currentDataSourceFormStepAtom);
    if (!step || step.pending) return;
    const fieldsWithRefreshOnChange = step.content
      .filter(content => 'refreshOnChange' in content && content.refreshOnChange)
      .map(content => ('fieldName' in content ? content.fieldName : null))
      .filter(isNotEmpty);

    if (
      fieldsWithRefreshOnChange.some(
        fieldName => lget(values, fieldName) !== lget(prevValues, fieldName)
      )
    ) {
      set(submitCurrentBuilderAtom, true);
    }
    prevValues = values;
  });
});

const buildResponseStepsAtom = atom<DataSourceBuilder.ConnectorStep[]>([]);

export const dataSourceFormStepsAtom = atom<DataSourceBuilder.ConnectorStep[]>(get => {
  return [
    {
      id: 'pick-data-source',
      label: 'Pick Data Source',
      content: [],
      completed: true,
      requiresSubmission: true
    },
    ...get(buildResponseStepsAtom),
    {
      id: 'success',
      label: 'Success',
      content: [],
      completed: true,
      requiresSubmission: false,
      nextButtonText: 'Finish'
    }
  ];
});

export const createdDataSourceIdAtom = atom<string | null>(null);

export const isSubmittingBuilderAtom = atom(get => {
  return get(submitCurrentBuilderMutationAtom).isPending;
});

export const finishCreateDataSourceAtom = atom(null, async (get, set) => {
  const createdId = get(createdDataSourceIdAtom);
  if (!createdId) throw new Error('No data source created!');
  const router = get(routingAtom);
  router?.navigate({
    to: '/data-source/$id',
    params: { id: createdId }
  });
});

export const currentDataSourceFormStepAtom = atom(get => {
  const currentStepId = get(currentDataSourceFormStepIdAtom);
  const steps = get(dataSourceFormStepsAtom);
  return steps.find(step => step.id === currentStepId);
});

const isFormChild = (child: any): child is { fieldName: string; default: any; value: any } => {
  return Boolean((child as any).fieldName);
};

export const submitCurrentBuilderAtom = atom(null, async (get, set, refresh: boolean = false) => {
  const dataSourceForm = get(dataSourceFormAtom);
  if (!dataSourceForm) return;
  try {
    const { values } = dataSourceForm.store.state;
    const currentStep = get(currentDataSourceFormStepAtom);
    if (!currentStep) throw new Error('Invalid step found!');
    if (currentStep.pending) throw new Error('Cannot submit a pending step!');
    if (!currentStep.requiresSubmission) {
      throw new Error('Should only submit steps that require submission!');
    }
    const result = await get(submitCurrentBuilderMutationAtom).mutateAsync({
      currentDataSource: values,
      currentStepId: currentStep.id,
      refresh
    });
    set(buildResponseStepsAtom, result.data.steps);
    set(createdDataSourceIdAtom, result.data?.dataSource?.id ?? null);
    // Set values received from the backend and update the form
    if (currentStep.id !== result.data.nextStepId || refresh) {
      const newValues = cloneDeep(values);
      // Now add all of the form defaults of the new step
      result.data.steps.forEach(step => {
        if (step?.pending) return;
        step.content.forEach(child => {
          if (
            isFormChild(child) &&
            (lget(newValues, child.fieldName) == null ||
              // When refreshing, reset values unless it was a refresh on change value itself
              (refresh &&
                step.id === result.data.nextStepId &&
                !('refreshOnChange' in child && child.refreshOnChange)))
          ) {
            lset(newValues, child.fieldName, child.default ?? child.value);
          }
        });
      });
      // Reset the form when you switch steps so the submit count stays consistent and default values are merged in
      dataSourceForm.reset();
      dataSourceForm.store.setState(s => ({ ...s, values: newValues }));
      set(currentDataSourceFormStepIdAtom, result.data.nextStepId);
    }
  } catch (err) {
    // TODO: Set errors based on the response if it is errored out
    log.error(err);
  }
});
