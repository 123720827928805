import 'axil-web-ui/style.css';
import get from 'lodash/get';

// Using babel preval, this file will pre evaluate at build time so we don't pull in tailwind deps
declare let preval: any;

import { isString } from 'lodash';
import type resolveConfig from 'tailwindcss/resolveConfig';

export type TwConfig = NonNullable<ReturnType<typeof resolveConfig>>;

export type ThemeType = NonNullable<TwConfig['theme']>;

const twConfig = preval`
const resolveConfig = require('tailwindcss/resolveConfig');
const tailwindConfig = require('../tailwind.config.js');

const resolved = resolveConfig(tailwindConfig);
delete resolved.plugins;
delete resolved.presets

module.exports = resolved;
` as TwConfig;

const theme = twConfig.theme!;
export default theme;

export const defaultFontFamily = (theme.fontFamily?.sans as string[]).join(',');

const daisyUiThemes = twConfig.daisyui.themes;

export const getThemeColor = (
  theme: ThemeType,
  colorStr: string,
  themeMode: 'dark' | 'light' = 'light'
): string => {
  const daisyTheme = daisyUiThemes.find((t: any) => Boolean(t[themeMode]));
  if (daisyTheme && daisyTheme[themeMode] && daisyTheme[themeMode][colorStr]) {
    return daisyTheme[themeMode][colorStr];
  }
  // Otherwise, fall back to general colors
  const val = get(theme.colors, colorStr);
  if (!isString(val)) {
    throw new Error('String  not found!');
  }
  return val;
};
