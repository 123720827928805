import { BaseFieldTypeDesc } from './common';
export default class TemperatureDesc extends BaseFieldTypeDesc {
    typeLabel = 'Temperature';
    dataLabel = 'Temperature';
    unitLabels = {
        F: 'Fahrenheit',
        C: 'Celsius',
        K: 'Kelvin'
    };
    static dataPointNumberFormats = {
        C: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'celsius',
            unitDisplay: 'short'
        }),
        F: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'fahrenheit',
            unitDisplay: 'short'
        }),
        K: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2
        })
    };
    static tickNumberFormats = {
        C: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'celsius',
            unitDisplay: 'narrow'
        }),
        F: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'fahrenheit',
            unitDisplay: 'narrow'
        }),
        K: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2
        })
    };
    // Since Kelvin is not in Intl.NumberFormat as a unit, just append "K" to get something there
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = TemperatureDesc.dataPointNumberFormats[field.unit];
        const formatted = numberFormat.format(dataPoint);
        return field.unit === 'K' ? formatted + ' K' : formatted;
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = TemperatureDesc.tickNumberFormats[field.unit];
        const formatted = numberFormat.format(dataPoint);
        // Notice the lack of space for Kelvin compared to the dataPoint formatter
        return field.unit === 'K' ? formatted + 'K' : formatted;
    };
}
