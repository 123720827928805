import { BaseFieldTypeDesc } from './common';
export default class LiquidDesc extends BaseFieldTypeDesc {
    typeLabel = 'Liquid';
    dataLabel = 'Liquid';
    unitLabels = {
        ml: 'Milliliter',
        floz: 'Fluid Ounce',
        L: 'Liter',
        gal: 'Gallon'
    };
    static dataPointNumberFormats = {
        ml: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'milliliter',
            unitDisplay: 'short'
        }),
        floz: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'fluid-ounce',
            unitDisplay: 'short'
        }),
        L: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'liter',
            unitDisplay: 'short'
        }),
        gal: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'gallon',
            unitDisplay: 'short'
        })
    };
    static tickNumberFormats = {
        ml: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            style: 'unit',
            unit: 'milliliter',
            unitDisplay: 'narrow'
        }),
        floz: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            style: 'unit',
            unit: 'fluid-ounce',
            unitDisplay: 'narrow'
        }),
        L: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            style: 'unit',
            unit: 'liter',
            unitDisplay: 'narrow'
        }),
        gal: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            style: 'unit',
            unit: 'gallon',
            unitDisplay: 'narrow'
        })
    };
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = LiquidDesc.dataPointNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = LiquidDesc.tickNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
}
