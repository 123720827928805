import { animated } from '@react-spring/web';
import { Button, cn, Loading, usePrevious } from 'axil-web-ui';
import { ChevronLeftIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useSlideTransition } from 'src/hooks/styles';
import { useDebounce } from 'use-debounce';

export type WizardStep = {
  id: string;
  previousButtonText?: string;
  nextButtonText?: string;
  hidePreviousButton?: boolean;
  nextButtonFormSubmit?: boolean;
  disabledNext?: boolean;
  onPrevious?: () => boolean | void | Promise<boolean | void>;
  onNext?: () => boolean | void | Promise<boolean | void>;
  content: JSX.Element;
};

type ControlledProps = {
  currentStepId: string;
  onStepChange: (stepId: string) => void;
};

export type WizardProps = {
  steps: WizardStep[];
} & (
  | {
      initialStepId?: string;
    }
  | ControlledProps
); // If you add a currentStepId, it becomes controlled

// eslint-disable-next-line @typescript-eslint/ban-types
const areControlledProps = (props: ControlledProps | {}): props is ControlledProps => {
  const p = props as ControlledProps;
  return p.currentStepId != null && p.onStepChange != null;
};

const useCurrentStepId = (props: WizardProps) => {
  // If its controlled, just use the props. Otherwise, use your own internal state
  const [currentStepId, setCurrentStepId] = useState(
    !areControlledProps(props) ? (props.initialStepId ?? props.steps[0].id) : props.currentStepId
  );
  if (!areControlledProps(props)) {
    return {
      currentStepId,
      onStepChange: setCurrentStepId
    };
  }
  return {
    currentStepId: props.currentStepId,
    onStepChange: props.onStepChange
  };
};
function useTransitionDirection(currentStepId: string, steps: WizardStep[]) {
  const prevStepId = usePrevious(currentStepId);
  return (
    steps.findIndex(step => step.id === currentStepId) >
    (prevStepId ? steps.findIndex(step => step.id === prevStepId) : -1)
  );
}

export default function Wizard(props: WizardProps) {
  const { steps } = props;
  const { currentStepId, onStepChange } = useCurrentStepId(props);
  const transitions = useSlideTransition(
    currentStepId,
    useTransitionDirection(currentStepId, steps)
  );
  const [debouncedStepId] = useDebounce(currentStepId, 20);
  const transitioning = debouncedStepId !== currentStepId;

  const currentStep = steps.find(s => s.id === currentStepId);
  if (!currentStep) throw new Error('Invalid step found!');
  const [submittingNext, setSubmittingNext] = useState(false);

  const handlePrevious = async () => {
    if (currentStep?.onPrevious) {
      const handled = await currentStep.onPrevious();
      if (handled) return;
    }
    const prevStep = steps[steps.findIndex(s => s.id === currentStepId) - 1];
    onStepChange(prevStep.id);
  };
  const handleNext = async () => {
    if (currentStep.nextButtonFormSubmit) return;
    if (currentStep.onNext) {
      try {
        setSubmittingNext(true);
        const handled = await currentStep.onNext();
        setSubmittingNext(false);
        if (handled) return;
      } catch (err) {
        setSubmittingNext(false);
        throw err;
      }
    }
    const nextStep = steps[steps.findIndex(s => s.id === currentStepId) + 1];
    onStepChange(nextStep.id);
  };
  const isFirstStep = steps[0].id === currentStepId;
  return (
    <div className="flex h-full min-h-0 flex-col items-center justify-between gap-4">
      <div className="relative flex w-full shrink grow flex-col overflow-hidden">
        {transitions((style, stepId) => {
          const step = steps.find(s => s.id === stepId);
          if (!step) return;
          return (
            <animated.div style={style} className={'h-full'}>
              {step.content}
            </animated.div>
          );
        })}
      </div>
      <div
        className={cn(
          'flex w-full max-w-3xl justify-between gap-4 border-t py-4',
          currentStep.hidePreviousButton ? 'justify-center' : 'justify-between'
        )}>
        {currentStep.hidePreviousButton ? null : (
          <Button
            onClick={handlePrevious}
            className={isFirstStep ? 'invisible' : undefined}
            size="lg">
            <ChevronLeftIcon size="24px" />
            {currentStep.previousButtonText ?? 'Previous'}
          </Button>
        )}
        <Button
          color="primary"
          size="lg"
          onClick={handleNext}
          type={currentStep.nextButtonFormSubmit ? 'submit' : 'button'}
          disabled={currentStep.disabledNext || submittingNext || transitioning}>
          {submittingNext ? <Loading /> : (currentStep.nextButtonText ?? 'Next')}
        </Button>
      </div>
    </div>
  );
}
