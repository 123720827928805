import { Dialog } from 'axil-web-ui';
import React from 'react';
import DashboardForm from './Dashboard/DashboardForm';

import { useAtomValue, useSetAtom } from 'jotai';
import { closeDialogAtom, dialogAtom } from 'src/atoms';
import Account from './Account';
import CreateDataSource from './DataSource/CreateDataSource';

export default function GlobalUserDialogs() {
  const currentDialog = useAtomValue(dialogAtom);
  const closeDialog = useSetAtom(closeDialogAtom);
  return (
    <>
      <Dialog isOpen={currentDialog?.type === 'CreateDashboard'} onClose={closeDialog}>
        <DashboardForm onSuccess={closeDialog} />
      </Dialog>
      {/* TODO: Think about this a bit more to smooth out the close experience */}
      <Dialog isOpen={currentDialog?.type === 'UpdateDashboard'} onClose={closeDialog}>
        {currentDialog?.type === 'UpdateDashboard' ? (
          <DashboardForm
            key={currentDialog.payload.id}
            update
            initialValues={currentDialog.payload}
            onSuccess={closeDialog}
          />
        ) : null}
      </Dialog>
      <Dialog isOpen={currentDialog?.type === 'CreateDataSource'} onClose={closeDialog} full>
        {currentDialog?.type === 'CreateDataSource' ? (
          <CreateDataSource
            onSuccess={closeDialog}
            initialConnector={currentDialog.payload?.initialConnector}
          />
        ) : (
          <div className="size-full"></div>
        )}
      </Dialog>

      <Dialog
        isOpen={currentDialog?.type === 'AccountPreferences'}
        onClose={closeDialog}
        full={{ width: false, height: true }}
        contentClassName="w-full md:w-[800px]">
        <div className="flex min-h-0 flex-col overflow-auto">
          <Account />
        </div>
      </Dialog>
    </>
  );
}
