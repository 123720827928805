import { BaseFieldTypeDesc } from './common';
import { format as dateFormat, startOfDay, addHours } from 'date-fns';
// This is essentially the same as TimeOfDayDesc, but the database queries just aggregates based on hour
export default class HourOfDayDesc extends BaseFieldTypeDesc {
    typeLabel = 'Hour of Day';
    dataLabel = 'Hour of Day';
    unitLabels = {
        hour: 'Hour'
    };
    static getDateToFormat = (hour) => {
        return addHours(startOfDay(new Date()), hour);
    };
    dataPointFormatter = (field, dataPoint) => {
        const date = HourOfDayDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'p');
    };
    tickFormatter = (field, dataPoint) => {
        const date = HourOfDayDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'p');
    };
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
