import { ParsedLocation, Register } from '@tanstack/react-router';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';

export const locationAtom = atom<ParsedLocation | null>(null);

export const pathnameAtom = atom(get => get(locationAtom)?.pathname ?? null);

export const routingAtom = atom<Register['router'] | null>(null);

export const routeMatchesAtom = atom(get => {
  const router = get(routingAtom);
  const location = get(locationAtom); // Triggers the update
  if (!router || !location) return null;
  return router.matchRoutes(router.latestLocation);
});

export const _syncLocationEffect = atomEffect((get, set) => {
  const router = get(routingAtom);
  if (!router) {
    set(locationAtom, null);
    return;
  }
  const unlisten = router.history.subscribe(() => {
    set(locationAtom, router.latestLocation);
  });
  set(locationAtom, router.latestLocation);
  return unlisten;
});
