import { useTransition } from '@react-spring/web';
import { usePrefersReducedMotion } from 'axil-web-ui';
import { useEffect, useMemo, useState } from 'react';
import { ThemeType } from 'src/theme';
import { useTopLevelTheme } from '.';

export function useSlideTransition<I>(item: I, forward: boolean) {
  return useTransition<I, React.CSSProperties>(item, {
    keys: null,
    initial: null,
    // Uncomment to debug awkward transitions
    // config: { mass: 1, tension: 500, friction: 50, duration: 1000000 },
    from: { opacity: 0, transform: `translate3d(${forward ? '' : '-'}100%,0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: `translate3d(${forward ? '-' : ''}100%,0,0)`,
      height: '100%',
      width: '100%',
      position: 'absolute'
    },
    immediate: usePrefersReducedMotion()
  });
}

const pxToInt = (pxVal: string) => parseInt(pxVal.replace('px', ''), 10);

// Mainly copied from grommet
export const getBreakpointSize = (containerWidth: number, screens: ThemeType['screens']) => {
  // Sorted in reverse order because breakpoints are based on screen max
  const sortedBreakpoints = (Object.keys(screens) as Array<keyof ThemeType['screens']>).sort(
    (a, b) => {
      return pxToInt(screens[b]) - pxToInt(screens[a]);
    }
  );
  // the last breakpoint on the sorted array should have
  // no windowWidth boundaries
  const lastBreakpoint = sortedBreakpoints.at(-1)!;
  const result = sortedBreakpoints.find(name => {
    const breakpoint = pxToInt(screens[name]);
    return breakpoint <= containerWidth ? name : false;
  });
  return result || lastBreakpoint;
};

export const useBreakPointSize = (containerWidth = 0) => {
  const screens = useTopLevelTheme().screens;
  return useMemo(() => {
    return getBreakpointSize(containerWidth, screens);
  }, [containerWidth, screens]);
};

export const useWindowBreakpointSize = () => {
  const screens = useTopLevelTheme().screens;
  const [size, setSize] = useState(getBreakpointSize(window.innerWidth, screens));
  useEffect(() => {
    const handleResize = () => setSize(getBreakpointSize(window.innerWidth, screens));
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
};
