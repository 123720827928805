import { Units, userPreferenceSchema } from 'daydash-data-structures';
import { z } from 'zod';

export const userUpdateSchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  preferences: userPreferenceSchema
});

export type UserUpdateValues = z.infer<typeof userUpdateSchema>;
