import { RegisteredRouter, RouteIds } from '@tanstack/react-router';
import { isNotEmpty } from 'axil-utils';
import { atom } from 'jotai';
import { _storeAtom, routeMatchesAtom, routingAtom } from '.';
import type { CommandGroup } from './commands.atoms';
import { dashboardListAtom } from './dashboards.atoms';
import { editWidgetAtom } from './dialogs.atoms';
import { CustomMenuItem } from 'src/components/Widget/types';

const expandedWidgetId: RouteIds<RegisteredRouter['routeTree']> =
  '/authed/dash/$dashboardId/$widgetId';

export const moveWidgetAtom = atom(
  null,
  async (
    get,
    set,
    {
      dashboardFromId,
      dashboardToId,
      widgetId
    }: { dashboardFromId: string; dashboardToId: string; widgetId: string }
  ) => {
    const dashboardList = get(dashboardListAtom);
    const dashboardFrom = dashboardList?.data?.find(d => d.id === dashboardFromId);
    const dashboardTo = dashboardList?.data?.find(d => d.id === dashboardToId);
    if (!dashboardFrom || !dashboardTo) {
      throw new Error('Dashboard not found!');
    }
    const widget = dashboardFrom.widgets?.find(w => w.id === widgetId);
    if (!widget) {
      throw new Error('Widget not found!');
    }
    const newWidget = {
      ...widget,
      layout: undefined
    };
    /**
     * TODO: Migrate to new system
     */
    // const electric = get(electricAtom);
    // // Add it first to prevent data loss
    // await electric?.db.dashboards.update({
    //   where: { id: dashboardToId },
    //   data: {
    //     widgets: [...(dashboardTo.widgets ?? []), newWidget]
    //   }
    // });
    // await electric?.db.dashboards.update({
    //   where: { id: dashboardFromId },
    //   data: {
    //     widgets: dashboardFrom.widgets?.filter(w => w.id !== widgetId)
    //   }
    // });
  }
);
export const widgetDetailsCustomMenuItemsAtom = atom<CustomMenuItem[] | null>(null);

export const widgetCommandsAtom = atom<CommandGroup | null>(get => {
  const store = get(_storeAtom);
  if (!store) return null;
  const router = get(routingAtom);
  const dashboardList = get(dashboardListAtom);
  const expandedWidgetMatch =
    get(routeMatchesAtom)?.find(match => match.routeId === expandedWidgetId) ?? null;
  const currentDashboard = expandedWidgetMatch
    ? dashboardList?.data?.find(d => d.id === expandedWidgetMatch?.params.dashboardId)
    : null;
  if (!currentDashboard) return null;
  const currentWidget = currentDashboard.widgets?.find(
    w => w.id === expandedWidgetMatch?.params.widgetId
  );
  if (!currentWidget) {
    console.error('Something went wrong and the widget is not found');
    return null;
  }
  const customWidgetMenuItems = get(widgetDetailsCustomMenuItemsAtom) ?? [];
  return {
    label: 'Widget',
    id: 'widget',
    commands: [
      {
        label: 'Return to Dashboard',
        keywords: ['back', 'return', 'dashboard'],
        id: 'return-to-dashboard',
        handler: () => router?.navigate({ to: '/dash/$id', params: { id: currentDashboard.id } })
      },
      {
        label: 'Edit Widget',
        id: 'edit-widget',
        handler: () =>
          store.set(editWidgetAtom, {
            dashboardId: currentDashboard.id,
            widgetId: currentWidget.id
          })
      },
      ...customWidgetMenuItems.map((item, idx) => ({
        label: item.label,
        id: `${item.label}-${idx}`,
        handler: item.onClick
      }))
      // TODO: Add delete command (need to migrate over the dashboard upsert first)
    ].filter(isNotEmpty)
  };
});
