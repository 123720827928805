import { addDays } from 'date-fns';
import { format as dateFormat, startOfWeek } from 'date-fns';
import { BaseFieldTypeDesc } from './common';
export default class DayOfWeekDesc extends BaseFieldTypeDesc {
    typeLabel = 'Day of Week';
    dataLabel = 'Day of Week';
    unitLabels = {
        day: 'day'
    };
    static getDateToFormat = (days) => {
        return addDays(startOfWeek(new Date(), {
            weekStartsOn: 0 // TODO: DD-136: Add support for week start time
        }), days);
    };
    dataPointFormatter = (field, dataPoint) => {
        const date = DayOfWeekDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'cccc');
    };
    tickFormatter = (field, dataPoint) => {
        const date = DayOfWeekDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'ccc');
    };
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
