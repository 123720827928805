import { useForm } from '@tanstack/react-form';
import { assertNever } from 'axil-utils';

import { zodValidator } from '@tanstack/zod-form-adapter';
import { getLatestWidgetOfType, Widget, widgetSchema } from 'daydash-data-structures';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import get from 'lodash/get';
import React, { useLayoutEffect, useMemo } from 'react';
import {
  availableStepsAtom,
  cleanupWidgetFormAtom,
  currentTypeAtom,
  currentWidgetFormStepAtom,
  initializeWidgetFormAtom,
  STEP,
  updateTypeAtom,
  widgetFormInitializedAtom
} from 'src/atoms/widgetForm.atoms';
import Wizard, { WizardStep } from 'src/components/common/Wizard';
import { useWindowBreakpointSize } from 'src/hooks/styles';
import { v4 as uuid } from 'uuid';
import { WidgetFormContext } from './context';
import DataSourceField from './DataSourceField';
import FinalizeWidget from './FinalizeWidget';
import InitialWidgetTypePicker from './InitialWidgetTypePicker';

interface WidgetFormProps {
  onSubmit: (newWidget: Widget) => Promise<void>;
  initial?: Widget | null;
}

const getButtonNameForStep = (step: STEP | null, short: boolean) => {
  if (step === 'type-picker') return short ? 'Select Type' : 'Select Widget Type';
  if (step === 'data-source-config') return short ? 'Configured Data' : 'Configure Data Source';
  if (step === 'finalize') return 'Finalize';
  if (step == null) return 'Previous'; // Basically never happens
  assertNever(step);
};

function WidgetForm({ initial, onSubmit }: WidgetFormProps) {
  const initialValues = useMemo(() => {
    // v will populated on submission depending on the type
    let parsed: Widget | null = null;
    // Just make sure we can parse everything out if we can. This will also help apply transforms
    if (initial) {
      const result = widgetSchema.safeParse(getLatestWidgetOfType(initial));
      if (result.success) parsed = result.data;
    }
    return initial ? { ...(parsed || initial) } : ({ id: uuid() } as any);
  }, [initial, widgetSchema]);
  const form = useForm<Widget, ReturnType<typeof zodValidator>>({
    defaultValues: initialValues,
    validatorAdapter: zodValidator(),
    validators: { onChange: widgetSchema },
    onSubmit: ({ value }) => onSubmit(widgetSchema.parse(value))
  });
  const initializeWidgetForm = useSetAtom(initializeWidgetFormAtom);
  const cleanupWidgetForm = useSetAtom(cleanupWidgetFormAtom);
  useLayoutEffect(() => {
    initializeWidgetForm(form);
    return () => cleanupWidgetForm();
  }, [form]);

  const currentType = useAtomValue(currentTypeAtom);
  const updateType = useSetAtom(updateTypeAtom);
  const [currentStepId, setCurrentStepId] = useAtom(currentWidgetFormStepAtom);
  const availableSteps = useAtomValue(availableStepsAtom);
  const canSubmit = form.useStore(s => s.canSubmit);
  const smallScreen = useWindowBreakpointSize() === 'sm';

  const wizardSteps = availableSteps.map<WizardStep>((step, idx) => {
    const nextStep = availableSteps[idx + 1] ?? null;
    const previousButtonText = getButtonNameForStep(availableSteps[idx - 1], smallScreen);
    if (step === 'finalize') {
      return {
        id: step,
        previousButtonText,
        nextButtonText: initial ? 'Update' : 'Create',
        nextButtonFormSubmit: true,
        disabledNext: !canSubmit,
        content: <FinalizeWidget />
      };
    }
    if (step === 'data-source-config') {
      const isStepValid = () => {
        const errorMap = form.state.errorMap;
        return !(
          get(errorMap, 'dataSource') ||
          get(errorMap, 'chartConfig.dataSource') ||
          get(errorMap, 'chartConfig')
        );
      };
      return {
        id: step,
        previousButtonText,
        disabledNext: !(isStepValid() && nextStep),
        onNext: async () => {
          await form.validate('submit');
          if (isStepValid()) nextStep && setCurrentStepId(nextStep);
          return true;
        },
        content: <DataSourceField />
      };
    }
    if (step === 'type-picker') {
      return {
        id: step,
        previousButtonText,
        disabledNext: !currentType,
        onNext: async () => {
          nextStep && setCurrentStepId(nextStep);
          return true;
        },
        content: (
          <InitialWidgetTypePicker onSelect={updateType} selectedType={currentType ?? null} />
        )
      };
    }
    assertNever(step);
  });
  // Hide the wizard while not initialized so we don't have a flash of the wrong slide
  const initialized = useAtomValue(widgetFormInitializedAtom);
  return (
    <WidgetFormContext.Provider value={form}>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate
        className="flex h-full min-h-0 flex-col justify-between">
        <div className="min-h-0 flex-shrink flex-grow">
          {initialized ? (
            <Wizard
              steps={wizardSteps}
              currentStepId={currentStepId}
              onStepChange={step => setCurrentStepId(step as STEP)}
            />
          ) : null}
        </div>
      </form>
    </WidgetFormContext.Provider>
  );
}

export default WidgetForm;
