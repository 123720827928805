import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import {
  CoreClientRPCCalls,
  CoreRPCCalls,
  DBQueries,
  DBQueryOptionGetter,
  DBQueryOptions,
  SyncState
} from './types';
import CommonRpc, { RPCCancelError } from './commonRpc';
import { isEqual, isEqualWith } from 'lodash';
import { authSessionAtom, Store, syncStateAtom, userIdAtom } from 'src/atoms';
import { assertNever } from 'axil-utils';

type DBFetchCall = keyof DBQueries;

export default class CoreClient implements CoreClientRPCCalls {
  public initialized = false;

  constructor(
    private store: Store,
    private commType: 'shared-worker' | 'broadcast-channel'
  ) {}

  _coreRpc: CommonRpc<CoreRPCCalls, CoreClientRPCCalls> | null = null;

  set coreRpc(rpc: CommonRpc<CoreRPCCalls, CoreClientRPCCalls>) {
    this._coreRpc = rpc;
  }

  get coreRpc() {
    const rpc = this._coreRpc;
    if (!rpc) {
      throw new Error('CoreRpc not initialized');
    }
    return rpc;
  }

  initializeSharedWorkerRPC = () => {
    // TODO: Figure out a better way to ensure the shared worker is cleaned up when necessary
    const worker = new SharedWorker(new URL('./core.worker.shared.ts', import.meta.url), {
      name: 'core',
      type: 'module'
    });
    worker.port.start();
    this.coreRpc = CommonRpc.createFromMessagePort<CoreRPCCalls, CoreClientRPCCalls>(
      worker.port,
      this,
      () => {
        worker.port.close();
        this.reinitialize();
      }
    );
  };

  initializeBroadcastChannelRPC = () => {
    const broadcastChannel = new BroadcastChannel('core');
    this.coreRpc = CommonRpc.createFromBroadcastChannel<CoreRPCCalls, CoreClientRPCCalls>(
      broadcastChannel,
      this,
      () => {
        broadcastChannel.close();
        this.reinitialize();
      }
    );
  };

  initialize = async () => {
    if (this.initialized) {
      console.warn('Already initialized. Returning');
      return;
    }
    if (this.commType === 'shared-worker') {
      this.initializeSharedWorkerRPC();
    } else if (this.commType === 'broadcast-channel') {
      this.initializeBroadcastChannelRPC();
    } else {
      assertNever(this.commType);
    }
    // The access token is a temp hack until we add cookie auth or native auth back in
    await this.coreRpc.call('initialize', this.store.get(userIdAtom));
    this.initialized = true;
  };

  close = async () => {
    if (!this.initialized) {
      console.warn('Not initialized. Returning');
      return;
    }
    this.initialized = false;
  };

  reinitialize = async () => {
    await this.close();
    await this.initialize();
  };

  getCapabilities = async () => {
    return this.coreRpc?.call('getCapabilities') as Promise<string[]>;
  };

  openStandaloneDashboard = async (id: string) => {
    return this.coreRpc?.call('openStandaloneDashboard', id);
  };

  private generateDBQuery = <C extends DBFetchCall>(dbCall: C): DBQueries[C] => {
    return (async (...params: Parameters<DBQueries[C]>) => {
      if (!this.initialized) {
        throw new Error('Coreclient not initialized');
      }
      return this.coreRpc?.call('query', dbCall, params);
    }) as any;
  };

  queries: DBQueries = {
    getDashboard: this.generateDBQuery('getDashboard'),
    getAllDashboards: this.generateDBQuery('getAllDashboards'),
    getDataSource: this.generateDBQuery('getDataSource'),
    getUserFile: this.generateDBQuery('getUserFile'),
    getAllDataSources: this.generateDBQuery('getAllDataSources'),
    getInitialSectionFields: this.generateDBQuery('getInitialSectionFields'),
    getDataSourceSection: this.generateDBQuery('getDataSourceSection'),
    getCategoryValues: this.generateDBQuery('getCategoryValues'),
    getDataSourceSectionsForDataSource: this.generateDBQuery('getDataSourceSectionsForDataSource'),
    queryDataPoints: this.generateDBQuery('queryDataPoints')
  };

  private generateDBQueryOptionsGetter = <C extends DBFetchCall>(
    dbCall: C
  ): DBQueryOptionGetter<C> => {
    return (...params: Parameters<DBQueries[C]>) => {
      return queryOptions<ReturnType<DBQueries[C]>>({
        queryKey: ['db', dbCall, ...params],
        queryFn: () => (this.queries[dbCall] as any)(...params),
        retry: (failureCount, error) => {
          if (error instanceof RPCCancelError) {
            return true;
          }
          return false;
        },
        placeholderData(prevData, prevQuery) {
          /**
           * Keep previous data for queryDataPoints if the layer config is the same
           * but just pagination or sorting has changed. This helps with re-renders and weird flashing
           */
          if (dbCall === 'queryDataPoints') {
            const currentLayerConfig = (params[0] as any)?.layerConfig;
            const prevLayerConfig = (prevQuery?.queryKey[2] as any)?.layerConfig;
            // Custom equal check for layer configs that ignores fields that don't matter as much to the underlying data
            const isBasicallyEqual = isEqualWith(
              currentLayerConfig,
              prevLayerConfig,
              (a, b, prop) => {
                if (prop === 'aggregateFields') return true; // Don't worry about aggregate fields changing
                return undefined;
              }
            );
            return isBasicallyEqual ? keepPreviousData(prevData) : undefined;
          }
          return undefined;
        },
        networkMode: 'always' // So we always hit up workers, even when offline
      }) as any; // Figure out types later
    };
  };

  getQueryOptions: DBQueryOptions = {
    getDashboard: this.generateDBQueryOptionsGetter('getDashboard'),
    getAllDashboards: this.generateDBQueryOptionsGetter('getAllDashboards'),
    getDataSource: this.generateDBQueryOptionsGetter('getDataSource'),
    getUserFile: this.generateDBQueryOptionsGetter('getUserFile'),
    getAllDataSources: this.generateDBQueryOptionsGetter('getAllDataSources'),
    getCategoryValues: this.generateDBQueryOptionsGetter('getCategoryValues'),
    getInitialSectionFields: this.generateDBQueryOptionsGetter('getInitialSectionFields'),
    getDataSourceSection: this.generateDBQueryOptionsGetter('getDataSourceSection'),
    getDataSourceSectionsForDataSource: this.generateDBQueryOptionsGetter(
      'getDataSourceSectionsForDataSource'
    ),
    queryDataPoints: this.generateDBQueryOptionsGetter('queryDataPoints')
  };

  /**
   * Calls needed to implement
   */

  getAuthToken = async () => {
    const session = this.store.get(authSessionAtom);
    return session?.getToken() ?? null;
  };

  syncStateChange = (state: SyncState) => {
    this.store.set(syncStateAtom.debouncedValueAtom, current => {
      if (isEqual(current, state)) return current;
      return state;
    });
  };
}
