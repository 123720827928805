import { BaseFieldTypeDesc } from './common';
export default class CategoryDesc extends BaseFieldTypeDesc {
    typeLabel = 'Category';
    dataLabel = (field) => field.label;
    defaultScale = 'band';
    unitLabels = {};
    dataPointFormatter = (field, value) => {
        if (field.type !== 'category')
            return '';
        if (field.meta?.labels) {
            if (field.meta?.labels[value]) {
                return field.meta.labels[value];
            }
            // If it was combined due to group_concat aggregation, split it and try to look up each one
            if (typeof value === 'string' && value.includes(',')) {
                const aggCorrected = value
                    .split(',')
                    .map(v => {
                    if (field.meta?.labels?.[v])
                        return field.meta.labels[v];
                    if (field.meta?.labels?.[parseInt(v, 10)])
                        return field.meta.labels[parseInt(v, 10)];
                    return v;
                })
                    .join(', ');
                if (aggCorrected !== value)
                    return aggCorrected;
            }
            console.warn(`No category label found`, {
                value,
                field
            });
        }
        /**
         * During aggregation, the data processor uses strings instead of numbers, even
         * though the category might be a string. Try to convert it back to a number
         * if need be to look up the right label
         */
        if (typeof value === 'string') {
            const parsedInt = parseInt(value, 10);
            if (!Number.isNaN(parsedInt) && field.meta?.labels?.[parsedInt]) {
                return field.meta?.labels[parsedInt];
            }
            // Try a float just in case
            const parsedFloat = parseFloat(value);
            if (!Number.isNaN(parsedFloat) && field.meta?.labels?.[parsedFloat]) {
                return field.meta?.labels[parsedFloat];
            }
        }
        if (value === '__unset') {
            return 'Not set';
        }
        // Otherwise, just fall back to the category value
        return String(value);
    };
    tickFormatter = (field, dataPoint) => {
        if (field.type !== 'category')
            return '';
        return field.meta?.labels?.[dataPoint] ?? dataPoint;
    };
}
