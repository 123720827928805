import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { moveItem } from 'src/utils';
import { updateSortWeights } from 'axil-utils';
import { CSS as DndCss } from '@dnd-kit/utilities';
import { GripVerticalIcon } from 'lucide-react';
import { SortableEntity } from 'src/types/service';
import { SortWeights, useSortedEntities } from 'src/hooks';

function SortableItem<I extends SortableEntity>({
  item,
  children
}: {
  item: I;
  children: React.ReactElement;
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id
  });
  /**
   * There may come a time where you want to swap the drag handle so that the whole thing
   * is draggable. Well I'm here to tell you that although it could be possible with a
   * different library, dnd-kit seems to struggle with having interactive elements inside
   * of the draggable block, like a link.
   *
   * I added a comment here in hopes of getting this addressed https://github.com/clauderic/dnd-kit/issues/1085
   *
   * It looks like an errant stopPropagation may be causing the problem
   */
  return (
    <div
      className="group ml-[-8px] rounded bg-white/0 transition-colors hover:bg-white/20"
      style={{
        transform: DndCss.Transform.toString(transform),
        transition
      }}
      ref={setNodeRef}>
      <div className="relative flex shrink-0 grow-0 flex-row items-center gap-2 px-2">
        <div
          className="absolute right-full shrink-0 grow-0 touch-none opacity-0 transition-opacity group-hover:opacity-70"
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          {...listeners}
          {...attributes}>
          <GripVerticalIcon size="16px" />
        </div>
        {children}
      </div>
    </div>
  );
}

export interface SortableListProps<I extends SortableEntity> {
  items: I[];
  onUpdateSortWeights: (newSortWeights: SortWeights) => void | Promise<any>;
  ItemRenderer: React.ComponentType<{ item: I }>;
}

export default function SortableList<I extends SortableEntity>({
  items,
  onUpdateSortWeights,
  ItemRenderer
}: SortableListProps<I>) {
  const [optimisticItems, setOptimisticItems] = useState<I[] | null>(null);
  const sorted = useSortedEntities(optimisticItems ?? items);
  // Check for optimistic items
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const handleDragEnd = useCallback(
    (evt: DragEndEvent) => {
      const { active, over } = evt;
      if (!over || active.id === over.id) return null;
      const currentDashboardIdx = sorted.findIndex(d => d.id === active.id);
      const currentDashboard = sorted[currentDashboardIdx];
      if (!currentDashboard) return null;
      const newDashboardIdx = sorted.findIndex(d => d.id === over.id);
      const rearranged = moveItem(sorted, currentDashboardIdx, newDashboardIdx);
      const sortWeights = rearranged.reduce<Record<string, string>>((weights, d) => {
        if (d.sortWeight) weights[d.id] = d.sortWeight;
        return weights;
      }, {});
      const order = rearranged.map(d => d.id);
      const updated = updateSortWeights(sortWeights, order);
      const result = onUpdateSortWeights(updated);
      // Set optimistic items if we can't do typical optimist results
      if (result != null) {
        setOptimisticItems(
          items.map(i => (updated[i.id] ? { ...i, sortWeight: updated[i.id] } : i))
        );
        result.finally(() => {
          if (!isMounted.current) return;
          setTimeout(() => {
            if (isMounted.current) setOptimisticItems(null);
          }, 100);
        });
      }
    },
    [sorted, onUpdateSortWeights]
  );
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={sorted} strategy={verticalListSortingStrategy}>
        {sorted.map(item => (
          <SortableItem
            // Adding sortWeight helps with weird animation jitters
            item={item}
            key={`${item.id}__${item.sortWeight}`}>
            <ItemRenderer item={item} />
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
}
