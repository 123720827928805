import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { queryClientAtom } from 'jotai-tanstack-query';
import isEqual from 'lodash/isEqual';
import { SyncState } from 'src/core/types';
import { DBFetchCallQueryKey } from 'src/core/types';
import { coreClientAtom } from '.';
import atomWithDebounce from './utils';

/**
 * This takes broadcast messages from a sync worker and adds it in state.
 *
 * The broadcast channel helps to subscribe to changes all the way from the sync worker
 * without adding a bunch of back and forth messages through the DB client
 */
export const syncStateAtom = atomWithDebounce<SyncState>({
  connected: false,
  dataSourceSectionShapesInitialized: false,
  dataPointShapesInitialized: false,
  shapes: []
});

// Intermediate atoms need to be mounted apparently
const currentUpToDateShapeHandlesAtom = atom(get => {
  const syncState = get(syncStateAtom.debouncedValueAtom);
  return new Map(
    syncState.shapes
      .filter(shape => shape.isUpToDate)
      .map(shape => [shape.name, { shapeHandle: shape.shapeHandle, lastOffset: shape.lastOffset }])
  );
});

export const syncInitializedAtom = atom(false);

export const _syncInitializedCheckAtom = atomEffect((get, set) => {
  if (get.peek(syncInitializedAtom)) return; // You can stop once its initialized
  const syncState = get(syncStateAtom.debouncedValueAtom);
  const fullySynced =
    syncState.connected &&
    // Only worry about the data source section stuff here in case there are no sections or anything
    syncState.dataSourceSectionShapesInitialized &&
    syncState.dataPointShapesInitialized &&
    syncState.shapes.every(shape => shape.initialized);
  if (fullySynced) set(syncInitializedAtom, true);
});

const previousUpToDateShapeHandlesAtom = atom<
  Map<string, { shapeHandle: string | null; lastOffset: string | null }>
>(new Map());

/**
 * The goal here is to find when changes are made after syncing and then invalidate all of the appropriate
 * queries. We can do that by identify which shapes are up to date and when there shapeHandle actually changes
 */
export const _autoRefetchDBQueriesAtom = atomEffect((get, set) => {
  const currentUpToDateShapeHandles = get(currentUpToDateShapeHandlesAtom);
  const coreClient = get(coreClientAtom);
  if (!coreClient?.initialized) return;
  // Important to use get.peek here or otherwise downstream updates are blocked because Jotai prevents infinite loops
  const previousUpToDateShapeHandles = get.peek(previousUpToDateShapeHandlesAtom);
  if (isEqual(currentUpToDateShapeHandles, previousUpToDateShapeHandles)) return;
  set(previousUpToDateShapeHandlesAtom, currentUpToDateShapeHandles);
  const queryClient = get(queryClientAtom);
  // For anything that wasn't up to date but is now up to date, invalidate queries so they refetch
  for (const [shapeName, { shapeHandle, lastOffset }] of currentUpToDateShapeHandles) {
    // No Change, carry on
    const prev = previousUpToDateShapeHandles.get(shapeName);
    if (prev && prev.shapeHandle === shapeHandle && prev.lastOffset === lastOffset) continue;
    if (shapeName === 'dashboards') {
      queryClient.invalidateQueries({ queryKey: ['db', 'getDashboard'] });
      queryClient.invalidateQueries({ queryKey: ['db', 'getAllDashboards'] });
    } else if (shapeName === 'dataSources') {
      queryClient.invalidateQueries({ queryKey: ['db', 'getDataSource'] });
      queryClient.invalidateQueries({ queryKey: ['db', 'getAllDataSources'] });
    } else if (shapeName.startsWith('dataSourceSections')) {
      queryClient.invalidateQueries({ queryKey: ['db', 'getDataSourceSection'] });
      queryClient.invalidateQueries({ queryKey: ['db', 'getDataSourceSectionsForDataSource'] });
      queryClient.invalidateQueries({ queryKey: ['db', 'getInitialSectionFields'] });
    } else if (shapeName.startsWith('dataPoints')) {
      const invalidateDataPointQueries = async () => {
        const dsId = shapeName.split(':')[1];
        const sections = await coreClient?.queries.getDataSourceSectionsForDataSource(dsId);
        const sectionIds = new Set(sections?.map(s => s.id) ?? []);
        queryClient.invalidateQueries({
          queryKey: ['db', 'queryDataPoints'],
          type: 'all',
          predicate(query) {
            const key = query.queryKey as DBFetchCallQueryKey<'queryDataPoints'>;
            return sectionIds.has(key[2].layerConfig[0].sectionId);
          }
        });
        queryClient.invalidateQueries({
          queryKey: ['db', 'getInitialSectionFields'],
          type: 'all',
          predicate(query) {
            const key = query.queryKey as DBFetchCallQueryKey<'getInitialSectionFields'>;
            return sectionIds.has(key[2]);
          }
        });
        queryClient.invalidateQueries({
          queryKey: ['db', 'getCategoryValues'],
          type: 'all',
          predicate(query) {
            const key = query.queryKey as DBFetchCallQueryKey<'getCategoryValues'>;
            return sectionIds.has(key[2]);
          }
        });
      };
      invalidateDataPointQueries();
    } else if (shapeName.startsWith('userFile')) {
      queryClient.invalidateQueries({ queryKey: ['db', 'getUserFile'] });
    }
  }
});
