import { RegisteredRouter, RouteIds } from '@tanstack/react-router';
import { isNotEmpty } from 'axil-utils';
import { AxiosError } from 'axios';
import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { getDeleteDashboardMutationOptions } from 'src/hooks';
import { Dashboard } from 'src/types/entities';
import {
  _storeAtom,
  coreClientAtom,
  isStandaloneAtom,
  promptAtom,
  routeMatchesAtom,
  routingAtom,
  toastAtom
} from '.';
import type { Command, CommandGroup } from './commands.atoms';
import { addWidgetAtom, editWidgetAtom, updateDialogAtom } from './dialogs.atoms';

export const dashboardListAtom = atomWithQuery(get => {
  const dbClient = get(coreClientAtom);
  return dbClient!.getQueryOptions.getAllDashboards();
});

const dashboardRouteIds: RouteIds<RegisteredRouter['routeTree']>[] = [
  '/authed/main/dash/$id',
  '/authed/dash/$id/edit',
  '/authed/dash/$dashboardId/add',
  '/authed/dash/$dashboardId/$widgetId/edit',
  '/authed/dash/$id/full'
];
const dashboardPageMatchAtom = atom(
  get => get(routeMatchesAtom)?.find(match => dashboardRouteIds.includes(match.routeId)) ?? null
);

export const currentDashboardAtom = atom<Dashboard | null>(get => {
  const dashboardList = get(dashboardListAtom);
  const match = get(dashboardPageMatchAtom);
  const currentDashId: string | null = match?.params?.dashboardId ?? match?.params.id ?? null;
  return currentDashId ? (dashboardList?.data?.find(d => d.id === currentDashId) ?? null) : null;
});

export const editDashboardLayoutAtom = atom(
  null,
  (get, set, id: string | undefined = get(currentDashboardAtom)?.id) => {
    if (!id) throw new Error('Dashboard id required');
    const router = get(routingAtom);
    if (!router) throw new Error('Router required');
    router.navigate({ to: '/dash/$id/edit', params: { id } });
  }
);

export const goFullScreenAtom = atom(
  null,
  (get, set, id: string | undefined = get(currentDashboardAtom)?.id) => {
    if (!id) throw new Error('Dashboard id required');
    const router = get(routingAtom);
    if (!router) throw new Error('Router required');
    router.navigate({ to: '/dash/$id/full', params: { id } });
  }
);

export const inspectWidgetAtom = atom(null, (get, set, widgetId: string) => {
  const router = get(routingAtom);
  if (!router) throw new Error('Router required');
  const dashboardId = get(currentDashboardAtom)?.id;
  if (!dashboardId) throw new Error('Dashboard id required');
  router.navigate({
    to: '/dash/$dashboardId/$widgetId',
    params: { dashboardId, widgetId }
  });
});

export const returnToDefaultDashboardViewAtom = atom(null, (get, set) => {
  const router = get(routingAtom);
  const currentId = get(currentDashboardAtom)?.id;
  if (!currentId) throw new Error('Current dashboard id required');
  if (!router) throw new Error('Router required');
  // Main use case, but not always there while in standalone
  const isStandalone = get(isStandaloneAtom);
  if (isStandalone) return router.navigate({ to: '/dash/$id/full', params: { id: currentId } });
  router.navigate({ to: '/dash/$id', params: { id: currentId } });
});

export const finishEditingLayoutAtom = atom(null, (get, set) => {
  return set(returnToDefaultDashboardViewAtom);
});

export const finishEditingWidgetAtom = atom(null, async (get, set, widgetId: string | null) => {
  await set(returnToDefaultDashboardViewAtom);
  if (!widgetId) return;
  setTimeout(() => {
    const widgetWrapper = document.body.querySelector(`[data-widget-id="${widgetId}"]`);
    if (!widgetWrapper) return;
    widgetWrapper.scrollIntoView({ block: 'start' });
  }, 300);
});

export const dashboardCommandsAtom = atom<CommandGroup | null>(get => {
  const store = get(_storeAtom);
  if (!store) return null;
  const router = get(routingAtom);
  const dashboardList = get(dashboardListAtom);
  const currentDashboard = get(currentDashboardAtom);
  const routeMatch = get(dashboardPageMatchAtom);
  let currentDashboardCommands: Command[] = [];
  const mainPageRoute = routeMatch?.routeId === '/authed/main/dash/$id';
  const editPageRoute = routeMatch?.routeId === '/authed/dash/$id/edit';
  const fullScreenRoute = routeMatch?.routeId === '/authed/dash/$id/full';
  if ((mainPageRoute || editPageRoute) && currentDashboard) {
    currentDashboardCommands = [
      {
        label: 'Add Widget',
        id: 'add-widget',
        handler: () => store.set(addWidgetAtom, currentDashboard.id)
      },
      mainPageRoute
        ? {
            label: 'Edit Layout',
            id: 'edit-layout',
            handler: () => store.set(editDashboardLayoutAtom)
          }
        : null,
      mainPageRoute
        ? {
            label: 'Go Fullscreen',
            id: 'go-fullscreen',
            handler: () => store.set(goFullScreenAtom)
          }
        : null,
      editPageRoute
        ? {
            label: 'Finish Editing',
            id: 'finish-editing',
            handler: () => store.set(finishEditingLayoutAtom)
          }
        : null,
      {
        label: 'Edit Dashboard Name',
        id: 'edit-dashboard',
        handler: () =>
          store.set(updateDialogAtom, { type: 'UpdateDashboard', payload: currentDashboard })
      },
      {
        label: 'Delete Current Dashboard',
        id: 'delete-dashboard',
        handler: () => store.set(deleteDashboardAtom, currentDashboard.id)
      },
      ...(currentDashboard.widgets
        ?.map(widget => {
          if (widget.type === 'Note') return [];
          return [
            {
              label: `Expand ${widget.title}`,
              id: `expand-widget-${widget.id}`,
              handler: () => store.set(inspectWidgetAtom, widget.id)
            },
            {
              label: `Edit ${widget.title}`,
              id: `edit-widget-${widget.id}`,
              handler: () =>
                store.set(editWidgetAtom, {
                  dashboardId: currentDashboard.id,
                  widgetId: widget.id
                })
            }
            // TODO: Add delete command
          ];
        })
        .flat() ?? [])
    ].filter(isNotEmpty);
  } else if (fullScreenRoute && currentDashboard) {
    currentDashboardCommands = [
      {
        label: 'Exit Fullscreen',
        id: 'exit-fullscreen',
        handler: () => router?.navigate({ to: '/dash/$id', params: { id: currentDashboard.id } })
      }
    ];
  }
  return {
    label: 'Dashboard',
    id: 'dashboard',
    commands: [
      ...currentDashboardCommands,
      ...(dashboardList?.data?.map(dashboard => [
        {
          label: `Go to ${dashboard.name}`,
          id: `go-to-dashboard-${dashboard.id}`,
          handler: () => router?.navigate({ to: '/dash/$id', params: { id: dashboard.id } })
        },
        {
          label: `Delete ${dashboard.name}`,
          id: `delete-dashboard-${dashboard.id}`,
          handler: () => store.set(deleteDashboardAtom, dashboard.id)
        }
      ]) ?? [])
    ]
      .flat()
      .filter(isNotEmpty)
  };
});

const deleteDashboardMutationAtom = atomWithMutation<unknown, string, AxiosError>(get => {
  const coreClient = get(coreClientAtom);
  if (!coreClient) throw new Error('Core client not initialized');
  const queryClient = get(queryClientAtom);
  return getDeleteDashboardMutationOptions(coreClient, queryClient);
});

export const deleteDashboardAtom = atom(null, async (get, set, id: string) => {
  const dashboardList = get(dashboardListAtom);
  const dashboard = dashboardList?.data?.find(d => d.id === id);
  if (!dashboard) throw new Error('Dashboard not found');
  const confirmed = await set(promptAtom, `Are you sure you want to delete ${dashboard?.name}?`);
  if (!confirmed) return;
  await get(deleteDashboardMutationAtom).mutateAsync(id);
  // Navigate away if on the dashboard page
  if (get(dashboardPageMatchAtom)?.params.id === id) {
    const router = get(routingAtom);
    router?.navigate({ to: '/' });
  }
  set(toastAtom, { title: 'Dashboard deleted' });
});

export const canOpenStandaloneDashboardAtom = atom(async get => {
  const coreClient = get(coreClientAtom);
  if (!coreClient) return false;
  const capabilities = await coreClient.getCapabilities();
  return capabilities.includes('open-standalone-dashboard');
});

export const openStandaloneDashboardAtom = atom(null, async (get, set, id: string) => {
  const coreClient = get(coreClientAtom);
  if (!coreClient) throw new Error('Core client not initialized');
  await coreClient.openStandaloneDashboard(id);
});
