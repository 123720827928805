import { Loading } from 'axil-web-ui';
import React, { useEffect, useState } from 'react';

interface TestDataSourceNotificationProps {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function TestDataSourceNotification({
  isLoading,
  isSuccess,
  isError
}: TestDataSourceNotificationProps) {
  const getTestingDataSourceMessage = () => {
    if (isLoading) return 'Testing connection...';
    if (isSuccess) return 'Success!';
    if (isError) return 'DataSource failed. Reconnect connection to fix it';
    return null;
  };
  const getTestingBackgroundColor = () => {
    if (isLoading) return 'bg-info';
    if (isSuccess) return 'bg-success';
    if (isError) return 'bg-error';
    return null;
  };
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (isLoading) return;
  }, [isLoading, isSuccess, isError]);
  useEffect(() => {
    if (isLoading) {
      setShowToast(true);
      return;
    }
    const timeout = setTimeout(() => {
      setShowToast(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);
  if (!showToast) {
    return null;
  }
  return (
    <div className="fixed bottom-0 left-0 right-0 my-4 flex justify-center">
      <div
        className={`flex min-w-[200px] max-w-[auto] flex-row items-center justify-center rounded p-8 shadow-lg ${getTestingBackgroundColor()}`}>
        <h4 className="mx-8 text-center text-black">{getTestingDataSourceMessage()}</h4>
        {isLoading ? <Loading /> : null}
      </div>
    </div>
  );
}

export default TestDataSourceNotification;
