import { Button, Collapsible, CollapsibleContent, CollapsibleTrigger } from 'axil-web-ui';

import { DatabaseZapIcon, LayoutDashboardIcon, Plus as PlusIcon } from 'lucide-react';
import React from 'react';
import ResizeHandle from 'src/components/common/ResizeHandle';
import { useStorageBoolean } from 'src/hooks/storage';
import { getLogoSrc } from 'src/utils';

import { Link } from '@tanstack/react-router';
import { useAtomValue, useSetAtom } from 'jotai';
import { updateDialogAtom } from 'src/atoms';
import { dashboardListAtom } from 'src/atoms/dashboards.atoms';
import { dataSourceListAtom } from 'src/atoms/dataSources.atoms';
import OnboardingIndicator from './OnboardingIndicator';
import SortableList from './SortableList';
import {
  useConnectorById,
  useUpdateDashboardSortWeights,
  useUpdateDataSourceSortWeights
} from 'src/hooks';

interface SidebarProps {
  adjustable?: boolean;
}

const DefaultNavWidth = 300;
const DashboardNavWidthVarName = `--dashboard-nav-width`;

function Sidebar({ adjustable }: SidebarProps) {
  const { data: dashboards } = useAtomValue(dashboardListAtom);
  const updateDashboardSortWeights = useUpdateDashboardSortWeights();
  const [dashboardListOpen, setDashboardListOpen] = useStorageBoolean(
    'sidebar:dashboardListOpen',
    true
  );

  const { data: dataSources } = useAtomValue(dataSourceListAtom);
  const updateDataSourceSortWeights = useUpdateDataSourceSortWeights();
  const [dataSourceListOpen, setDataSourceListOpen] = useStorageBoolean(
    'sidebar:dataSourceListOpen',
    true
  );
  const { isLoading: connectorsLoading, connectors } = useConnectorById();
  const updateDialog = useSetAtom(updateDialogAtom);

  return (
    <aside
      className="bg-base-300 relative flex h-full max-h-full min-h-0 max-w-full flex-col border-r-2 dark:border-r-0 dark:bg-black"
      style={{
        gridArea: 'sidebar',
        width: adjustable
          ? `var(${DashboardNavWidthVarName}, ${DefaultNavWidth}px)`
          : `${DefaultNavWidth}px`
      }}>
      {adjustable ? (
        <ResizeHandle
          cssVarName={DashboardNavWidthVarName}
          defaultDim={DefaultNavWidth}
          persist
          minDim={240}
          maxDim={600}
        />
      ) : null}
      <OnboardingIndicator />
      <div className="min-h-0 shrink grow overflow-y-auto overflow-x-hidden py-8">
        <Collapsible
          open={dashboardListOpen}
          onOpenChange={open => setDashboardListOpen(open)}
          className="mb-8">
          <div className="mb-4 flex w-full justify-between pl-8 pr-12">
            <div className="flex shrink-0 flex-row flex-wrap items-center justify-start gap-2">
              <LayoutDashboardIcon />
              <h3 className="text-lg font-bold">Dashboards</h3>
              <Button
                size="md"
                ghost
                square
                onClick={() =>
                  updateDialog({
                    type: 'CreateDashboard'
                  })
                }>
                <PlusIcon size="20px" />
              </Button>
            </div>
            {dashboards && dashboards.length > 0 ? <CollapsibleTrigger /> : null}
          </div>
          <CollapsibleContent className="flex flex-col gap-2 px-8">
            {dashboards ? (
              <SortableList
                items={dashboards}
                onUpdateSortWeights={sortWeights =>
                  updateDashboardSortWeights.mutateAsync(sortWeights)
                }
                ItemRenderer={({ item: dashboard }) => {
                  return (
                    <Link
                      to={'/dash/' + dashboard.id}
                      className="inline-flex max-w-full shrink grow items-center break-words py-1"
                      activeProps={{ style: { textDecoration: 'underline' } }}>
                      <span>{dashboard.name}</span>
                    </Link>
                  );
                }}
              />
            ) : null}
          </CollapsibleContent>
          {dashboards?.length === 0 ? (
            <p className="pl-8 text-neutral-500">Add your first Dashboard</p>
          ) : null}
        </Collapsible>
        <Collapsible open={dataSourceListOpen} onOpenChange={open => setDataSourceListOpen(open)}>
          <div className="mb-4 flex w-full justify-between pl-8 pr-12">
            <div className="flex shrink-0 flex-row flex-wrap items-center justify-start gap-2">
              <DatabaseZapIcon />
              <h3 className="text-lg font-bold">Data Sources</h3>
              <Button
                size="md"
                ghost
                square
                onClick={() =>
                  updateDialog({
                    type: 'CreateDataSource',
                    payload: null
                  })
                }>
                <PlusIcon size="20px" />
              </Button>
            </div>
            {dataSources && dataSources.length > 0 ? <CollapsibleTrigger /> : null}
          </div>
          <CollapsibleContent className="flex flex-col gap-2 px-8">
            {dataSources && dataSources.length > 0 ? (
              <SortableList
                items={dataSources}
                onUpdateSortWeights={sortWeights =>
                  updateDataSourceSortWeights.mutateAsync(sortWeights)
                }
                ItemRenderer={({ item: dataSource }) => {
                  const connectorInfo = connectors?.[dataSource.connectorId];
                  return (
                    <>
                      <div>
                        {connectorInfo?.logo ? (
                          <div className="size-6 p-0.5">
                            <img
                              src={getLogoSrc(connectorInfo.logo)}
                              className="h-full w-full object-contain"
                            />
                          </div>
                        ) : null}
                      </div>
                      <Link
                        to={'/data-source/' + dataSource.id}
                        className="inline-flex max-w-full shrink grow items-center break-words py-1 font-thin"
                        activeProps={{ style: { textDecoration: 'underline' } }}>
                        <span>{dataSource.name}</span>
                      </Link>
                    </>
                  );
                }}
              />
            ) : null}
          </CollapsibleContent>
          {dataSources?.length === 0 ? (
            <p className="pl-8 text-neutral-500">Add your first Data Source</p>
          ) : null}
        </Collapsible>
      </div>
    </aside>
  );
}

export default Sidebar;
