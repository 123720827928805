import { Button, Tooltip } from 'axil-web-ui';

import { HelpCircleIcon, RedoIcon, UndoIcon } from 'lucide-react';
import React from 'react';
import { useDashboardUndo, useUpdateDashboard } from 'src/hooks';
import { Dashboard } from 'src/types/entities';

interface EditBarProps {
  dashboard: Dashboard;
  onFinishEditing: () => void;
  onAddWidget: () => void;
}

function EditBar({ dashboard, onFinishEditing, onAddWidget }: EditBarProps) {
  const mutation = useUpdateDashboard();
  const { undo, redo, canUndo, canRedo } = useDashboardUndo(dashboard, mutation);
  return (
    <div className="bg-secondary sticky top-0 z-10 flex shrink-0 flex-row justify-center gap-12 px-8 py-2">
      <div className="flex flex-row gap-4">
        <Button disabled={!canUndo} onClick={() => undo()}>
          <UndoIcon />
        </Button>
        <Button disabled={!canRedo} onClick={() => redo()}>
          <RedoIcon />
        </Button>
      </div>
      <div className="flex flex-row items-center gap-4">
        <Button onClick={onAddWidget} color="primary">
          Add Widget
        </Button>
        <Button onClick={onFinishEditing} color="accent">
          Finish Editing
        </Button>
        <Tooltip
          message="Resize widgets by moving the handle on the bottom right. Rearrange the widgets by dragging the handle on the top left"
          placement="bottom">
          <HelpCircleIcon size={24} />
        </Tooltip>
      </div>
    </div>
  );
}

export default EditBar;
