import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { Widget } from 'daydash-data-structures';
import isEqualWith from 'lodash/isEqualWith';
import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useMemo } from 'react';
import { useCoreClient } from '../../context';
import { Dashboard } from '../../types/entities';
import { DashboardCreatePayload, DashboardUpdatePayload } from '../../types/service';
import useUndo from '../use-undo';
import axios, { AxiosError, AxiosResponse } from 'axios';
import CoreClient from 'src/core/core.client';
import { useAtomValue } from 'jotai';
import { userIdAtom } from 'src/atoms';

export const useAllDashboards = () => {
  const coreClient = useCoreClient();
  return useQuery(coreClient.getQueryOptions.getAllDashboards());
};

export const useSortedDashboards = (unsorted: Dashboard[] = []) => {
  return useMemo(() => {
    return sortBy(
      unsorted,
      d => d.sortWeight,
      d => new Date(d.createdAt)
    );
  }, [unsorted]);
};

export const useDashboard = (id: string) => {
  const coreClient = useCoreClient();
  return useQuery(coreClient.getQueryOptions.getDashboard(id));
};

async function optimisticallyUpdateAllDashboards(
  updatedDashboards: (Partial<Dashboard> & { id: string })[],
  coreClient: CoreClient,
  queryClient: QueryClient
) {
  const queryKey = coreClient.getQueryOptions.getAllDashboards().queryKey;
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey });
  // Snapshot the previous values
  const previousDashboards = queryClient.getQueryData<Dashboard[] | undefined>(queryKey);
  // Optimistically update to the new value
  const byId = new Map(updatedDashboards?.map(d => [d.id, d]) ?? []);
  queryClient.setQueryData<Dashboard[] | undefined>(queryKey, old => {
    if (!old) return;
    return old.map(d => {
      const updated = byId.get(d.id);
      if (updated) return { ...d, ...updated };
      return d;
    });
  });
  return previousDashboards;
}

async function optimisticallyUpdateDashboard(
  updatedDashboard: Partial<Dashboard> & { id: string },
  userId: string,
  coreClient: CoreClient,
  queryClient: QueryClient
) {
  const queryKey = coreClient.getQueryOptions.getDashboard(updatedDashboard.id).queryKey;
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey });
  // Snapshot the previous values
  const previousDashboard = queryClient.getQueryData<Dashboard | undefined>(queryKey);

  // Optimistically update to the new value
  queryClient.setQueryData<Dashboard | null>(queryKey, old => {
    if (!old) {
      if (updatedDashboard.name && updatedDashboard.widgets) {
        return {
          ownerId: userId,
          createdAt: new Date(),
          updatedAt: new Date(),
          localUpdatedAt: null,
          ...updatedDashboard
        } as Dashboard;
      }
      return null;
    }
    return { ...old, ...updatedDashboard };
  });
  return previousDashboard;
}

export const useCreateDashboard = () => {
  const queryClient = useQueryClient();
  const coreClient = useCoreClient();
  const userId = useAtomValue(userIdAtom);
  const mutation = useMutation<
    AxiosResponse<Dashboard>,
    unknown,
    DashboardCreatePayload,
    {
      previousDashboard: Dashboard | undefined;
      previousDashboards: Dashboard[] | undefined;
    }
  >({
    mutationFn: payload => {
      return axios.post('/dashboard', {
        id: payload.id,
        name: payload.name,
        sortWeight: payload.sortWeight,
        widgets: payload.widgets
      });
    },
    onMutate: async newDashboard => {
      return {
        previousDashboard: await optimisticallyUpdateDashboard(
          newDashboard,
          userId,
          coreClient,
          queryClient
        ),
        previousDashboards: await optimisticallyUpdateAllDashboards(
          [newDashboard],
          coreClient,
          queryClient
        )
      };
    },
    onError: (error, variables, context) => {
      if (!context) return;
      queryClient.setQueryData(
        coreClient.getQueryOptions.getAllDashboards().queryKey,
        context.previousDashboards
      );
      queryClient.setQueryData(
        coreClient.getQueryOptions.getDashboard(variables.id).queryKey,
        context.previousDashboard
      );
    }
  });
  return mutation;
};

export const useUpdateDashboard = () => {
  const coreClient = useCoreClient();
  const userId = useAtomValue(userIdAtom);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<Dashboard>,
    unknown,
    DashboardUpdatePayload,
    {
      previousDashboard: Dashboard | undefined;
      previousDashboards: Dashboard[] | undefined;
    }
  >({
    mutationFn: payload => {
      return axios.put(`/dashboard/${payload.id}`, {
        name: payload.name,
        sortWeight: payload.sortWeight,
        widgets: payload.widgets
      });
    },
    onMutate: async newDashboard => {
      return {
        previousDashboard: await optimisticallyUpdateDashboard(
          newDashboard,
          userId,
          coreClient,
          queryClient
        ),
        previousDashboards: await optimisticallyUpdateAllDashboards(
          [newDashboard],
          coreClient,
          queryClient
        )
      };
    },
    onError: (error, variables, context) => {
      if (!context) return;
      queryClient.setQueryData(
        coreClient.getQueryOptions.getAllDashboards().queryKey,
        context.previousDashboards
      );
      queryClient.setQueryData(
        coreClient.getQueryOptions.getDashboard(variables.id).queryKey,
        context.previousDashboard
      );
    }
  });
};

export const useUpsertWidget = (
  dashboard: Dashboard | null
): {
  upsert: (widget: Widget) => Promise<void>;
  dashboardMutation: ReturnType<typeof useUpdateDashboard>;
} => {
  const dashboardMutation = useUpdateDashboard();
  // TODO: Expand this a bit where you can try to compose mutations somehow
  const upsert = useCallback(
    async (newWidget: Widget) => {
      if (!dashboard) return;
      const current = dashboard.widgets;
      const newWidgets = current?.find(w => w.id === newWidget.id)
        ? current.map(w => (w.id === newWidget.id ? newWidget : w))
        : [...(current ?? []), newWidget];
      await dashboardMutation.mutateAsync({
        ...dashboard,
        widgets: newWidgets
      });
    },
    [dashboardMutation, dashboard]
  );

  return {
    upsert,
    dashboardMutation
  };
};

type SortWeights = Record<string, string | null>;

const isNewWeight = (updated: [string, string | null]): updated is [string, string] => {
  return Boolean(updated[1]);
};

export const useUpdateDashboardSortWeights = () => {
  const coreClient = useCoreClient();
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<Dashboard>[], unknown, SortWeights, Dashboard[]>({
    mutationFn: weights => {
      return Promise.all(
        Object.entries(weights)
          .filter(isNewWeight)
          .map(async ([id, newWeight]) =>
            axios.patch(`/dashboard/${id}`, { sortWeight: newWeight })
          )
      );
    },
    onMutate: async newWeights =>
      optimisticallyUpdateAllDashboards(
        Object.entries(newWeights).map(([id, sortWeight]) => ({ id, sortWeight })),
        coreClient,
        queryClient
      ),
    onError: (error, newWeights, previousDashboards) => {
      if (!previousDashboards) return;
      queryClient.setQueryData(
        coreClient.getQueryOptions.getAllDashboards().queryKey,
        previousDashboards
      );
    }
  });
  return mutation;
};

export const getDeleteDashboardMutationOptions = (
  coreClient: CoreClient,
  queryClient: QueryClient,
  onSuccess?: () => void
): UseMutationOptions<unknown, AxiosError, string> => ({
  mutationFn: id => axios.delete(`/dashboard/${id}`),
  async onSuccess(_, id) {
    const dashboardsQueryKey = coreClient.getQueryOptions.getAllDashboards().queryKey;
    // Optimistically update to the new value while we wait for the sync
    queryClient.setQueryData<Dashboard[] | undefined>(dashboardsQueryKey, old => {
      if (!old) return;
      return old.filter(d => d.id !== id);
    });
    const dashboardQueryKey = coreClient.getQueryOptions.getDashboard(id!).queryKey;
    queryClient.setQueryData<Dashboard | null>(dashboardQueryKey, null);
    onSuccess?.();
  }
});

export const useDeleteDashboard = (onSuccess?: () => void) => {
  const coreClient = useCoreClient();
  const queryClient = useQueryClient();
  return useMutation(getDeleteDashboardMutationOptions(coreClient, queryClient, onSuccess));
};

const dashboardContentEqual = (
  d1: Dashboard | null | undefined,
  d2: Dashboard | null | undefined
) => {
  return isEqualWith(d1, d2, (d1Val, d2Val, key) => {
    // Don't flag them as unequal if only the updated dates are different
    if (key === 'updatedAt' || key === 'localUpdatedAt') return true;
    return undefined;
  });
};

export function useDashboardUndo(
  current: Dashboard | null | undefined,
  mutation: UseMutationResult<any, any, DashboardUpdatePayload>
) {
  const [undoDashboardState, { set: setUndo, undo, redo, canUndo, canRedo, reset }] =
    useUndo(current);

  // Reset whenever the actual dashboard changes
  useEffect(() => {
    reset(current);
  }, [current?.id]);

  // Whenever the server state changes, set the undoDashboard state
  useEffect(() => {
    if (
      undoDashboardState.present?.id === current?.id &&
      !dashboardContentEqual(undoDashboardState.present, current)
    ) {
      setUndo(current);
    }
  }, [current]);
  // Whenever the undo dashboard state changes, update the current
  useEffect(() => {
    if (
      undoDashboardState.present &&
      undoDashboardState.present?.id === current?.id &&
      !dashboardContentEqual(undoDashboardState.present, current)
    ) {
      mutation.mutate(undoDashboardState.present);
    }
  }, [undoDashboardState.present]);
  return {
    undo,
    redo,
    canUndo,
    canRedo
  };
}
