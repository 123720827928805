import { isNotEmpty } from 'axil-utils';
import { AxiosError, AxiosResponse } from 'axios';
import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { _storeAtom, promptAtom, routeMatchesAtom, routingAtom, toastAtom } from '.';
import type { Command, CommandGroup } from './commands.atoms';
import { coreClientAtom } from '.';
import { getDeleteDataSourceMutationOptions } from 'src/hooks';

export const dataSourceListAtom = atomWithQuery(get => {
  const dbClient = get(coreClientAtom);
  return dbClient!.getQueryOptions.getAllDataSources();
});

const dataSourcePageRouteAtom = atom(get => {
  const routeMatches = get(routeMatchesAtom);
  return routeMatches?.find(match => match.routeId === '/authed/main/data-source/$id');
});
export const currentDataSourceAtom = atom(get => {
  const dataSourceList = get(dataSourceListAtom);
  if (!dataSourceList) return null;
  const pageRoute = get(dataSourcePageRouteAtom);
  const currentDataSourceId: string | null = pageRoute?.params.id;
  const currentDataSource = currentDataSourceId
    ? dataSourceList?.data?.find(d => d.id === currentDataSourceId)
    : null;
  return currentDataSource;
});

export const dataSourceCommandsAtom = atom<CommandGroup | null>(get => {
  const store = get(_storeAtom);
  if (!store) return null;
  const router = get(routingAtom);
  const currentDataSource = get(currentDataSourceAtom);
  const dataSourceList = get(dataSourceListAtom);
  if (!dataSourceList) return null;
  const currentDataSourceCommands: Command[] = [];
  if (currentDataSource) {
    // TODO: Add delete data source
  }
  return {
    label: 'Data Source',
    id: 'datasource',
    commands: [
      ...currentDataSourceCommands,
      ...(dataSourceList?.data?.map(dataSource => [
        {
          label: `Go to ${dataSource.name}`,
          id: `go-to-data-source-${dataSource.id}`,
          handler: () => router?.navigate({ to: '/data-source/$id', params: { id: dataSource.id } })
        },
        {
          label: `Delete ${dataSource.name}`,
          id: `delete-data-source-${dataSource.id}`,
          handler: () => store.set(deleteDataSourceAtom, dataSource.id)
        }
      ]) ?? [])
    ]
      .flat()
      .filter(isNotEmpty)
  };
});

const deleteDataSourceMutationAtom = atomWithMutation<AxiosResponse, string, AxiosError>(get => {
  const coreClient = get(coreClientAtom);
  if (!coreClient) throw new Error('Core client not found');
  const queryClient = get(queryClientAtom);
  return getDeleteDataSourceMutationOptions(coreClient, queryClient);
});
export const deleteDataSourceAtom = atom(null, async (get, set, id: string) => {
  const dataSource = get(dataSourceListAtom)?.data?.find(d => d.id === id);
  if (!dataSource) throw new Error('Datasource not found');
  const confirmed = await set(promptAtom, `Are you sure you want to delete ${dataSource.name}?`);
  if (!confirmed) return;
  await get(deleteDataSourceMutationAtom).mutateAsync(id);
  // Navigate away if on the dashboard page
  if (get(dataSourcePageRouteAtom)?.params.id === id) {
    const router = get(routingAtom);
    router?.navigate({ to: '/' });
  }
  set(toastAtom, { title: 'Data Source Deleted', description: dataSource.name });
});
