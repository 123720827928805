import { atom } from 'jotai';

const getOnlineStatus = () => {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
};

export const onlineStatusAtom = atom(getOnlineStatus());
onlineStatusAtom.onMount = set => {
  const syncOnlineState = () => set(getOnlineStatus());
  window.addEventListener('offline', syncOnlineState);
  window.addEventListener('online', syncOnlineState);
  return () => {
    window.removeEventListener('offline', syncOnlineState);
    window.removeEventListener('online', syncOnlineState);
  };
};

export const idleAtom = atom(false);
idleAtom.onMount = set => {
  let timeout: number | null = null;
  const handleActivity = () => {
    set(false);
    if (timeout) clearTimeout(timeout);
    timeout = window.setTimeout(() => set(true), 1000 * 30);
  };
  handleActivity();
  document.addEventListener('pointermove', handleActivity, { passive: true });
  document.addEventListener('keydown', handleActivity, { passive: true });
  document.addEventListener('scroll', handleActivity, { passive: true });
  return () => {
    document.removeEventListener('pointermove', handleActivity);
    document.removeEventListener('keydown', handleActivity);
    document.removeEventListener('scroll', handleActivity);
    timeout && clearTimeout(timeout);
  };
};
