import { createContext } from 'react';

const DashboardContext = createContext<{
  containerRect: Omit<DOMRectReadOnly, 'toJSON'> | null;
  inspectingWidget: boolean;
  isEditing?: boolean;
  gridGap: number;
  containerPadding: number;
  rowHeight: number;
  colCount: number;
}>({
  containerRect: null,
  inspectingWidget: false,
  gridGap: 8,
  containerPadding: 16,
  rowHeight: 160,
  colCount: 4
});

export default DashboardContext;
