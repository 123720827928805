import { BaseFieldTypeDesc } from './common';
export default class DistanceDesc extends BaseFieldTypeDesc {
    typeLabel = 'Distance';
    dataLabel = 'Distance';
    unitLabels = {
        km: 'Kilometer',
        mi: 'Mile',
        m: 'Meter',
        ft: 'Foot',
        cm: 'Centimeter',
        in: 'Inch'
    };
    static dataPointNumberFormats = {
        km: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'kilometer',
            unitDisplay: 'short'
        }),
        mi: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'mile',
            unitDisplay: 'short'
        }),
        m: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'meter',
            unitDisplay: 'short'
        }),
        ft: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'foot',
            unitDisplay: 'short'
        }),
        cm: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'centimeter',
            unitDisplay: 'short'
        }),
        in: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'inch',
            unitDisplay: 'short'
        })
    };
    static tickNumberFormats = {
        km: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'kilometer',
            unitDisplay: 'narrow'
        }),
        mi: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'mile',
            unitDisplay: 'narrow'
        }),
        m: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'meter',
            unitDisplay: 'narrow'
        }),
        ft: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'foot',
            unitDisplay: 'narrow'
        }),
        cm: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'centimeter',
            unitDisplay: 'narrow'
        }),
        in: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'inch',
            unitDisplay: 'narrow'
        })
    };
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = DistanceDesc.dataPointNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = DistanceDesc.tickNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
}
