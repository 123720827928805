import { format as dateFormat } from 'date-fns';
import { BaseFieldTypeDesc } from './common';
export default class MonthOfYearDesc extends BaseFieldTypeDesc {
    typeLabel = 'Month of Year';
    dataLabel = 'Month of Year';
    unitLabels = {
        month: 'Month'
    };
    static getDateToFormat = (monthIndex) => {
        return new Date(new Date().getFullYear(), monthIndex);
    };
    dataPointFormatter = (field, dataPoint) => {
        const date = MonthOfYearDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'MMMM');
    };
    tickFormatter = (field, dataPoint) => {
        const date = MonthOfYearDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'MMM');
    };
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
