import { Select, cn } from 'axil-web-ui';

import React, { useMemo, useState } from 'react';
import { getLogoSrc } from 'src/utils';
import PageLoader from '../common/loading/PageLoader';
import { useWindowBreakpointSize } from 'src/hooks/styles';
import { useDataSourceForm } from './context';
import { useConnectorById } from 'src/hooks';
import { getFieldDisplayErrors } from 'src/utils/form';

const PickDataSourceStep = () => {
  const { isLoading, connectors } = useConnectorById();
  const form = useDataSourceForm();
  const field = form.useField({
    name: 'connectorId',
    validators: {
      onChange: ({ value }) => {
        if (!value) return 'Please select a connector';
      }
    }
  });
  const sortedConnectors = useMemo(() => {
    if (!connectors) return null;
    return Object.values(connectors).sort((a, b) => {
      // TODO: Add supported client check in here so we deemphasize unsupported connectors
      return b.sortPriority - a.sortPriority || a.name.localeCompare(b.name);
    });
  }, [connectors]);
  const [selectedFilter, setSelectedFilter] = useState<string | 'all'>('all');
  const allCategories = useMemo(() => {
    if (!sortedConnectors) return null;
    const categorySet = sortedConnectors.reduce((cats, conn) => {
      conn.categories.forEach(cat => cats.add(cat));
      return cats;
    }, new Set<string>());
    return Array.from(categorySet).sort((a, b) => {
      if (a === 'Other') return 1;
      return a.localeCompare(b);
    });
  }, [sortedConnectors]);
  const finalConnectorList = useMemo(() => {
    if (!sortedConnectors) return null;
    if (selectedFilter === 'all') return sortedConnectors;
    return sortedConnectors.filter(conn => conn.categories.includes(selectedFilter));
  }, [selectedFilter, sortedConnectors]);
  const options = useMemo(() => {
    if (!allCategories) return null;
    return [
      { label: 'All', value: 'all' },
      ...allCategories.map(category => ({
        label: category,
        value: category
      }))
    ];
  }, [allCategories]);

  const screenSize = useWindowBreakpointSize();
  const mobileMode = screenSize === 'sm';
  const errors = getFieldDisplayErrors(field, true);
  if (!connectors) return null;
  return (
    <div className="flex h-full min-h-0 w-full shrink flex-col items-center">
      <h2 className="w-full text-center text-2xl font-bold">Select a Connector Type</h2>
      {isLoading ? (
        <PageLoader />
      ) : options && finalConnectorList ? (
        <div className="flex h-full min-h-0 w-full shrink flex-col sm:p-8 md:flex-row md:items-start md:justify-start">
          {!mobileMode ? (
            <div className="shrink-0 grow-0 p-8">
              <ul className="flex flex-col gap-4">
                {options.map(opt => {
                  return (
                    <li
                      role="menuitem"
                      key={opt.value}
                      className={cn(
                        'cursor-pointer text-lg',
                        selectedFilter === opt.value && 'text-primary underline'
                      )}
                      onClick={() => setSelectedFilter(opt.value)}>
                      {opt.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div className="w-full p-4">
              <Select
                value={options.find(o => o.value === selectedFilter) ?? options[0]}
                name="selectedCategory"
                label="Category"
                onChange={o => setSelectedFilter(o?.value ?? 'all')}
                options={options}
              />
            </div>
          )}
          <div className="flex max-h-full shrink grow flex-col items-stretch gap-8 overflow-auto p-4 sm:p-8 md:flex-row md:flex-wrap md:items-stretch">
            {finalConnectorList.map(conn => {
              return (
                <button
                  autoFocus={field.state.value === conn.id}
                  className={cn(
                    'flex cursor-pointer flex-col items-start gap-4 rounded-lg bg-neutral-200 p-4 shadow-md transition-transform hover:scale-105 md:basis-72 dark:bg-neutral-500',
                    field.state.value === conn.id && 'outline-primary outline outline-4'
                  )}
                  onClick={() => field.handleChange(conn.id)}
                  key={conn.id}>
                  <div className="flex items-center gap-4">
                    <div className="size-20 cursor-pointer">
                      <img src={getLogoSrc(conn.logo)} className="h-full w-full object-contain" />
                    </div>
                    <label className="cursor-pointer text-xl font-bold">{conn.name}</label>
                  </div>
                  <label className="cursor-pointer text-left">{conn.description}</label>
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
      {errors?.map((error, idx) =>
        error ? (
          <div className="center text-error" key={idx}>
            {error}
          </div>
        ) : null
      )}
    </div>
  );
};

export default PickDataSourceStep;
