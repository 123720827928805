import { getDateFromSerial } from 'axil-utils';
/**
 * Day values are stored as UTC on the service days at midnight (for various reasons)
 * In order to display the right day, go ahead and convert that date so it works
 * with the user's local timezone
 */
export function getLocalizedDayDate(t) {
    const d = new Date(t);
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
}
export function getMillisecondsForField(val, field) {
    if (field.unit === 'epochMS' || field.unit === 'ms')
        return val;
    if (field.unit === 'sec' || field.unit === 'epochSeconds')
        return val * 1000;
    if (field.unit === 'min')
        return val * 1000 * 60;
    if (field.unit === 'SerialNumber')
        return getDateFromSerial(val).getTime();
    throw new Error('Invalid time unit!');
}
