import { useQuery } from '@tanstack/react-query';
import { isNotEmpty } from 'axil-utils';
import { BaseLayerConfig, LayerConfig, NumericAbstractDataField } from 'daydash-data-structures';
import { useContext } from 'react';
import { CoreClientContext } from '../context';
import { DBFetchQueryOptions } from 'src/core/types';
import { DataFetchOrder } from 'src/core/dataFetching';
import { useAtomValue } from 'jotai';
import { currentUserAtom } from 'src/atoms/user.atoms';

const useCoreClient = () => {
  const coreClient = useContext(CoreClientContext);
  if (!coreClient) throw new Error('CoreClient not found');
  return coreClient;
};

export const useDataSourceSection = (dataSourceSectionId: string | null) => {
  const coreClient = useCoreClient();
  return useQuery(
    dataSourceSectionId
      ? coreClient.getQueryOptions.getDataSourceSection(dataSourceSectionId)
      : ({
          enabled: false
        } as DBFetchQueryOptions<'getDataSourceSection'>)
  );
};

export const useDataSourceSections = (dataSourceId: string | null) => {
  const coreClient = useCoreClient();
  return useQuery(
    dataSourceId
      ? coreClient.getQueryOptions.getDataSourceSectionsForDataSource(dataSourceId)
      : ({
          enabled: false
        } as DBFetchQueryOptions<'getDataSourceSectionsForDataSource'>)
  );
};

export const useCategoryValues = (sectionId: string | null, fieldName: string | null) => {
  const coreClient = useCoreClient();
  return useQuery(
    sectionId && fieldName
      ? coreClient.getQueryOptions.getCategoryValues(sectionId, fieldName)
      : ({
          enabled: false
        } as DBFetchQueryOptions<'getCategoryValues'>)
  );
};

export const useDataFetch = (
  layers: [BaseLayerConfig, ...LayerConfig[]] | null = null,
  {
    selected,
    pageIndex = 0,
    pageSize = 2500,
    order = null,
    defaultDir = 'DESC'
  }: {
    pageIndex?: number;
    pageSize?: number;
    defaultDir?: 'ASC' | 'DESC';
    selected: string[] | 'all'; // Final selection of data points
    order?: DataFetchOrder[] | null;
  }
) => {
  const currentUser = useAtomValue(currentUserAtom);
  if (!currentUser) throw new Error('User Required');
  const coreClient = useCoreClient();
  return useQuery(
    (layers?.every(l => isNotEmpty(l))
      ? coreClient.getQueryOptions.queryDataPoints({
          layerConfig: layers as [BaseLayerConfig, ...LayerConfig[]],
          unitPreferences: currentUser.preferences.units,
          selected,
          pageIndex,
          pageSize,
          defaultDir,
          order
        })
      : {
          enabled: false
        }) as DBFetchQueryOptions<'queryDataPoints'>
  );
};

/**
 * Useful for finding bounds for data sets you are querying
 */
export function useDataBoundDataFetch(
  initialLayers: [BaseLayerConfig, ...LayerConfig[]] | null,
  yFields: NumericAbstractDataField[] | null
) {
  return useDataFetch(
    yFields && initialLayers
      ? [
          ...initialLayers,
          {
            grouping: {
              type: 'all',
              aggregateFields: yFields.flatMap(yField => [
                {
                  operation: 'min' as const,
                  fieldName: yField.name
                },
                {
                  operation: 'max' as const,
                  fieldName: yField.name
                }
              ])
            }
          }
        ]
      : null,
    {
      selected: 'all'
    }
  );
}
