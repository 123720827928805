import { BaseFieldTypeDesc, getDateFormatter } from './common';
export default class DateTimeDesc extends BaseFieldTypeDesc {
    typeLabel = 'Date Time';
    dataLabel = 'Time';
    unitLabels = {
        epochSeconds: 'Epoch Seconds',
        epochMS: 'Epoch Milliseconds',
        SerialNumber: 'Serial Number'
    };
    defaultScale = 'time';
    // TODO: This depends on if we are going by day on the date/time scale what. Figure this out
    tickFormatter = getDateFormatter('P');
    dataPointFormatter = getDateFormatter('Pp');
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
