import { isFunction } from 'lodash';
import { BaseFieldTypeDesc } from './common';
export default class NumberDesc extends BaseFieldTypeDesc {
    typeLabel = 'Number';
    unitLabels = {
        float: 'Decimal',
        int: 'Whole Number',
        percentage: 'Percentage'
    };
    dataLabel = (field) => {
        if (field.type !== 'number') {
            throw new Error('Invalid field passed into NumberDesc');
        }
        if (field.unit === 'percentage') {
            return 'Percentage';
        }
        if (field.unit === 'float' || field.unit === 'int') {
            return 'Amount';
        }
        return '';
    };
    getAxisLabel = (field, combined) => {
        // A bit of a hack to work around awkward class implementation
        let mainLabel = combined
            ? this.dataLabel
            : field.label;
        if (isFunction(mainLabel))
            mainLabel = mainLabel(field);
        return mainLabel;
    };
    static dataPointNumberFormats = {
        float: new Intl.NumberFormat(undefined, {
            notation: 'standard' // TODO: Maybe go to scientific if there are too many digits
        }),
        int: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            notation: 'standard' // TODO: Maybe go to scientific if there are too many digits
        }),
        percentage: new Intl.NumberFormat(undefined, {
            style: 'unit',
            unit: 'percent',
            unitDisplay: 'short'
        })
    };
    static tickNumberFormats = {
        float: new Intl.NumberFormat(undefined, {
            notation: 'standard' // TODO: Maybe go to scientific if there are too many digits
        }),
        int: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 0,
            notation: 'standard' // TODO: Maybe go to scientific if there are too many digits
        }),
        percentage: new Intl.NumberFormat(undefined, {
            style: 'unit',
            unit: 'percent',
            unitDisplay: 'narrow'
        })
    };
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = NumberDesc.dataPointNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = NumberDesc.tickNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
}
