import { atom } from 'jotai';
import { dashboardListAtom } from './dashboards.atoms';
import { dataSourceListAtom } from './dataSources.atoms';

export interface OnboardingState {
  isDataSourceCreated: boolean;
  isDashboardCreated: boolean;
  isDashboardWithWidgetCreated: boolean;
  isFullyOnboarded: boolean;
}

const areDataSourcesLoading = atom(get => get(dataSourceListAtom).isLoading);

const areDashboardsLoading = atom(get => get(dashboardListAtom).isLoading);

const isDataSourceCreatedAtom = atom(get => {
  if (get(areDataSourcesLoading)) return null;
  const dataSourceList = get(dataSourceListAtom);
  return Boolean(dataSourceList.data && dataSourceList.data.length > 0);
});

const isDashboardCreatedAtom = atom(get => {
  if (get(areDashboardsLoading)) return null;
  const dashboardsList = get(dashboardListAtom);
  return Boolean(dashboardsList.data && dashboardsList.data.length > 0);
});

const isDashboardWithWidgetCreatedAtom = atom(get => {
  if (get(areDashboardsLoading)) return null;
  const dashboardsList = get(dashboardListAtom);
  return Boolean(dashboardsList.data && dashboardsList.data.find(d => d.widgets.length > 0));
});

export const isFullyOnboardedAtom = atom(get => {
  if (get(areDataSourcesLoading) || get(areDashboardsLoading)) return null;
  const isDataSourceCreated = get(isDataSourceCreatedAtom);
  const isDashboardCreated = get(isDashboardCreatedAtom);
  const isDashboardWithWidgetCreated = get(isDashboardWithWidgetCreatedAtom);
  return isDataSourceCreated && isDashboardCreated && isDashboardWithWidgetCreated;
});

export const onboardingStateAtom = atom(get => {
  const isDataSourceCreated = get(isDataSourceCreatedAtom);
  const isDashboardCreated = get(isDashboardCreatedAtom);
  const isDashboardWithWidgetCreated = get(isDashboardWithWidgetCreatedAtom);
  const isFullyOnboarded = get(isFullyOnboardedAtom);
  if (
    isDataSourceCreated == null ||
    isDashboardCreated == null ||
    isDashboardWithWidgetCreated == null ||
    isFullyOnboarded == null
  ) {
    return null;
  }
  return {
    isDataSourceCreated,
    isDashboardCreated,
    isDashboardWithWidgetCreated,
    isFullyOnboarded
  };
});

export const percentCompletedAtom = atom(get => {
  const onboardingState = get(onboardingStateAtom);
  if (!onboardingState) return null;
  let completeCount = 0;
  if (onboardingState?.isDataSourceCreated) completeCount++;
  if (onboardingState?.isDashboardCreated) completeCount++;
  if (onboardingState?.isDashboardWithWidgetCreated) completeCount++;
  return completeCount / 3;
});
