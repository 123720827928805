import { useForm } from '@tanstack/react-form';
import { Link } from '@tanstack/react-router';
import { Button, TextInput } from 'axil-web-ui';
import React from 'react';

import { zodValidator } from '@tanstack/zod-form-adapter';
import { useAtomValue, useSetAtom } from 'jotai';
import { loginErrorAtom, loginWithPasswordAtom } from 'src/atoms';
import { getFieldDisplayErrors } from 'src/utils/form';
import { z } from 'zod';
import SubmitButton from '../common/forms/SubmitButton';

const schema = z.object({
  email: z.string().email({ message: 'Must be a valid email' }),
  password: z.string().min(1, 'Required')
});

export default function LoginPage() {
  const loginWithPassword = useSetAtom(loginWithPasswordAtom);
  const loginErrorMessage = useAtomValue(loginErrorAtom);
  // const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const form = useForm<z.infer<typeof schema>, ReturnType<typeof zodValidator>>({
    validatorAdapter: zodValidator(),
    validators: { onChange: schema },
    async onSubmit({ value }) {
      const { email, password } = value;
      await loginWithPassword(email, password);
    }
  });
  const isSubmitting = form.useStore(s => s.isSubmitting);
  return (
    <div className="bg-base-100 min-h-full p-8 sm:p-16">
      <h2 className="mb-8 mt-0 text-2xl">Login</h2>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <form.Field name="email">
          {field => (
            <TextInput
              label="Email"
              name={field.name}
              required
              error={getFieldDisplayErrors(field)}
              value={field.state.value ?? ''}
              onChange={e => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <form.Field name="password">
          {field => (
            <TextInput
              label="Password"
              name={field.name}
              type="password"
              required
              error={getFieldDisplayErrors(field)}
              value={field.state.value ?? ''}
              onChange={e => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        {/* <div className="flex flex-row justify-end">
          <Button link onClick={() => setIsForgotPasswordDialogOpen(true)}>
            Forgot Password?
          </Button>
        </div> */}
        <div className="my-2 flex flex-row justify-between gap-4">
          <SubmitButton form={form} label="Log In" />
          {/* <Link to="/signup">
            <Button link disabled={isSubmitting}>
              Sign up
            </Button>
          </Link> */}
        </div>
        {loginErrorMessage ? (
          <div className="my-2 flex flex-row">
            <span className="text-error font-bold">{loginErrorMessage}</span>
          </div>
        ) : null}
      </form>
      {/* <Dialog
        isOpen={isForgotPasswordDialogOpen}
        onClose={() => setIsForgotPasswordDialogOpen(false)}>
        <ForgotPassword />
      </Dialog> */}
    </div>
  );
}
