import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { isNotEmpty } from 'axil-utils';
import axios from 'axios';
import { isString } from 'lodash';
import { useContext } from 'react';
import log from 'src/log';
import { AccessCredential } from 'src/types/service';
import { TopLevelThemeContext } from '../context';

export * from './dataFetch';
export * from './routing';
export * from './service/admin';
export * from './service/common';
export * from './service/dashboards';
export * from './service/dataSources';
export * from './storage';
export * from './use-undo';

export class PopupWindowWaitAccessError extends Error {}

export function useTopLevelTheme() {
  return useContext(TopLevelThemeContext).theme;
}

const fiveMin = 5 * 60 * 1000;

/** NOTE: In dev mode, the window shows as closed right away even though its open, most likely because of https stuff */
const waitForAccessCredential = async (
  win: Window,
  connectorId: string,
  queryClient: QueryClient,
  maxWait = fiveMin
): Promise<string | null> => {
  const start = Date.now();
  const cleanups: (() => void)[] = [];
  const result = await Promise.race<string | null>(
    [
      new Promise<string | null>(resolve => {
        const interval = setInterval(async () => {
          const credentials = await queryClient.fetchQuery<AccessCredential[]>({
            queryKey: ['/access-credential']
          });
          const newAccessCredential = credentials.find(
            cred =>
              (cred.connectorId === connectorId &&
                Number(new Date(cred.createdAt)) > Number(start)) ||
              Number(new Date(cred.updatedAt)) > Number(start)
          );
          if (newAccessCredential) {
            resolve(newAccessCredential.id);
          }
        }, 5000);
        cleanups.push(() => clearInterval(interval));
      }),
      new Promise<string | null>(resolve => {
        const handleMessage = (evt: MessageEvent) => {
          const msg = evt.data;
          if (msg && msg.type === 'accessCredentialCreated') {
            resolve(isString(msg.accessCredentialId) ? (msg.accessCredentialId as string) : null);
          }
        };
        win.addEventListener('message', handleMessage);
        cleanups.push(() => {
          try {
            win.removeEventListener('message', handleMessage);
          } catch (err) {
            log.warn(err);
          }
        });
      })
      // // Only do the window interval check if we can actually connect to the window
      // win && window.location.protocol === 'https:'
      //   ? new Promise<null>(resolve => {
      //       const interval = setInterval(() => {
      //         if (!win || win.closed || Date.now() - start > maxWait) {
      //           clearInterval(interval);
      //           resolve(null);
      //         }
      //       }, 1000);
      //       cleanups.push(() => clearInterval(interval));
      //     })
      //   : null
    ].filter(isNotEmpty)
  );
  cleanups.forEach(c => c());
  return result;
};

const openConnectWindow = (url: string, windowTitle: string) => {
  const width = Math.min(600, window.innerWidth);
  const height = Math.min(600, window.innerHeight);
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const left = (window.screen.width - width) / 2 + dualScreenLeft;
  const top = (window.screen.height - height) / 2 + dualScreenTop;
  const features: (string | [string, string | number])[] = [
    'resizable',
    'scrollbars',
    ['width', width],
    ['innerWidth', width],
    ['height', height],
    ['innerHeight', height],
    ['left', left],
    ['top', top]
  ];
  return window.open(
    url,
    windowTitle,
    features.map(f => (Array.isArray(f) ? f.join('=') : f)).join(', ')
  );
};

export const useCreateAccessCredential = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (connectorId: string) => {
      const resp = await axios.post<{ type: 'success' } | { type: 'redirect'; url: string }>(
        `/access-credential/connect/${connectorId}`
      );
      if (resp.data.type !== 'redirect') throw new Error('only redirect working right now');
      const popupWin = openConnectWindow(resp.data.url, `DayDash Access Credential`);
      if (!popupWin) throw Error('Could not connect');
      const newAccessCredentialId = await waitForAccessCredential(
        popupWin,
        connectorId,
        queryClient
      );
      if (!newAccessCredentialId) throw new PopupWindowWaitAccessError('Could not connect');
      return newAccessCredentialId;
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['/access-credential']
      });
    }
  });
};

export const useReconnectAccessCredential = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (accessCredential: AccessCredential) => {
      const resp = await axios.post<{ type: 'success' } | { type: 'redirect'; url: string }>(
        `/access-credential/reconnect/${accessCredential.id}`
      );
      if (resp.data.type !== 'redirect') throw new Error('only redirect working right now');
      const popupWin = openConnectWindow(resp.data.url, `Reconnecting...`);
      if (!popupWin) throw new PopupWindowWaitAccessError('Could not reconnect');
      await waitForAccessCredential(popupWin, accessCredential.connectorId, queryClient);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['/access-credential']
      });
    }
  });
};
