import { FormApi, useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Loading } from 'axil-web-ui';
import { DataSourceBuilder } from 'daydash-data-structures';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import {
  currentDataSourceFormStepAtom,
  currentDataSourceFormStepIdAtom,
  dataSourceFormAtom,
  dataSourceFormSchema,
  dataSourceFormStepsAtom,
  DataSourceFormValues,
  finishCreateDataSourceAtom,
  resetDataSourceFormAtom,
  submitCurrentBuilderAtom
} from 'src/atoms/dataSourceForm.atoms';
import Wizard, { WizardStep } from '../common/Wizard';
import BuildStep from './BuildStep';
import PickDataSourceStep from './PickDataSourceStep';
import { DataSourceFormContext } from './context';

const Success = ({ step }: { step: DataSourceBuilder.ConnectorStep }) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <h2 className="text-center text-xl">{step.label}</h2>
      <p>You just created a Data Source!</p>
    </div>
  );
};

function useSyncForm(form: FormApi<DataSourceFormValues, ReturnType<typeof zodValidator>>) {
  const setForm = useSetAtom(dataSourceFormAtom);
  useEffect(() => setForm(form), [form, setForm]);
  const resetDataSourceForm = useSetAtom(resetDataSourceFormAtom);
  useEffect(() => () => resetDataSourceForm(), []);
}

function CreateDataSource({
  onSuccess,
  initialConnector
}: {
  onSuccess: () => void;
  initialConnector?: string | null;
}) {
  const currentStep = useAtomValue(currentDataSourceFormStepAtom);
  if (!currentStep) throw new Error('Invalid step found!');
  if (currentStep.pending) throw new Error('Cannot navigate to a pending step!');
  const submitCurrentBuilder = useSetAtom(submitCurrentBuilderAtom);
  const form = useForm<DataSourceFormValues, ReturnType<typeof zodValidator>>({
    validatorAdapter: zodValidator(),
    validators: { onChange: dataSourceFormSchema },
    onSubmit: () => submitCurrentBuilder()
  });
  useSyncForm(form);
  const canSubmit = form.useStore(s => s.canSubmit);
  const currentStepId = currentStep.id;
  // If there was an initial connector id, just go ahead and auto submit on load
  // TODO: Move this to Jotai
  useEffect(() => {
    if (initialConnector && currentStepId === 'pick-data-source') {
      submitCurrentBuilder();
    }
  }, []);
  const setCurrentStepId = useSetAtom(currentDataSourceFormStepIdAtom);
  const finishCreateDataSource = useSetAtom(finishCreateDataSourceAtom);
  const steps = useAtomValue(dataSourceFormStepsAtom);
  const wizardSteps = steps.map<WizardStep>(step => {
    if (step.pending) return { id: step.id, content: <Loading /> }; // Should not get here
    if (currentStep.id === 'success') {
      return {
        id: step.id,
        hidePreviousButton: true,
        nextButtonText: 'Finish',
        content: <Success step={step} />,
        onNext: async () => {
          finishCreateDataSource();
          onSuccess();
          return true;
        }
      };
    }
    return {
      id: step.id,
      previousButtonText: step.previousButtonText,
      nextButtonText: step.nextButtonText,
      disabledNext: !canSubmit,
      content: step.id === 'pick-data-source' ? <PickDataSourceStep /> : <BuildStep step={step} />,
      onNext: async () => {
        if (!currentStep) throw new Error('Current step not found!');
        if (currentStep.pending) throw new Error('Cannot submit a pending step!');
        if (currentStep.requiresSubmission) {
          await form.handleSubmit();
          return true;
        }
        // Let the progression behavior happen normally if not requiring submission
      }
    };
  });

  return (
    <DataSourceFormContext.Provider value={form}>
      <Wizard steps={wizardSteps} currentStepId={currentStepId} onStepChange={setCurrentStepId} />
    </DataSourceFormContext.Provider>
  );
}

export default CreateDataSource;
