import { Widget } from 'daydash-data-structures';
import { Layout } from 'react-grid-layout';
import widgetConfigs from './WidgetTypes/config';

export function getWidgetGridPosition(
  widget: Widget,
  currentBottomY: number,
  colCount: number,
  forceBottomY = false
): Layout {
  const widgetCfg = widgetConfigs[widget.type];
  return {
    i: widget.id,
    x: widget.layout?.x ?? 0,
    y: forceBottomY ? currentBottomY : widget.layout?.y ?? currentBottomY,
    w: widget.layout?.w ?? Math.max(widgetCfg.defaults.width ?? 2, colCount),
    h: widget.layout?.h ?? widgetCfg.defaults.height ?? 2
  };
}
