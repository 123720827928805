import { useAtom } from 'jotai';
import React from 'react';
import { promptStateAtom } from 'src/atoms';
import Prompt from './common/Prompt';

export default function GlobalPrompt() {
  const [promptState] = useAtom(promptStateAtom);
  return (
    <Prompt
      isOpen={promptState.prompting}
      promptText={(promptState.prompting && promptState.text) || ''}
      onCancel={() => promptState.prompting && promptState.confirm(false)}
      onConfirm={() => promptState.prompting && promptState.confirm(true)}
    />
  );
}
