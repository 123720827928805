import { BaseFieldTypeDesc } from './common';
export default class VelocityDesc extends BaseFieldTypeDesc {
    typeLabel = 'Velocity';
    dataLabel = 'Velocity';
    unitLabels = {
        mph: 'Miles Per Hour',
        kph: 'Kilometers Per Hour'
    };
    unitSymbols = {
        mph: 'mph',
        kph: 'km/h'
    };
    // NOTE: mph and kph are not part of the standard unit options, so we need to define our own number formats
    static dataPointNumberFormat = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2
    });
    static tickNumberFormat = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2
    });
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = VelocityDesc.dataPointNumberFormat;
        return (numberFormat.format(dataPoint) +
            ' ' +
            this.unitSymbols[field.unit]);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = VelocityDesc.tickNumberFormat;
        return (numberFormat.format(dataPoint) + this.unitSymbols[field.unit]);
    };
}
