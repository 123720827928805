import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export default createSyncStoragePersister({
  storage: window.localStorage
});

// Old persister. It had some issues with IndexedDb. Using local storage instead for now

// import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
// import { clear, createStore, entries, setMany } from 'idb-keyval';
// function createIDBPersister() {
//   const store = createStore('persistedQueries', 'queries');
//   return {
//     persistClient: async (client: PersistedClient) => {
//       await setMany(
//         [
//           ['timestamp', client.timestamp],
//           ['buster', client.buster],
//           ...client.clientState.queries.map<[IDBValidKey, any]>(query => {
//             const serializedQuery = JSON.parse(JSON.stringify(query));
//             return [query.queryHash, serializedQuery];
//           })
//         ],
//         store
//       );
//     },
//     restoreClient: async () => {
//       return (await entries(store)).reduce<PersistedClient>(
//         (restoredClientState, [key, val]) => {
//           if (key === 'timestamp') restoredClientState.timestamp = val;
//           else if (key === 'buster') restoredClientState.buster = val;
//           else {
//             restoredClientState.clientState.queries.push(val);
//           }
//           return restoredClientState;
//         },
//         {
//           buster: '',
//           timestamp: 0,
//           clientState: {
//             mutations: [],
//             queries: []
//           }
//         }
//       );
//     },
//     removeClient: async () => {
//       await clear(store);
//     }
//   } satisfies Persister;
// }

// export default createIDBPersister();
