import { assertNever, isNotEmpty } from 'axil-utils';
import { TextInput, cn } from 'axil-web-ui';
import { Units } from 'daydash-data-structures';
import React, { useMemo } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

type DurationInputProps = Omit<React.ComponentProps<typeof TextInput>, 'type' | 'label'> & {
  value: number;
  unit: Units.Duration;
  onChange: (value: number | null) => void;
  className?: string;
};

type DurationField = 'years' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';

const DurationInput: React.FC<DurationInputProps> = ({
  value,
  unit,
  onChange,
  className,
  ...rest
}) => {
  const unitInputConfig = useMemo(() => {
    return (
      [
        {
          name: 'years',
          label: 'Years',
          amount: 365 * 24 * 60 * 60 * 1000
        },
        {
          name: 'weeks',
          label: 'Weeks',
          amount: 7 * 24 * 60 * 60 * 1000
        },
        {
          name: 'days',
          label: 'Days',
          amount: 24 * 60 * 60 * 1000
        },
        {
          name: 'hours',
          label: 'Hours',
          amount: 60 * 60 * 1000
        },
        {
          name: 'minutes',
          label: 'Minutes',
          amount: 60 * 1000
        },
        unit === Units.Duration.ms || unit === Units.Duration.sec
          ? {
              name: 'seconds',
              label: 'Seconds',
              amount: 1000
            }
          : null,
        unit === Units.Duration.ms
          ? {
              name: 'milliseconds',
              label: 'Milliseconds',
              amount: 1
            }
          : null
      ] satisfies ({ name: DurationField; label: string; amount: number } | null)[]
    ).filter(isNotEmpty);
  }, [unit]);

  const parsed = useMemo(() => {
    // First, normalize the unit to milliseconds
    let normalized: number;
    if (unit === Units.Duration.ms) normalized = value;
    else if (unit === Units.Duration.sec) normalized = value * 1000;
    else if (unit === Units.Duration.min) normalized = value * 60 * 1000;
    else assertNever(unit);
    return unitInputConfig.reduce<{ remaining: number; parsing: Record<DurationField, number> }>(
      ({ remaining, parsing }, { name, amount }) => {
        parsing[name] = Math.floor(remaining / amount);
        remaining -= parsing[name] * amount;
        return { remaining, parsing };
      },
      {
        remaining: normalized,
        parsing: { years: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }
      }
    );
  }, [value]);

  const handleChange = (values: NumberFormatValues, name: string) => {
    const updated = { ...parsed.parsing, [name]: values.floatValue ?? 0 };
    const newDuration = unitInputConfig.reduce((acc, { name, amount }) => {
      const value = updated[name];
      if (value) acc += value * amount;
      return acc;
    }, 0);
    // Now, map it back to the right format
    let mapped: number;
    if (unit === Units.Duration.ms) mapped = newDuration;
    else if (unit === Units.Duration.sec) mapped = Math.round(newDuration / 1000);
    else if (unit === Units.Duration.min) mapped = Math.round(newDuration / (60 * 1000));
    else assertNever(unit);
    onChange(mapped);
  };

  return (
    <div className={cn('flex gap-2', className)}>
      {unitInputConfig.map(({ name, label }) => (
        <NumericFormat
          key={name}
          value={parsed.parsing[name]}
          displayType="input"
          decimalScale={0}
          customInput={TextInput}
          {...rest}
          label={label}
          name={`${rest.name}.${name}`}
          valueIsNumericString
          allowNegative={false}
          className="w-16"
          onValueChange={values => handleChange(values, name)}
        />
      ))}
    </div>
  );
};

export default DurationInput;
