import React from 'react';

interface NotFoundProps {}

function NotFoundPage(props: NotFoundProps) {
  return (
    <div>
      <h1>Page not found!</h1>
    </div>
  );
}

export default NotFoundPage;
