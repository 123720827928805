import React from 'react';
import { useForm } from '@tanstack/react-form';
import { useNavigate } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Button, TextInput } from 'axil-web-ui';
import { z } from 'zod';
import SubmitButton from '../common/forms/SubmitButton';
import { v4 as uuid } from 'uuid';
import { Dashboard } from 'src/types/entities';
import { useCreateDashboard, useUpdateDashboard } from 'src/hooks';
import { getFieldDisplayErrors } from 'src/utils/form';

interface DashboardFormProps {
  update?: boolean;
  initialValues?: Dashboard;
  onSuccess?: (dashboard: Dashboard) => void;
  onCancel?: () => void;
}
const schema = z.object({
  name: z.string().min(1, 'Required'),
  widgets: z.array(z.any()).nullable().default([]),
  sortWeight: z.string().nullish()
});

function DashboardForm({
  onSuccess,
  onCancel,
  initialValues,
  update: isUpdate
}: DashboardFormProps) {
  const navigate = useNavigate();
  const createMutation = useCreateDashboard();
  const updateMutation = useUpdateDashboard();
  const form = useForm<z.infer<typeof schema>, ReturnType<typeof zodValidator>>({
    defaultValues: initialValues,
    validatorAdapter: zodValidator(),
    validators: { onChange: schema },
    async onSubmit({ value }) {
      const mutation = isUpdate ? updateMutation : createMutation;
      const id = initialValues?.id ?? uuid();
      const { data: newDashboard } = await mutation.mutateAsync({
        ...value,
        widgets: value.widgets ?? [],
        sortWeight: value.sortWeight ?? null,
        id
      });
      if (!newDashboard) throw new Error('Failed to create dashboard');
      if (onSuccess) onSuccess(newDashboard);
      // Navigate to new dashboard on create
      if (!isUpdate) await navigate({ to: '/dash/' + id });
    }
  });
  return (
    <div className="p-8">
      <h3 className="mb-4 text-2xl">
        {isUpdate ? `Update ${initialValues?.name || 'Dashboard'}` : 'Create a Dashboard'}
      </h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <form.Field name="name">
          {field => (
            <TextInput
              label="Name"
              name={field.name}
              required
              error={getFieldDisplayErrors(field)}
              value={field.state.value ?? ''}
              onChange={e => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <div className="flex flex-row justify-between gap-4">
          <SubmitButton form={form} label={isUpdate ? 'Update' : 'Submit'} />
          {onCancel ? (
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          ) : null}
        </div>
        {/* {formik.status?.type === 'SubmissionError' ? (
          <div className="flex flex-row">
            <span className="text-status-error font-bold">{formik.status?.message}</span>
          </div>
        ) : null} */}
      </form>
    </div>
  );
}

export default DashboardForm;
