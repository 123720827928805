import React from 'react';
import { useParams } from '@tanstack/react-router';
import DashboardComponent from '../Dashboard/Dashboard';
import PageLoader from '../common/loading/PageLoader';
import { useDashboard } from 'src/hooks';

export interface DashboardPageProps {
  fullScreen?: boolean;
  editing?: boolean;
}

export default function DashboardPage({ fullScreen, editing }: DashboardPageProps) {
  const looseParams = useParams({ strict: false });
  const dashboardId = looseParams.id;
  if (!dashboardId) throw new Error('Dashboard Id not found!');
  const { data: dashboard } = useDashboard(dashboardId);
  if (dashboard === undefined || (dashboard && dashboard.id !== dashboardId)) return <PageLoader />;
  if (!dashboard) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h3>Dashboard not found. It may have been deleted</h3>
      </div>
    );
  }
  return (
    <DashboardComponent
      key={dashboard.id}
      dashboard={dashboard}
      fullScreen={fullScreen}
      editing={editing}
    />
  );
}
