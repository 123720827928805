import { BaseFieldTypeDesc, getDateFormatter } from './common';
export default class WeekDesc extends BaseFieldTypeDesc {
    typeLabel = 'Week';
    dataLabel = 'Week';
    unitLabels = {
        epochMS: 'Epoch Milliseconds'
    };
    defaultScale = 'time';
    tickFormatter = getDateFormatter("'W'w yyyy");
    dataPointFormatter = getDateFormatter("'Week 'w yyyy (MMM d)");
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
