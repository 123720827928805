import { createContext, useContext } from 'react';
import { FormHookApi } from '../common/forms/types';
import { DataSourceFormValues } from 'src/atoms/dataSourceForm.atoms';

type DataSourceFormApi = FormHookApi<DataSourceFormValues>;

export type DataSourceFormPath = Parameters<DataSourceFormApi['useField']>[0]['name'];

export const DataSourceFormContext = createContext<DataSourceFormApi | null>(null);

export const useDataSourceForm = () => {
  const form = useContext(DataSourceFormContext);
  if (!form) throw new Error('useWidgetForm must be used within a WidgetFormProvider');
  return form;
};
