import { Button, Loading } from 'axil-web-ui';
import React from 'react';
import { FormHookApi } from './types';

export interface SubmitButtonProps {
  form: FormHookApi<any>;
  label: string;
}

export default function SubmitButton({ form, label }: SubmitButtonProps) {
  // Allow the first submit attempt to go through even if the form is invalid so the user can see the errors
  const submitEnabled = form.useStore(s => s.submissionAttempts === 0 || s.canSubmit);
  const isSubmitting = form.useStore(s => s.isSubmitting);
  return (
    <Button color="primary" type="submit" disabled={!submitEnabled || isSubmitting}>
      {isSubmitting ? <Loading /> : label}
    </Button>
  );
}
