import { Button, Dialog } from 'axil-web-ui';

import React from 'react';

interface PromptProps {
  promptText: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen?: boolean;
}

function Prompt({ promptText, onConfirm, onCancel, isOpen = true }: PromptProps) {
  return (
    <Dialog
      isOpen={isOpen}
      zIndex={1400}
      modal
      contentClassName="w-80 max-w-full"
      actions={[
        <Button color="primary" key="yes" onClick={onConfirm}>
          Yes
        </Button>,
        <Button color="secondary" key="no" onClick={onCancel}>
          No
        </Button>
      ]}>
      <div className="px-4 md:p-8">
        <p className="text-center text-base md:text-lg">{promptText}</p>
      </div>
    </Dialog>
  );
}

export default Prompt;
