import { Button, TextInput } from 'axil-web-ui';
import React, { useEffect } from 'react';

import { useForm } from '@tanstack/react-form';

import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import SubmitButton from '../common/forms/SubmitButton';
import { getFieldDisplayErrors } from 'src/utils/form';

const schema = z.object({
  name: z.string().min(1, 'Required')
});

interface EditDataSourceNameProps {
  initialName?: string;
  dataSourceId: string;
  onSave: (values: { name: string }) => Promise<any> | any;
  onCancel?: () => void;
}

function EditDataSourceName({
  initialName,
  onSave,
  onCancel,
  dataSourceId
}: EditDataSourceNameProps) {
  const form = useForm<z.infer<typeof schema>, ReturnType<typeof zodValidator>>({
    defaultValues: { name: initialName ?? '' },
    validatorAdapter: zodValidator(),
    validators: { onChange: schema },
    onSubmit: ({ value }) => onSave(schema.parse(value))
  });
  useEffect(() => {
    form.reset();
  }, [dataSourceId]);
  return (
    <div className="p-16">
      <h3 className="mb-4 text-2xl">Edit DataSource Name</h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <form.Field name="name">
          {field => (
            <TextInput
              label="Name"
              name={field.name}
              required
              error={getFieldDisplayErrors(field)}
              value={field.state.value ?? ''}
              onChange={e => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <div className="flex flex-row justify-between gap-4">
          <SubmitButton form={form} label="Update" />
          {onCancel ? (
            <Button color="accent" onClick={onCancel}>
              Cancel
            </Button>
          ) : null}
        </div>
        {/* {formik.status?.type === 'SubmissionError' ? (
          <div className="flex flex-row">
            <span className="text-status-error font-bold">{formik.status?.message}</span>
          </div>
        ) : null} */}
      </form>
    </div>
  );
}

export default EditDataSourceName;
