import { cn } from 'axil-web-ui';
import React from 'react';

interface StandardTitleProps {
  level?: '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6;
  title?: string | undefined;
  className?: string;
}

function StandardTitle({ title, className, level = 3 }: StandardTitleProps) {
  const Heading = `h${level}` as keyof JSX.IntrinsicElements;
  // Extra padding room for the drag handle and the Widget overflow
  return title ? (
    <Heading className={cn('standardTitle grow px-4 text-xl font-bold', className)}>
      {title}
    </Heading>
  ) : null;
}

export default StandardTitle;
