import { isNotEmpty } from 'axil-utils';
import { atom } from 'jotai';
import { _storeAtom, devToolsAtom, routingAtom, setDevToolsAtom } from '.';
import { dataSourceCommandsAtom } from './dataSources.atoms';
import { updateDialogAtom } from './dialogs.atoms';
import {
  canExpandSidebarAtom,
  closeSidebarAtom,
  collapseSidebarAtom,
  expandSidebarAtom,
  openSidebarAtom,
  sidebarAtom
} from './sidebar.atoms';
import { dashboardCommandsAtom } from './dashboards.atoms';
import { widgetCommandsAtom } from './widgets.atoms';

export type Command = {
  label: string;
  icon?: React.ReactNode;
  keywords?: string[];
  id: string;
  handler: () => void;
};

export type CommandGroup = {
  label: string;
  id: string;
  commands: Command[];
};

const sidebarCommandsAtom = atom<Command[]>(get => {
  const store = get(_storeAtom);
  const commands: Command[] = [];
  if (!store) return [];
  const sidebarState = get(sidebarAtom);
  const canExpandSidebar = get(canExpandSidebarAtom);
  if (canExpandSidebar && !sidebarState.expanded)
    commands.push({
      label: 'Expand Sidebar',
      id: 'expand-sidebar',
      handler: () => store.set(expandSidebarAtom)
    });
  if (canExpandSidebar && sidebarState.expanded)
    commands.push({
      label: 'Collapse Sidebar',
      id: 'collapse-sidebar',
      handler: () => store.set(collapseSidebarAtom)
    });
  if (!sidebarState.expanded && !sidebarState.open)
    commands.push({
      label: 'Open Sidebar',
      id: 'open-sidebar',
      handler: () => store.set(openSidebarAtom)
    });
  if (!sidebarState.expanded && sidebarState.open)
    commands.push({
      label: 'Close Sidebar',
      id: 'close-sidebar',
      handler: () => store.set(closeSidebarAtom)
    });
  return commands;
});

const devToolsCommandsAtom = atom<CommandGroup | null>(get => {
  if (import.meta.env.MODE !== 'development') return null;

  const store = get(_storeAtom);
  if (!store) return null;
  const devToolsState = get(devToolsAtom);
  return {
    label: 'Dev Tools',
    id: 'dev-tools',
    commands: [
      {
        label: !devToolsState.jotai ? 'Show Jotai Dev Tools' : 'Hide Jotai Dev Tools',
        id: 'toggle-jotai',
        handler: () => store.set(setDevToolsAtom, 'jotai', !devToolsState.jotai)
      },
      {
        label: !devToolsState.query ? 'Show Query Dev Tools' : 'Hide Query Dev Tools',
        id: 'toggle-query',
        handler: () => store.set(setDevToolsAtom, 'query', !devToolsState.query)
      }
    ]
  };
});

export const commandsAtom = atom<CommandGroup[]>(get => {
  const store = get(_storeAtom);
  if (!store) return [];
  const router = get(routingAtom);
  const commandGroups: CommandGroup[] = [];
  // General
  commandGroups.push({
    label: 'General',
    id: 'general',
    commands: [
      {
        label: 'Create Dashboard',
        id: 'create-dashboard',
        handler: () => store.set(updateDialogAtom, { type: 'CreateDashboard', payload: null })
      },
      {
        label: 'Create Data Source',
        id: 'create-dataSource',
        handler: () => store.set(updateDialogAtom, { type: 'CreateDataSource', payload: null })
      },
      {
        label: 'Go back',
        id: 'go-back',
        handler: () => router?.history.back()
      },
      {
        label: 'Go forward',
        id: 'go-forward',
        handler: () => router?.history.forward()
      },
      ...get(sidebarCommandsAtom)
    ].filter(isNotEmpty)
  });

  const dashboardCommandGroup = get(dashboardCommandsAtom);
  if (dashboardCommandGroup) commandGroups.push(dashboardCommandGroup);
  // Widget
  const widgetCommandGroup = get(widgetCommandsAtom);
  if (widgetCommandGroup) commandGroups.push(widgetCommandGroup);
  // Datasource
  const dataSourceCommandGroup = get(dataSourceCommandsAtom);
  if (dataSourceCommandGroup) commandGroups.push(dataSourceCommandGroup);
  // Account
  commandGroups.push({
    label: 'Account',
    id: 'account',
    commands: [
      {
        label: 'Settings',
        id: 'settings',
        handler: () => store.set(updateDialogAtom, { type: 'AccountPreferences', payload: null })
      }
    ]
  });
  // Dev Tools
  const devToolsCommandGroup = get(devToolsCommandsAtom);
  if (devToolsCommandGroup) commandGroups.push(devToolsCommandGroup);

  return commandGroups;
});
