import { assertNever } from 'axil-utils';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Units } from 'daydash-data-structures';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import memoize from 'lodash/memoize';
import { getMillisecondsForField } from '../utils/date';
import { BaseFieldTypeDesc } from './common';
const durationSecondsMap = {
    years: 365 * 30 * 7 * 24 * 60 * 60 * 1,
    months: 30 * 7 * 24 * 60 * 60 * 1,
    weeks: 7 * 24 * 60 * 60 * 1,
    days: 24 * 60 * 60 * 1,
    hours: 60 * 60 * 1,
    minutes: 60 * 1,
    seconds: 1
};
// Stolen a little bit from https://github.com/tolu/ISO8601-duration/blob/master/src/index.js
const durationToSeconds = (duration) => {
    return Object.entries(duration).reduce((seconds, [key, val]) => {
        return seconds + durationSecondsMap[key] * val;
    }, 0);
};
const niceTickIntervals = (() => {
    return [
        ['seconds', 1],
        ['seconds', 10],
        ['seconds', 15],
        ['seconds', 30],
        ['minutes', 1],
        ['minutes', 10],
        ['minutes', 15],
        ['minutes', 30],
        ['hours', 1],
        ['hours', 3],
        ['hours', 6],
        ['hours', 12],
        ['days', 1],
        ['days', 2],
        ['days', 3],
        ['weeks', 1],
        ['weeks', 2],
        ['months', 1],
        ['months', 2],
        ['months', 3],
        ['years', 1],
        ['years', 2],
        ['years', 3],
        ['years', 4],
        ['years', 5],
        ['years', 8],
        ['years', 10],
        ['years', 50],
        ['years', 100],
        ['years', 500],
        ['years', 1000]
    ].map(([unit, interval]) => durationToSeconds({ [unit]: interval }));
})();
const getClosestNiceDuration = memoize((differential) => {
    return niceTickIntervals.reduce((closest, current) => {
        return Math.abs(differential - current) < Math.abs(differential - closest) ? current : closest;
    }, 0);
});
export default class DurationDesc extends BaseFieldTypeDesc {
    typeLabel = 'Duration';
    dataLabel = 'Duration';
    unitLabels = {
        sec: 'Seconds',
        ms: 'Milliseconds',
        min: 'Minutes'
    };
    static durationFromField = (dataPoint, field) => {
        if (field.type !== 'duration') {
            throw new Error('Not a duration field!');
        }
        const val = isPlainObject(dataPoint) ? get(dataPoint, field.key) : dataPoint;
        const duration = intervalToDuration({
            start: 0,
            end: getMillisecondsForField(Number(val), field)
        });
        return duration;
    };
    getNicerTickValues = (initialTicks, fields) => {
        // Whoa, the differential is itself a duration if you think about it
        // Normalize to seconds
        let convertedTicks;
        const unit = fields[0].unit;
        if (unit === Units.Duration.ms)
            convertedTicks = initialTicks.map(tick => tick / 1000);
        else if (unit === Units.Duration.min)
            convertedTicks = initialTicks.map(tick => tick * 60);
        else if (unit === Units.Duration.sec)
            convertedTicks = initialTicks;
        else
            assertNever(unit);
        const highestDifferential = convertedTicks.reduce((maxDiff, tickVal, idx) => {
            if (idx === 0)
                return maxDiff;
            const prev = convertedTicks[idx - 1];
            const diff = tickVal - prev;
            return diff > maxDiff ? diff : maxDiff;
        }, 0);
        const niceDuration = getClosestNiceDuration(highestDifferential);
        const minInitialTick = convertedTicks[0];
        const maxInitialTick = convertedTicks[convertedTicks.length - 1];
        // 0 is the starting point needed to find the right multiplier
        const from = Math.round(minInitialTick / niceDuration);
        const to = Math.round(maxInitialTick / niceDuration);
        const updatedTicks = [];
        for (let i = from; i <= to; i++) {
            let val;
            // Convert back to the right unit
            if (unit === Units.Duration.sec)
                val = niceDuration * i;
            else if (unit === Units.Duration.ms)
                val = niceDuration * i * 1000;
            else if (unit === Units.Duration.min)
                val = (niceDuration * i) / 60;
            else
                assertNever(unit);
            updatedTicks.push(val);
        }
        return updatedTicks;
    };
    static baseDurationFormatOptions = {
        maximumFractionDigits: 0,
        style: 'unit',
        unitDisplay: 'narrow'
    };
    static tickDurationFormats = {
        years: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'year'
        }),
        months: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'month'
        }),
        weeks: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'week'
        }),
        days: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'day'
        }),
        hours: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'hour'
        }),
        minutes: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'minute'
        }),
        seconds: new Intl.NumberFormat(undefined, {
            ...DurationDesc.baseDurationFormatOptions,
            unit: 'second'
        })
    };
    tickFormatter = (field, dataPoint) => {
        const duration = DurationDesc.durationFromField(dataPoint, field);
        return Object.entries(duration)
            .map(([key, val]) => val ? DurationDesc.tickDurationFormats[key].format(val) : null)
            .filter(Boolean)
            .join(' ');
    };
    dataPointFormatter = (field, dataPoint) => {
        const duration = DurationDesc.durationFromField(dataPoint, field);
        return formatDuration(duration);
    };
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
