import { BaseFieldTypeDesc } from './common';
export default class EnergyDesc extends BaseFieldTypeDesc {
    typeLabel = 'Energy';
    dataLabel = 'Energy';
    unitLabels = {
        kcal: 'Calorie',
        kJ: 'Kilojoule'
    };
    unitSymbols = {
        kJ: 'kJ',
        kcal: 'C'
    };
    // NOTE: Kilojoules and K Cals are not part of the standard Intl NumberFormat params
    static dataPointNumberFormat = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2
    });
    static tickNumberFormat = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2
    });
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = EnergyDesc.dataPointNumberFormat;
        return (numberFormat.format(dataPoint) + ' ' + this.unitSymbols[field.unit]);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = EnergyDesc.tickNumberFormat;
        return numberFormat.format(dataPoint) + this.unitSymbols[field.unit];
    };
}
