import { useRouterState } from '@tanstack/react-router';
import { Progress } from 'axil-web-ui';
import React from 'react';
import { useDebounce } from 'use-debounce';

export default function PageLoadingIndicator() {
  const navigating = useRouterState({ select: state => state.isLoading });
  const [debouncedNavigating] = useDebounce(navigating, 100, { trailing: true, leading: false }); // Give a little bit of time so we don't annoy the user
  // Check both conditions so it goes away immediately after navigation is done
  if (navigating && debouncedNavigating)
    return (
      <Progress
        className="fixed left-0 right-0 top-0 z-20 h-1 rounded-none"
        color="primary"
        data-state="indeterminate"
      />
    );
  return null;
}
