import CategoryDesc from './descs/category';
import CurrencyDesc from './descs/currency';
import DateDesc from './descs/date';
import DateTimeDesc from './descs/dateTime';
import DistanceDesc from './descs/distance';
import DurationDesc from './descs/duration';
import EnergyDesc from './descs/energy';
import LiquidDesc from './descs/liquid';
import MassDesc from './descs/mass';
import NumberDesc from './descs/number';
import TemperatureDesc from './descs/temperature';
import HourDesc from './descs/hour';
import WeekDesc from './descs/week';
import MonthDesc from './descs/month';
import YearDesc from './descs/year';
import TimeOfDayDesc from './descs/timeOfDay';
import HourOfDayDesc from './descs/hourOfDay';
import DayOfWeekDesc from './descs/dayOfWeek';
import MonthOfYearDesc from './descs/monthOfYear';
import VelocityDesc from './descs/velocity';
export const fieldTypeToDescs = {
    number: new NumberDesc(),
    dateTime: new DateTimeDesc(),
    date: new DateDesc(),
    hour: new HourDesc(),
    week: new WeekDesc(),
    month: new MonthDesc(),
    year: new YearDesc(),
    timeOfDay: new TimeOfDayDesc(),
    hourOfDay: new HourOfDayDesc(),
    dayOfWeek: new DayOfWeekDesc(),
    monthOfYear: new MonthOfYearDesc(),
    duration: new DurationDesc(),
    temperature: new TemperatureDesc(),
    energy: new EnergyDesc(),
    liquid: new LiquidDesc(),
    distance: new DistanceDesc(),
    velocity: new VelocityDesc(),
    mass: new MassDesc(),
    currency: new CurrencyDesc(),
    // Nonnumeric
    category: new CategoryDesc()
};
/**
 * And now, the convenience functions
 */
// You have to explicitly set aggType to null when formatting data points. That way, we can catch count issues
export const formatDataPoint = (field, dataPoint) => {
    if (dataPoint == null || Number.isNaN(dataPoint))
        return '';
    return fieldTypeToDescs[field.type].dataPointFormatter(field, dataPoint);
};
export const getAxisLabel = (field, combined) => {
    return fieldTypeToDescs[field.type].getAxisLabel(field, combined);
};
export const getTooltipFormatter = (xField, yField) => {
    // A little weird shaping it like this but it's to keep it consistent with getTooltipFormatter over time
    return (point) => {
        const { datum } = point;
        return [
            formatDataPoint(xField, datum[xField.key]),
            formatDataPoint(yField, datum[yField.key])
        ].join('\n');
    };
};
export const getTickFormatter = (field) => {
    if (!field)
        return (p) => p ?? '';
    const fieldDesc = fieldTypeToDescs[field.type];
    return (dataPoint) => {
        return dataPoint != null && !Number.isNaN(dataPoint)
            ? fieldDesc.tickFormatter(field, dataPoint)
            : '';
    };
};
export const getNicerTickValues = (initialTicks, fields) => {
    // These should all be the same type of field
    const fieldDesc = fieldTypeToDescs[fields[0].type];
    if (fieldDesc.getNicerTickValues) {
        return fieldDesc.getNicerTickValues(initialTicks, fields);
    }
    return undefined;
};
export const getFieldDefaultScale = (field) => {
    return fieldTypeToDescs[field.type].defaultScale ?? 'linear';
};
