import {
  CategoryDataSourceFilter,
  DateRangeDataSourceFilter,
  EmptyDataSourceFilter,
  NumericDataSourceFilter,
  StringDataSourceFilter
} from 'daydash-data-structures';

export const emptyOperations = [
  {
    label: 'Empty',
    value: 'empty'
  },
  {
    label: 'Not Empty',
    value: 'not-empty'
  }
] as {
  label: string;
  value: EmptyDataSourceFilter['operation'];
}[];

export const dateRangeOperations = [
  {
    label: 'Before',
    value: 'before'
  },
  {
    label: 'After',
    value: 'after'
  }
] as {
  label: string;
  value: DateRangeDataSourceFilter['operation'];
}[];

export const numericOperations = [
  {
    label: 'Equals',
    value: '='
  },
  {
    label: 'Not Equals',
    value: '<>'
  },
  {
    label: 'Greater Than',
    value: '>'
  },
  {
    label: 'Greater Than or Equals',
    value: '>='
  },
  {
    label: 'Less Than',
    value: '<'
  },
  {
    label: 'Less Than or Equals',
    value: '<='
  }
] satisfies {
  label: string;
  value: NumericDataSourceFilter['operation'];
}[];

export const stringOperations = [
  {
    label: 'Equals',
    value: '='
  },
  {
    label: 'Not Equals',
    value: '<>'
  },
  {
    label: 'Starts With',
    value: 'starts-with'
  },
  {
    label: 'Not Starts With',
    value: 'not-starts-with'
  },
  {
    label: 'Ends With',
    value: 'ends-with'
  },
  {
    label: 'Not Ends With',
    value: 'not-ends-with'
  },
  {
    label: 'Contains',
    value: 'contains'
  },
  {
    label: 'Not Contains',
    value: 'not-contains'
  }
] satisfies {
  label: string;
  value: StringDataSourceFilter['operation'];
}[];

export const categoryOperations = [
  {
    label: 'Is',
    value: 'in'
  },
  {
    label: 'Is not',
    value: 'not-in'
  }
] satisfies {
  label: string;
  value: CategoryDataSourceFilter['operation'];
}[];
