import { TextInput } from 'axil-web-ui';
import { customCurrencyCodes } from 'daydash-data-structures';
import React, { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';

type CurrencyInputProps = Omit<React.ComponentProps<typeof TextInput>, 'type'> & {
  currencyCode: string;
  onChange: (value: number | null) => void;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  currencyCode,
  value,
  onChange,
  ...rest
}) => {
  const currencyFormatter = useMemo(() => {
    if (customCurrencyCodes.includes(currencyCode as any)) {
      new Intl.NumberFormat(undefined, {
        // TODO: Rethink this
        maximumFractionDigits: 8
      });
    }
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }, [currencyCode]);
  return (
    <NumericFormat
      value={value}
      displayType="input"
      customInput={TextInput}
      thousandSeparator
      prefix={currencyFormatter.format(0).replace(/0/g, '')}
      decimalScale={currencyFormatter.resolvedOptions().maximumFractionDigits}
      {...rest}
      valueIsNumericString
      allowNegative={false}
      onValueChange={values => onChange(values.floatValue ?? null)}
    />
  );
};

export default CurrencyInput;
