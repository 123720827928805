import React, { useCallback } from 'react';
import { isAxiosError } from 'axil-utils';
import UpdatePasswordForm, { FormValues } from '../ResetPassword/UpdatePasswordForm';
import { useToast } from 'axil-web-ui';

function AuthenticationSettings() {
  // const changePasswordMutation = useChangePassword();
  // const { toast } = useToast();
  // const handleChangePasswordSubmit = useCallback(async (values: FormValues) => {
  //   try {
  //     await changePasswordMutation.mutateAsync(values.password);
  //     toast({ title: 'Password successfully changed' });
  //   } catch (err) {
  //     if (isAxiosError(err)) {
  //       return false;
  //     }
  //     throw err;
  //   }
  //   return true;
  // }, []);
  return (
    <div className="mx-auto w-full max-w-lg py-4">
      <h2 className="mb-8 text-2xl">Security Settings</h2>
      <div>
        <h4 className="mb-2 text-xl">Change Password</h4>
        {/* <UpdatePasswordForm onSubmit={handleChangePasswordSubmit} /> */}
      </div>
    </div>
  );
}

export default AuthenticationSettings;
