import { FieldType, NumericAbstractDataField } from 'daydash-data-structures';
import { FieldTypeDesc, fieldTypeToDescs } from 'daydash-unit-descs';

import {
  AlarmClockIcon,
  BanknoteIcon,
  CalendarClockIcon,
  CalendarDaysIcon,
  CalendarFoldIcon,
  CalendarIcon,
  CalendarRangeIcon,
  CalendarSearchIcon,
  CalendarXIcon,
  Clock12Icon,
  FlaskConicalIcon,
  GaugeIcon,
  HashIcon,
  HourglassIcon,
  ListIcon,
  LucideIcon,
  RulerIcon,
  TextIcon,
  ThermometerIcon,
  WatchIcon,
  WeightIcon,
  ZapIcon
} from 'lucide-react';

export const dataTypeConfig = {
  number: {
    Icon: HashIcon,
    label: fieldTypeToDescs.number.typeLabel,
    unitOptions: fieldTypeToDescs.number.getUnitLabelOptions()
  },
  string: { Icon: TextIcon, label: 'Text', unitOptions: null },
  category: { Icon: ListIcon, label: fieldTypeToDescs.category.typeLabel, unitOptions: null },
  currency: {
    Icon: BanknoteIcon,
    label: fieldTypeToDescs.currency.typeLabel,
    unitOptions: fieldTypeToDescs.currency.getUnitLabelOptions()
  },
  dateTime: {
    Icon: CalendarClockIcon,
    label: fieldTypeToDescs.dateTime.typeLabel,
    unitOptions: fieldTypeToDescs.dateTime.getUnitLabelOptions()
  },
  date: {
    Icon: CalendarIcon,
    label: fieldTypeToDescs.date.typeLabel,
    unitOptions: fieldTypeToDescs.date.getUnitLabelOptions()
  },
  hour: {
    Icon: Clock12Icon,
    label: fieldTypeToDescs.hour.typeLabel,
    unitOptions: fieldTypeToDescs.hour.getUnitLabelOptions()
  },
  week: {
    Icon: CalendarRangeIcon,
    label: fieldTypeToDescs.week.typeLabel,
    unitOptions: fieldTypeToDescs.week.getUnitLabelOptions()
  },
  month: {
    Icon: CalendarXIcon,
    label: fieldTypeToDescs.month.typeLabel,
    unitOptions: fieldTypeToDescs.month.getUnitLabelOptions()
  },
  year: {
    Icon: CalendarFoldIcon,
    label: fieldTypeToDescs.year.typeLabel,
    unitOptions: fieldTypeToDescs.year.getUnitLabelOptions()
  },
  timeOfDay: {
    Icon: WatchIcon,
    label: fieldTypeToDescs.timeOfDay.typeLabel,
    unitOptions: fieldTypeToDescs.timeOfDay.getUnitLabelOptions()
  },
  hourOfDay: {
    Icon: AlarmClockIcon,
    label: fieldTypeToDescs.hourOfDay.typeLabel,
    unitOptions: fieldTypeToDescs.hourOfDay.getUnitLabelOptions()
  },
  dayOfWeek: {
    Icon: CalendarDaysIcon,
    label: fieldTypeToDescs.dayOfWeek.typeLabel,
    unitOptions: fieldTypeToDescs.dayOfWeek.getUnitLabelOptions()
  },
  monthOfYear: {
    Icon: CalendarSearchIcon,
    label: fieldTypeToDescs.monthOfYear.typeLabel,
    unitOptions: fieldTypeToDescs.monthOfYear.getUnitLabelOptions()
  },
  duration: {
    Icon: HourglassIcon,
    label: fieldTypeToDescs.duration.typeLabel,
    unitOptions: fieldTypeToDescs.duration.getUnitLabelOptions()
  },
  distance: {
    Icon: RulerIcon,
    label: fieldTypeToDescs.distance.typeLabel,
    unitOptions: fieldTypeToDescs.distance.getUnitLabelOptions()
  },
  mass: {
    Icon: WeightIcon,
    label: fieldTypeToDescs.mass.typeLabel,
    unitOptions: fieldTypeToDescs.mass.getUnitLabelOptions()
  },
  temperature: {
    Icon: ThermometerIcon,
    label: fieldTypeToDescs.temperature.typeLabel,
    unitOptions: fieldTypeToDescs.temperature.getUnitLabelOptions()
  },
  energy: {
    Icon: ZapIcon,
    label: fieldTypeToDescs.energy.typeLabel,
    unitOptions: fieldTypeToDescs.energy.getUnitLabelOptions()
  },
  liquid: {
    Icon: FlaskConicalIcon,
    label: fieldTypeToDescs.liquid.typeLabel,
    unitOptions: fieldTypeToDescs.liquid.getUnitLabelOptions()
  },
  velocity: {
    Icon: GaugeIcon,
    label: fieldTypeToDescs.velocity.typeLabel,
    unitOptions: fieldTypeToDescs.velocity.getUnitLabelOptions()
  }
} satisfies Record<
  FieldType,
  {
    Icon: LucideIcon;
    label: string;
    unitOptions: ReturnType<
      FieldTypeDesc<NumericAbstractDataField['type']>['getUnitLabelOptions']
    > | null;
  }
>;
