import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

export type SortWeights = Record<string, string | null>;

export const sortEntities = <I extends { sortWeight?: string | null; createdAt: string | Date }>(
  unsorted: readonly I[] = []
): I[] => {
  return sortBy(
    unsorted,
    d => d.sortWeight,
    d => new Date(d.createdAt)
  );
};
export const useSortedEntities = <
  I extends { sortWeight?: string | null; createdAt: string | Date }
>(
  unsorted: readonly I[] = []
): I[] => {
  return useMemo(() => sortEntities(unsorted), [unsorted]);
};
