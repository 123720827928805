import { Clerk } from '@clerk/clerk-js';
import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { queryClientAtom } from 'jotai-tanstack-query';
import { loadable } from 'jotai/utils';
import { getLocalDBName } from 'src/core/types';
import { getServiceURL } from 'src/environment';
import persister from 'src/queryPersister';
import { clearIndexedDB } from 'src/utils';
import { routingAtom } from './history.atoms';
import { atomWithUnwrap } from './utils';

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

const clerkAtom = atom(async () => {
  const clerk = new Clerk(clerkPubKey);
  await clerk.load({
    supportEmail: 'support@axil.llc',
    signInUrl: '/login'
    // signUpUrl: '/signup'
  });
  return clerk;
});

export const isAuthInitializedAtom = atom(async get => {
  const clerk = await get(clerkAtom);
  return clerk.loaded;
});

const loadableClerkAtom = loadable(clerkAtom);

type AuthUser = NonNullable<Clerk['user']>;

export const authUserAtom = atom<AuthUser | null>(null);

type Session = NonNullable<Clerk['session']>;

export const authSessionAtom = atom<Session | null>(null);

type AuthClient = NonNullable<Clerk['client']>;

export const authClientAtom = atom<AuthClient | null>(null);

/**
 * Figure out initial clerk loading
 */
export const _activeUserSyncAtom = atomEffect((get, set) => {
  const loaded = get(loadableClerkAtom);
  if (loaded.state !== 'hasData') return;
  const clerk = loaded.data;
  set(authUserAtom, clerk.user ?? null);
  set(authSessionAtom, clerk.session ?? null);
  set(authClientAtom, clerk.client ?? null);
  return clerk.addListener(emission => {
    set(authUserAtom, emission.user ?? null);
    set(authSessionAtom, emission.session ?? null);
    set(authClientAtom, emission.client ?? null);
  });
});

export const _setAxiosAuthInterceptorAtom = atomEffect(get => {
  const session = get(authSessionAtom);
  const id = axios.interceptors.request.use(async config => {
    const headers = { ...config.headers };
    const accessToken = (await session?.getToken()) ?? null;
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
    return {
      ...config,
      headers,
      withCredentials: true,
      baseURL: config.baseURL ?? getServiceURL()
    } as InternalAxiosRequestConfig<any>;
  });
  return () => {
    // Reset when the session changes
    axios.interceptors.request.eject(id);
  };
});

export const userIdAtom = atomWithUnwrap(get => {
  const user = get(authUserAtom);
  return user?.id ?? null;
});

export const isLoggedInAtom = atom(get => {
  return Boolean(get(authSessionAtom));
});

/**
 * Login atoms
 */
export const loginErrorAtom = atom<string | null>(null);
export const loginWithPasswordAtom = atom(
  null,
  async (get, set, email: string, password: string) => {
    try {
      set(loginErrorAtom, null);
      const clerk = await get(clerkAtom);
      const signInAttempt = await clerk.client?.signIn.create({
        strategy: 'password',
        identifier: email,
        password
      });
      // If the sign-in is complete, set the user as active
      if (signInAttempt?.status === 'complete') {
        await clerk.setActive({ session: signInAttempt.createdSessionId });
        const router = get(routingAtom);
        await router?.invalidate();
      } else {
        console.error(signInAttempt);
      }
    } catch (err) {
      if (err instanceof Error) {
        set(loginErrorAtom, err.message);
      } else {
        throw err;
      }
    }
  }
);

// TODO: Add login with email code, Google, and Apple

/**
 * Logout atoms
 */
const cleanupLocalStorageAtom = atom(null, async (get, set) => {
  const queryClient = get(queryClientAtom);
  const userId = get(userIdAtom);
  if (!userId) throw new Error('No user ID found');
  queryClient.getQueryCache().clear();
  await persister.removeClient();
  try {
    await clearIndexedDB(getLocalDBName(userId));
  } catch (err) {
    console.error(err);
  }
});

export const logoutAtom = atom(null, async (get, set) => {
  await set(cleanupLocalStorageAtom);
  await (await get(clerkAtom)).signOut();
  // Reload the app when you log out
  window.location.replace('/');
});

// Keep local storage and what not around when you reauthenticate
export const reauthenticateAtom = atom(null, async (get, set) => {
  await (await get(clerkAtom)).signOut();
  const router = get(routingAtom);
  return router?.navigate({ to: '/login' });
});
