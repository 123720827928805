import { EventEmitterProvider } from '@visx/xychart';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  cn,
  useResizeObserver
} from 'axil-web-ui';
import { Widget } from 'daydash-data-structures';
import React, {
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  CopyPlusIcon,
  GripIcon,
  Maximize2Icon,
  MoreHorizontalIcon,
  MoveIcon,
  PenIcon,
  Trash2Icon
} from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { v4 as uuid } from 'uuid';
import { CustomMenuItem, ViewWidgetProps } from './types';
import StandardTitle from './WidgetTypes/common/StandardTitle';
import WidgetSpinner from './WidgetTypes/common/WidgetSpinner';
import typeToWidgetMap, { NullComponents } from './WidgetTypes/config';

export interface WidgetComponentProps {
  widget: Widget;
  isEditing?: boolean;
  onInspect: (widgetId: string) => void;
  // TODO: Name these better. Edit launches the edit flow, update is the widget to update itself
  onEdit: (widgetId: string) => void;
  onUpdate: (widget: Widget) => Promise<void>;
  onDelete: (widgetId: string) => void;
  onMove: (widgetId: string) => void;
  showDragHandle?: boolean;
}

const OverflowMenuLabel = ({ icon, label }: { icon: ReactElement | null; label: string }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {icon
        ? React.cloneElement(icon, {
            style: {
              fill: 'currentColor',
              stroke: 'currentColor',
              width: 16,
              height: 16
            }
          })
        : null}
      <div>{label}</div>
    </div>
  );
};

function WidgetComponent({
  widget,
  showDragHandle = false,
  isEditing,
  onInspect,
  onEdit,
  onUpdate,
  onDelete,
  onMove
}: WidgetComponentProps) {
  const [editing, setEditing] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true });
  const [customMenuItems, setCustomMenuItems] = useState<CustomMenuItem[] | null>(null);
  // Done to keep consistent references since we almost never want to re-render widget views
  const latestOnUpdate = useRef<typeof onUpdate>();
  latestOnUpdate.current = onUpdate;
  const handleSave = useCallback(async (newWidget: Widget) => {
    await latestOnUpdate.current?.(newWidget);
    setEditing(false);
  }, []);
  const [shouldShowWidgetView, setShouldShouldWidgetView] = useState(false);

  const widgetViewContainer = useRef<HTMLDivElement>(null);
  const widgetViewContainerRect = useResizeObserver(
    shouldShowWidgetView ? widgetViewContainer : null
  );

  // Build it first, but only show it while the widget is in view to help with perf
  const widgetElement = useMemo(() => {
    const WidgetViewComponent = (typeToWidgetMap[widget.type]?.View ??
      NullComponents.View) as React.ComponentType<ViewWidgetProps<Widget>>;
    if (!widgetViewContainerRect) return null;
    return (
      <WidgetViewComponent
        widget={widget}
        onSave={handleSave}
        onSetCustomMenuItems={setCustomMenuItems}
        containerRect={widgetViewContainerRect}
      />
    );
  }, [widget, handleSave, setCustomMenuItems, widgetViewContainerRect]);

  // So we don't block scrolling, try to use requestIdleCallback if available
  useEffect(() => {
    if (shouldShowWidgetView || !inView) return;
    // If it's not supported, just set it to true and move on
    if (!window.requestIdleCallback) {
      setShouldShouldWidgetView(true);
      return;
    }
    const id = window.requestIdleCallback(() => setShouldShouldWidgetView(true), {
      timeout: 200
    });
    return () => window.cancelIdleCallback(id);
  }, [inView]);
  const title = widget.type !== 'Note' && widget.type !== 'SingleValue' ? widget.title : null;
  return (
    <div
      ref={ref}
      className={cn(
        'bg-base-200 group relative flex h-full w-full flex-col overscroll-contain rounded-3xl p-0 shadow-md dark:shadow-xl',
        isEditing ? 'rounded-br-none' : null
      )}>
      <div
        className={cn('flex items-center justify-between', {
          'justify-end': !title && !showDragHandle
        })}>
        {showDragHandle ? (
          <div
            className="drag-handle z-1 cursor-grab p-4"
            style={{
              touchAction: 'none'
            }}>
            <GripIcon />
          </div>
        ) : null}
        {/* TODO: Use a type guard to figure out if its a widget that supports a title */}
        {title ? <StandardTitle title={title} /> : null}
        {!editing ? (
          <DropdownMenu>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => onInspect(widget.id)}>
                <OverflowMenuLabel icon={<Maximize2Icon />} label="Expand" />
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onEdit(widget.id)}>
                <OverflowMenuLabel icon={<PenIcon />} label="Edit" />
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onMove(widget.id)}>
                <OverflowMenuLabel icon={<MoveIcon />} label="Move" />
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  // Update with a new id acts as an upsert
                  onUpdate({
                    ...widget,
                    // Don't persist the x value, otherwise the widget gets placed in an awkward spot
                    layout: widget.layout
                      ? {
                          ...widget.layout,
                          x: 0
                        }
                      : undefined,
                    id: uuid()
                  })
                }>
                <OverflowMenuLabel icon={<CopyPlusIcon />} label="Duplicate" />
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onDelete(widget.id)}>
                <OverflowMenuLabel icon={<Trash2Icon />} label="Delete" />
              </DropdownMenuItem>
              {customMenuItems && customMenuItems.length > 0 ? (
                <>
                  <DropdownMenuSeparator />
                  {customMenuItems.map(customMenuItem => {
                    return (
                      <DropdownMenuItem
                        key={customMenuItem.label}
                        onClick={customMenuItem.onClick}
                        disabled={!!customMenuItem.disabled}>
                        <OverflowMenuLabel
                          icon={customMenuItem.icon ?? null}
                          label={customMenuItem.label}
                        />
                      </DropdownMenuItem>
                    );
                  })}
                </>
              ) : null}
            </DropdownMenuContent>
            <DropdownMenuTrigger>
              <div className="px-4 py-2">
                <MoreHorizontalIcon />
              </div>
            </DropdownMenuTrigger>
          </DropdownMenu>
        ) : null}
      </div>
      <div className="min-h-0 shrink grow" ref={widgetViewContainer}>
        <Suspense fallback={<WidgetSpinner />}>
          <EventEmitterProvider>{shouldShowWidgetView ? widgetElement : null}</EventEmitterProvider>
        </Suspense>
      </div>
    </div>
  );
}

export default WidgetComponent;
