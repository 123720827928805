import { Select } from 'axil-web-ui';
import { useAtomValue } from 'jotai';
import get from 'lodash/get';
import React, { useEffect, useMemo } from 'react';
import { dataSourceListAtom } from 'src/atoms/dataSources.atoms';
import { useWidgetForm, WidgetFormPath } from 'src/components/Widget/WidgetForm/context';
import { useDataFetch, useDataSourceSections } from 'src/hooks';
import DataSourceFiltersField from './DataSourceFiltersField';

type DataSourcePath = Extract<WidgetFormPath, `${string}dataSource`>;

/**
 * The fields for the base layer. This is where we pick the datasource and section
 */
export default function BaseLayerField({ dsFieldPath }: { dsFieldPath: DataSourcePath }) {
  const form = useWidgetForm();
  const { data: dataSources } = useAtomValue(dataSourceListAtom);
  const baseLayerPath = `${dsFieldPath}.layers[0]` as const;
  const currentLayer = form.useStore(s => get(s.values, baseLayerPath));
  const dataSourceIdPath = `${baseLayerPath}.dataSourceId` as const;
  const dataSourceSectionIdPath = `${baseLayerPath}.sectionId` as const;
  const filtersPath = `${baseLayerPath}.filters` as const;
  const dataSourceId = form.useStore(s => get(s.values, dataSourceIdPath));
  const dataSourceSectionId = form.useStore(s => get(s.values, dataSourceSectionIdPath));
  const { data: records } = useDataFetch(currentLayer ? [currentLayer] : null, {
    defaultDir: 'DESC',
    selected: 'all'
  });
  const dataSourceSelectOptions = useMemo(() => {
    return dataSources?.map(dataSource => ({
      label: dataSource.name,
      disabled: false,
      value: dataSource.id
    }));
  }, [dataSources]);
  const { data: sections } = useDataSourceSections(dataSourceId ?? null);
  useEffect(() => {
    if (
      sections &&
      dataSourceId &&
      dataSourceSectionId &&
      sections &&
      !sections.find(section => section.id === dataSourceSectionId)
    ) {
      form.setFieldValue(dataSourceSectionIdPath, undefined, { dontUpdateMeta: true });
    }
  }, [sections, dataSourceSectionId, dataSourceId]);

  const sectionSelectOptions = useMemo(() => {
    return sections?.map(section => ({
      label: section.label,
      disabled: false,
      value: section.id
    }));
  }, [sections]);

  useEffect(() => {
    if (sectionSelectOptions && sectionSelectOptions.length > 0 && !dataSourceSectionId) {
      // Auto select the first one
      form.setFieldValue(dataSourceSectionIdPath, sectionSelectOptions[0]?.value, {
        dontUpdateMeta: true
      });
    }
  }, [sectionSelectOptions, dataSourceSectionId]);
  return (
    <div>
      <h3 className="mb-4 text-xl">{`Initial Data Source`}</h3>
      <form.Field name={dataSourceIdPath}>
        {field => (
          <Select
            options={dataSourceSelectOptions || []}
            name={field.name}
            label="Data Source"
            isOptionDisabled={o => !!o.disabled}
            value={dataSourceSelectOptions?.find(o => o.value === dataSourceId)}
            onChange={o => field.handleChange(o?.value)}
          />
        )}
      </form.Field>
      {sectionSelectOptions ? (
        <form.Field name={dataSourceSectionIdPath}>
          {field => (
            <Select
              options={sectionSelectOptions || []}
              name={field.name}
              label="Section"
              isOptionDisabled={o => !!o.disabled}
              value={sectionSelectOptions?.find(o => o.value === dataSourceSectionId)}
              onChange={o => field.handleChange(o?.value)}
            />
          )}
        </form.Field>
      ) : null}
      {dataSourceSectionId && records?.fields ? (
        <DataSourceFiltersField
          fieldPath={filtersPath}
          dataFields={records?.fields}
          sectionId={dataSourceSectionId}
        />
      ) : null}
    </div>
  );
}
