import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { atomWithStorage } from 'jotai/utils';
import log from 'src/log';
import { pageSizeAtom, pathnameAtom } from '.';

const expandedPreferenceAtom = atomWithStorage<boolean>('sidebar-expanded', true, undefined, {
  getOnInit: true
});

export const canExpandSidebarAtom = atom(get => {
  return get(pageSizeAtom) !== 'sm';
});

export const sidebarAtom = atom<{
  expanded: boolean;
  open: boolean;
  hovering: boolean;
}>({
  expanded: true,
  open: false,
  hovering: false
});

export const _sidebarEffect = atomEffect((get, set) => {
  const currentExpanded = get(sidebarAtom).expanded;
  const canExpand = get(canExpandSidebarAtom);
  const expandedPreference = get(expandedPreferenceAtom);
  let newExpanded: boolean;
  if (canExpand && expandedPreference) newExpanded = true;
  else if (!canExpand) newExpanded = false;
  else if (!expandedPreference) newExpanded = false;
  else newExpanded = currentExpanded;
  set(sidebarAtom, prev => ({ ...prev, expanded: newExpanded }));
});

export const _sidebarCloseOnPathChange = atomEffect((get, set) => {
  get(pathnameAtom); // Triggers the update
  if (get.peek(sidebarAtom).open) set(closeSidebarAtom);
});

export const expandSidebarAtom = atom(null, (get, set) => {
  if (!get(canExpandSidebarAtom)) {
    log.error('Sidebar cannot expand');
    return;
  }
  set(expandedPreferenceAtom, true);
  set(sidebarAtom, { hovering: false, expanded: true, open: false });
});

export const collapseSidebarAtom = atom(null, (get, set) => {
  set(expandedPreferenceAtom, false);
  set(sidebarAtom, { hovering: false, expanded: false, open: false });
});

export const openSidebarAtom = atom(null, (get, set) => {
  if (get(sidebarAtom).expanded) {
    log.error('Sidebar is already expanded, cannot open');
    return;
  }
  set(sidebarAtom, { expanded: false, hovering: false, open: true });
});

export const closeSidebarAtom = atom(null, (get, set) => {
  if (get(sidebarAtom).expanded) {
    log.error('Sidebar is expanded, cannot close. Use a collapse instead');
    return;
  }
  set(sidebarAtom, { expanded: false, hovering: false, open: false });
});

export const hoverSidebarAtom = atom(null, (get, set, hovering: boolean) => {
  set(sidebarAtom, prev => ({ ...prev, hovering }));
});
