import { Tabs } from 'axil-web-ui';
import React, { useState } from 'react';
import Security from './Security';
import GeneralSettings from './GeneralSettings';
import UnitPreferences from './UnitPreferences';

function Account() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="flex min-h-0 max-w-full flex-col self-center p-4 sm:p-8 md:p-16">
      <h1 className="mb-4 mt-0 text-center text-3xl">Account</h1>
      <Tabs
        activeIndex={activeIndex}
        onTabSelect={setActiveIndex}
        items={[
          {
            title: 'General',
            content: <GeneralSettings />
          },
          {
            title: 'Unit Preferences',
            content: <UnitPreferences />
          },
          {
            title: 'Security',
            content: <Security />
          }
        ]}
      />
    </div>
  );
}

export default Account;
