import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useNavigate } from '@tanstack/react-router';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'axil-web-ui';
import { useAtom, useSetAtom, useStore } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { RocketIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { devToolsAtom, setDevToolsAtom } from 'src/atoms';

const getTanStackContainer = () => document.querySelector<HTMLDivElement>('.tsqd-parent-container');

const getTanStackTriggerButton = () =>
  getTanStackContainer()?.querySelector<HTMLButtonElement>('.tsqd-open-btn');

const getTanStackQueriesContainer = () => {
  return getTanStackContainer()?.querySelector<HTMLDivElement>('.tsqd-queries-container');
};

export default function DevTools() {
  const store = useStore();
  const [devTools] = useAtom(devToolsAtom);
  const showQueryDevTools = useSetAtom(setDevToolsAtom);
  const shouldShow = !['production', 'test'].includes(import.meta.env.MODE as any);
  const navigate = useNavigate();
  // If the user hides or shows different devtools, make sure you update our state
  useEffect(() => {
    if (!shouldShow) return;
    let jotaiInitialized = false;
    let tanstackInitialized = false;
    const syncWithDom = () => {
      if (devTools.jotai) {
        if (document.querySelector('.jotai-devtools-shell')) {
          setTimeout(() => {
            jotaiInitialized = true;
          }, 200);
        } else if (jotaiInitialized && document.querySelector('.jotai-devtools-trigger-button')) {
          // If its initialized and the button is showing, that means the user has closed it and we should remove it
          showQueryDevTools('jotai', false);
        }
      }
      if (devTools.query) {
        const tanStackContainer = getTanStackContainer();
        const tanStackButton = getTanStackTriggerButton();
        if (tanStackContainer && tanStackButton) {
          if (!tanstackInitialized) {
            tanStackButton.click();
            setTimeout(() => {
              tanstackInitialized = true;
            }, 200);
          } else if (!getTanStackQueriesContainer()) {
            // If its initialized and the button is showing, that means the user has closed it and we should remove it
            showQueryDevTools('query', false);
          }
        }
      }
    };
    syncWithDom();
    // Detect if it was closed and update the atom if it was
    const interval = setInterval(() => {
      syncWithDom();
    }, 100);
    return () => clearInterval(interval);
  }, [shouldShow, devTools]);
  if (!shouldShow) return null;
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <DropdownMenu>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => showQueryDevTools('jotai', true)}>
            Jotai DevTools
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => showQueryDevTools('query', true)}>
            TanStack Query DevTools
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate({ to: '/admin' })}>
            Go to Admin
          </DropdownMenuItem>
        </DropdownMenuContent>
        <DropdownMenuTrigger>
          <RocketIcon size={24} />
        </DropdownMenuTrigger>
      </DropdownMenu>
      {devTools.query ? <ReactQueryDevtools /> : null}
      {devTools.jotai ? (
        <JotaiDevTools
          store={store}
          isInitialOpen
          options={{
            shouldShowPrivateAtoms: true,
            shouldExpandJsonTreeViewInitially: true
          }}
        />
      ) : null}
    </div>
  );
}
