import { PaginationState, SortingState } from '@tanstack/react-table';
import { DataTable } from 'axil-web-ui';
import { BaseLayerConfig, LayerConfig } from 'daydash-data-structures';
import React, { useLayoutEffect, useState } from 'react';
import { useDataFetch } from 'src/hooks';
import { useWindowBreakpointSize } from 'src/hooks/styles';
import { useAbstractDataFieldTableColumns } from 'src/hooks/tables';

const defaultPaginationState = {
  pageIndex: 0,
  pageSize: 200
};
export function PreviewData({ layers }: { layers: [BaseLayerConfig, ...LayerConfig[]] }) {
  const size = useWindowBreakpointSize();
  const mobileMode = size === 'sm';
  const [sortingState, setSortingState] = useState<SortingState | null>(null);
  const [paginationState, setPaginationState] = useState<PaginationState>(defaultPaginationState);
  // Reset pagination when sorting changes
  useLayoutEffect(() => {
    setPaginationState(defaultPaginationState);
  }, [sortingState]);
  const { data: records } = useDataFetch(layers, {
    pageIndex: paginationState.pageIndex,
    pageSize: paginationState.pageSize,
    defaultDir: 'DESC',
    selected: 'all',
    order: sortingState?.map(s => ({ name: s.id, dir: s.desc ? 'DESC' : 'ASC' }))
  });
  const columns = useAbstractDataFieldTableColumns(records?.fields ?? null);
  if (!records || !columns) return null;
  return (
    <div className="h-96 max-h-full min-h-0">
      <DataTable
        columns={columns}
        data={records.data ?? null}
        type="pagination"
        size={mobileMode ? 'xs' : 'md'}
        sortingState={sortingState}
        onHeaderCellClick={header => {
          setSortingState([
            { id: header.id, desc: sortingState?.[0].id === header.id && !sortingState?.[0].desc }
          ]);
        }}
        paginationState={paginationState}
        onPaginationChange={setPaginationState}
        pageCount={Math.ceil(records.total / paginationState.pageSize)}
      />
    </div>
  );
}
