import { animated, useSpring } from '@react-spring/web';
import { usePrefersReducedMotion, useResizeObserver } from 'axil-web-ui';
import { useAtom, useSetAtom } from 'jotai';
import { throttle } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { closeSidebarAtom, hoverSidebarAtom, sidebarAtom } from 'src/atoms';

export type FloatingSidebarOpenState =
  | { open: false }
  | { open: true; reason: 'hover' | 'anchored' };

export default function SidebarFloatingDrawer({ children }: { children: React.ReactElement }) {
  const [{ open, hovering }] = useAtom(sidebarAtom);
  const hoverSidebar = useSetAtom(hoverSidebarAtom);
  const closeSidebar = useSetAtom(closeSidebarAtom);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRect = useResizeObserver(wrapperRef);
  // Forward the rect to a ref so we can use it in the hook
  const wrapperRectRef = useRef<DOMRectReadOnly | null>(null);
  wrapperRectRef.current = wrapperRect;
  // Reset open if a user taps outside of the sidebar when its anchored
  useEffect(() => {
    if (!open) return;
    const handleClick = (evt: MouseEvent) => {
      if (!wrapperRef.current) return;
      if (
        evt.target instanceof Element &&
        !wrapperRef.current.contains(evt.target) &&
        !evt.target.closest('#sidebar-toggle') // Don't worry if you click on the top nav
      ) {
        closeSidebar();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [open]);
  useEffect(() => {
    if (open) return;
    const handlePointerMove = throttle((evt: PointerEvent) => {
      if (!wrapperRef.current) return;
      const leftBound = hovering ? wrapperRef.current.clientWidth : 0;
      const buffer = 24;
      const newHovering = Math.abs(evt.clientX) < leftBound + buffer;
      if (hovering !== newHovering) {
        hoverSidebar(newHovering);
      }
    }, 50);
    document.addEventListener('pointermove', handlePointerMove);
    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      handlePointerMove.cancel();
    };
  }, [open, hovering]);
  // Default the width to 1000 so it stays off screen when you first load the page
  const style = useSpring({
    left: open || hovering ? 0 : (wrapperRect?.width ?? 1000) * -1,
    immediate: usePrefersReducedMotion()
  });
  // TODO: Setting top to 58px to match the nav height for now but later on we should
  // position this absolutely so we don't need to know the nav height
  return (
    <animated.div
      ref={wrapperRef}
      className="fixed bottom-10 top-20 z-30 overflow-hidden rounded-r-xl shadow-xl"
      style={style}>
      {children}
    </animated.div>
  );
}
