import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { DataSourceBuilder } from 'daydash-data-structures';
import { useCoreClient } from '../../context';
import { DataSource } from '../../types/entities';
import { Connector } from '../../types/service';
import { SortWeights } from './common';
import CoreClient from 'src/core/core.client';
import { useAtomValue } from 'jotai';
import { isLoggedInAtom } from 'src/atoms';

export function useConnectorById() {
  const { isLoading, data: connectors } = useQuery<Connector[], AxiosError>({
    queryKey: [`/data-source/connector-list`]
  });
  return {
    isLoading,
    connectors: connectors ? Object.fromEntries(connectors.map(c => [c.id, c])) : null
  };
}

export const useAllDataSources = () => {
  const db = useCoreClient();
  return useQuery(db.getQueryOptions.getAllDataSources());
};

export const useDataSource = (id: string) => {
  const db = useCoreClient();
  return useQuery(db.getQueryOptions.getDataSource(id));
};

const isNewWeight = (updated: [string, string | null]): updated is [string, string] => {
  return Boolean(updated[1]);
};

async function optimisticallyUpdateAllDataSources(
  updatedDataSources: (Partial<DataSource> & { id: string })[],
  coreClient: CoreClient,
  queryClient: QueryClient
) {
  const queryKey = coreClient.getQueryOptions.getAllDataSources().queryKey;
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey });
  // Snapshot the previous values
  const previousDataSources = queryClient.getQueryData<DataSource[] | undefined>(queryKey);
  // Optimistically update to the new value
  const byId = new Map(updatedDataSources?.map(d => [d.id, d]) ?? []);
  queryClient.setQueryData<DataSource[] | undefined>(queryKey, old => {
    if (!old) return;
    return old.map(d => {
      const updated = byId.get(d.id);
      if (updated) return { ...d, ...updated };
      return d;
    });
  });
  return previousDataSources;
}

async function optimisticallyUpdateDataSource(
  updatedDataSource: Partial<DataSource> & { id: string },
  coreClient: CoreClient,
  queryClient: QueryClient
) {
  const queryKey = coreClient.getQueryOptions.getDataSource(updatedDataSource.id).queryKey;
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey });
  // Snapshot the previous values
  const previousDataSource = queryClient.getQueryData<DataSource | undefined>(queryKey);
  // Optimistically update to the new value
  queryClient.setQueryData<DataSource | null>(queryKey, old => {
    if (!old) return null; // Should really never happen
    return { ...old, ...updatedDataSource };
  });
  return previousDataSource;
}

export const useUpdateDataSourceSortWeights = () => {
  const db = useCoreClient();
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<DataSource>[], unknown, SortWeights, DataSource[]>({
    mutationFn: weights => {
      return Promise.all(
        Object.entries(weights)
          .filter(isNewWeight)
          .map(async ([id, newWeight]) =>
            axios.patch(`/data-source/${id}`, { sortWeight: newWeight })
          )
      );
    },
    onMutate: async weights => {
      return await optimisticallyUpdateAllDataSources(
        Object.entries(weights).map(([id, sortWeight]) => ({ id, sortWeight })),
        db,
        queryClient
      );
    },
    onError: async (_, weights, previousDataSources) => {
      if (!previousDataSources) return;
      queryClient.setQueryData(
        db.getQueryOptions.getAllDataSources().queryKey,
        previousDataSources
      );
    }
  });
  return mutation;
};

export const getDeleteDataSourceMutationOptions = (
  coreClient: CoreClient,
  queryClient: QueryClient,
  onSuccess?: () => void
): UseMutationOptions<AxiosResponse, AxiosError, string> => ({
  mutationFn: id => axios.delete('/data-source/' + id),
  async onSuccess(_, id) {
    const dataSourcesQueryKey = coreClient.getQueryOptions.getAllDataSources().queryKey;
    // Optimistically update to the new value while we wait for the sync
    queryClient.setQueryData<DataSource[] | undefined>(dataSourcesQueryKey, old => {
      if (!old) return;
      return old.filter(d => d.id !== id);
    });
    const dataSourceQueryLey = coreClient.getQueryOptions.getDataSource(id!).queryKey;
    queryClient.setQueryData<DataSource | null>(dataSourceQueryLey, null);
    onSuccess?.();
  }
});

// Use service for deletes so we make sure it deletes everything we need
export const useDeleteDataSource = (onSuccess?: () => void) => {
  const db = useCoreClient();
  const queryClient = useQueryClient();
  return useMutation(getDeleteDataSourceMutationOptions(db, queryClient, onSuccess));
};

export const usePatchDataSource = () => {
  const db = useCoreClient();
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<DataSource>,
    unknown,
    {
      id: string;
      name?: string;
      sortWeight?: string;
    },
    {
      previousDataSource: DataSource | undefined;
      previousDataSources: DataSource[] | undefined;
    }
  >({
    mutationFn: payload => axios.patch(`/data-source/${payload.id}`, payload),
    async onMutate(payload) {
      return {
        previousDataSource: await optimisticallyUpdateDataSource(payload, db, queryClient),
        previousDataSources: await optimisticallyUpdateAllDataSources([payload], db, queryClient)
      };
    },
    onError(_, payload, context) {
      if (!context) return;
      const { previousDataSource, previousDataSources } = context;
      queryClient.setQueryData(
        db.getQueryOptions.getDataSource(payload.id).queryKey,
        previousDataSource
      );
      queryClient.setQueryData(
        db.getQueryOptions.getAllDataSources().queryKey,
        previousDataSources
      );
    }
  });
};

export const useTestConnection = (id?: string) => {
  return useMutation({
    mutationFn: () => {
      if (!id) throw new Error('ID required!');
      return axios.post(`/data-source/test-connection/${id}`);
    }
  });
};

export const useSyncDataSource = (id?: string) => {
  return useMutation({
    mutationFn: () => {
      if (!id) throw new Error('ID required!');
      return axios.post(`/data-source/sync/${id}`);
    },
    onSuccess(resp) {
      // TODO: Synchronize local datasource data when this finishes. Basically, prioritize it
      // as part of the sync stream instead of waiting
    }
  });
};

export type SubmitBuilderPayload = {
  currentDataSource: {
    connectorId: string;
    accessCredentialId?: string | undefined;
    name?: string | undefined;
    config?: any;
    clientData?: any;
  };
  currentStepId: string | null;
  refresh?: boolean;
};
export const getSubmitBuilderMutationOptions = (): UseMutationOptions<
  AxiosResponse<{
    dataSource: {
      id: string;
      // More data source stuff here but the types slightly differ than Electric
    } | null;
    nextStepId: string;
    steps: DataSourceBuilder.ConnectorStep[];
  }>,
  AxiosError,
  SubmitBuilderPayload
> => {
  return {
    mutationFn: payload => {
      return axios.post(`/data-source/builder/submit`, payload);
    },
    onSuccess(resp) {}
  };
};

export const useSubmitBuilder = () => {
  return useMutation(getSubmitBuilderMutationOptions());
};
