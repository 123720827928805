import { BaseFieldTypeDesc, getDateFormatter } from './common';
export default class DateDesc extends BaseFieldTypeDesc {
    typeLabel = 'Date';
    dataLabel = 'Date';
    unitLabels = {
        epochSeconds: 'Epoch Seconds',
        epochMS: 'Epoch Milliseconds',
        SerialNumber: 'Serial Number'
    };
    defaultScale = 'time';
    tickFormatter = getDateFormatter('P');
    dataPointFormatter = getDateFormatter('P');
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
