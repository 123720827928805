import { currencyCodes, currencyLabels, customCurrencyCodes } from 'daydash-data-structures';
import { BaseFieldTypeDesc } from './common';
const getCurrencyCodeFromFieldCurrency = (unit) => {
    return unit.toString();
};
const isCustomCurrencyCode = (currency) => {
    return customCurrencyCodes.includes(currency.toString());
};
export default class CurrencyDesc extends BaseFieldTypeDesc {
    typeLabel = 'Currency';
    dataLabel = 'Amount';
    // Create a new unit labels object so it's in a sorted order and it has the proper currency values
    unitLabels = currencyCodes.reduce((mapping, code) => {
        mapping[code] = currencyLabels[code];
        return mapping;
    }, {});
    cachedCurrencyFormatters = {};
    static customCodePrecisions = {
        BTC: 8,
        ETH: 18
    };
    static customCurrencySymbols = {
        BTC: '₿'
    };
    getSimpleCustomFormatter = (code) => {
        const formatter = new Intl.NumberFormat(undefined, {
            maximumFractionDigits: CurrencyDesc.customCodePrecisions[code]
        });
        const prefix = CurrencyDesc.customCurrencySymbols[code] ?? code;
        return (dataPoint) => {
            return prefix + ' ' + formatter.format(dataPoint);
        };
    };
    // Custom currencies use their own number formatter
    getCurrencyFormatter = (currency) => {
        if (!this.cachedCurrencyFormatters[currency]) {
            let format;
            if (isCustomCurrencyCode(currency)) {
                format = this.getSimpleCustomFormatter(currency);
            }
            else {
                const formatter = new Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: currency
                });
                format = formatter.format;
            }
            this.cachedCurrencyFormatters[currency] = format;
        }
        return this.cachedCurrencyFormatters[currency];
    };
    dataPointFormatter = (field, dataPoint) => {
        const currencyCode = getCurrencyCodeFromFieldCurrency(field.unit);
        if (currencyCode) {
            const format = this.getCurrencyFormatter(currencyCode);
            return format(dataPoint);
        }
        return '';
    };
    tickFormatter = (field, dataPoint) => {
        const currencyCode = getCurrencyCodeFromFieldCurrency(field.unit);
        if (currencyCode) {
            const format = this.getCurrencyFormatter(currencyCode);
            return format(dataPoint);
        }
        return '';
    };
}
