import { DeepKeys, FormApi, FormState } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { isNotEmpty } from 'axil-utils';
import { Widget, isWidgetTypeWithDataSource, widgetSchema } from 'daydash-data-structures';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { ZodRawShape } from 'zod';
import lget from 'lodash/get';

const widgetFormAtom = atom<FormApi<Widget, ReturnType<typeof zodValidator>> | null>(null);

export const widgetFormStateAtom = atom<FormState<Widget> | null>();

export const widgetFormInitializedAtom = atom(get => Boolean(get(widgetFormAtom)));

export const initializeWidgetFormAtom = atom(
  null,
  (get, set, form: FormApi<Widget, ReturnType<typeof zodValidator>>) => {
    set(widgetFormAtom, form);
    // Initialize the state. The listener will be set in the sync effect
    set(widgetFormStateAtom, form.store.state);
    // Set to finalize by default if we have default values
    const editing = Boolean(form.options.defaultValues?.type);
    set(currentWidgetFormStepAtom, editing ? 'finalize' : 'type-picker');
  }
);

export const cleanupWidgetFormAtom = atom(null, (get, set) => {
  const form = get(widgetFormAtom);
  if (!form) return;
  set(widgetFormAtom, null);
  set(widgetFormStateAtom, null);
});

export const _syncWidgetFormStateAtom = atomEffect((get, set) => {
  const widgetForm = get(widgetFormAtom);
  return widgetForm?.store.subscribe(() => {
    set(widgetFormStateAtom, widgetForm.store.state);
  });
});

export const widgetFormDirtyAtom = atom(get => get(widgetFormStateAtom)?.isDirty ?? null);

export const currentTypeAtom = atom(get => get(widgetFormStateAtom)?.values.type ?? null);

const STEPS = ['type-picker', 'data-source-config', 'finalize'] as const;
export type STEP = (typeof STEPS)[number];

export const dataSourceFieldsValidAtom = atom(get => {
  const fieldPath = get(dataSourceFieldPathAtom);
  const errorMap = get(widgetFormStateAtom)?.errorMap;
  return Boolean(fieldPath && !lget(errorMap, fieldPath));
});

export const availableStepsAtom = atom(get => {
  const dataSourceFieldsValid = get(dataSourceFieldsValidAtom);
  const currentType = get(currentTypeAtom);
  return [
    'type-picker' as const,
    currentType && isWidgetTypeWithDataSource(currentType) ? ('data-source-config' as const) : null,
    currentType &&
    ((isWidgetTypeWithDataSource(currentType) && dataSourceFieldsValid) ||
      !isWidgetTypeWithDataSource(currentType))
      ? ('finalize' as const)
      : null
  ].filter(isNotEmpty);
});

export const currentWidgetFormStepAtom = atom<STEP>('type-picker');

export const currentWidgetTypeSchemaAtom = atom(get => {
  const currentType = get(currentTypeAtom);
  if (!currentType) return null;
  const schema = widgetSchema.options.find(o => o.shape.type.value === currentType)?.shape;
  if (!schema) throw new Error(`Schema not found for type ${currentType}`);
  return schema;
});

export const dataSourceFieldPathAtom = atom(get => {
  const currentTypeSchema = get(currentWidgetTypeSchemaAtom);
  if (!currentTypeSchema) return null;
  return (currentTypeSchema as ZodRawShape).chartConfig ? 'chartConfig.dataSource' : 'dataSource';
});

const nextStepAtom = atom(null, (get, set) => {
  const nextSteps = get(availableStepsAtom);
  set(currentWidgetFormStepAtom, nextSteps[nextSteps.indexOf(get(currentWidgetFormStepAtom)) + 1]);
});

export const updateTypeAtom = atom(null, (get, set, newType: Widget['type']) => {
  const form = get(widgetFormAtom);
  if (!form) throw new Error('Form not found');
  if (!newType) return;
  const initialDataSourcePath = get(dataSourceFieldPathAtom);
  form.setFieldValue('type', newType);
  const currentTypeSchema = get(currentWidgetTypeSchemaAtom);
  if (!currentTypeSchema) throw new Error('Schema not found');
  // Get default v type
  form.setFieldValue('v', currentTypeSchema.v.parse(undefined), { dontUpdateMeta: true });
  // Reset the data source fields as necessary
  const newDataSourcePath = get(dataSourceFieldPathAtom);
  if (initialDataSourcePath && newDataSourcePath && initialDataSourcePath !== newDataSourcePath) {
    form.setFieldValue(newDataSourcePath, form.getFieldValue(initialDataSourcePath));
  }
  // Now, reset all of the fields that weren't touched as we can set new defaults
  // (think horizontal defaulting to true for cat bar chart but not bar chart)
  Object.entries(form.state.fieldMeta).forEach(([key, meta]) => {
    if (key === 'type' || key === 'v' || (newDataSourcePath && key.startsWith(newDataSourcePath)))
      return;
    if (!meta.isTouched) form.deleteField(key as DeepKeys<Widget>);
  });
  set(nextStepAtom);
});
