import { Link } from '@tanstack/react-router';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'axil-web-ui';
import { useAtomValue, useSetAtom } from 'jotai';
import { CircleUserIcon, MenuIcon, SettingsIcon } from 'lucide-react';
import React, { useState } from 'react';
import logo from 'src/assets/logo.svg';
import { updateDialogAtom } from 'src/atoms';
import log from 'src/log';
import { logoutAtom } from 'src/atoms/auth.atoms';
import { currentUserAtom, userProfileImageAtom } from 'src/atoms/user.atoms';

interface TopNavProps {
  gridArea?: string;
  onMenuClick: () => void;
  showSidebarToggle: boolean;
}

function AccountMenu({ onSettingsClick }: { onSettingsClick: () => void }) {
  const currentUser = useAtomValue(currentUserAtom);
  const logout = useSetAtom(logoutAtom);
  if (!currentUser) {
    log.error('Active user not found in account menu!');
    return null;
  }
  return (
    <div className="flex w-64 flex-col gap-4 p-4">
      <div>
        <h4 className="m-0 text-lg">User</h4>
        {currentUser.firstName && currentUser.lastName ? (
          <div>{`${currentUser.firstName} ${currentUser.lastName}`}</div>
        ) : null}
        <div>{currentUser.emailAddresses[0]}</div>
      </div>
      <hr style={{ width: '100%' }} />
      <Button onClick={onSettingsClick}>
        <span className="mr-4">
          <SettingsIcon size="24px" />
        </span>
        Settings
      </Button>
      <hr style={{ width: '100%' }} />
      <div>
        <Button color="warning" onClick={logout}>
          Log out
        </Button>
      </div>
    </div>
  );
}

function TopNav({ gridArea, onMenuClick, showSidebarToggle }: TopNavProps) {
  const [settingsDropMenuOpen, setSettingsDropMenuOpen] = useState(false);
  const updateDialog = useSetAtom(updateDialogAtom);
  const profileImage = useAtomValue(userProfileImageAtom);
  return (
    <nav
      style={{ gridArea }}
      className={
        'bg-base-300 flex flex-row justify-between border-b-2 border-neutral-300 px-8 py-4 dark:border-neutral-500'
      }>
      <div className="flex items-center gap-8">
        {showSidebarToggle ? (
          <div onClick={onMenuClick} className="cursor-pointer" id="sidebar-toggle">
            <MenuIcon size="24px" />
          </div>
        ) : null}
        <Link to="/">
          <img src={logo} alt="DayDash" className="h-8" />
        </Link>
      </div>
      <DropdownMenu
        open={settingsDropMenuOpen}
        onOpenChange={open => setSettingsDropMenuOpen(open)}>
        <DropdownMenuContent align="end">
          <AccountMenu
            onSettingsClick={() => {
              setSettingsDropMenuOpen(false);
              updateDialog({
                type: 'AccountPreferences',
                payload: null
              });
            }}
          />
        </DropdownMenuContent>
        <DropdownMenuTrigger>
          {profileImage ? (
            <img src={profileImage} alt="Profile Image" className="size-8 rounded-full" />
          ) : (
            <CircleUserIcon className="size-8" />
          )}
        </DropdownMenuTrigger>
      </DropdownMenu>
    </nav>
  );
}

export default TopNav;
