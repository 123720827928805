import { BaseFieldTypeDesc } from './common';
export default class MassDesc extends BaseFieldTypeDesc {
    typeLabel = 'Mass';
    dataLabel = 'Mass';
    unitLabels = {
        lb: 'Pound',
        kg: 'Kilogram'
    };
    static dataPointNumberFormats = {
        lb: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'pound',
            unitDisplay: 'short'
        }),
        kg: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'kilogram',
            unitDisplay: 'short'
        })
    };
    static tickNumberFormats = {
        lb: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'pound',
            unitDisplay: 'short' // Narrows is just a # for some reason
        }),
        kg: new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
            style: 'unit',
            unit: 'kilogram',
            unitDisplay: 'narrow'
        })
    };
    dataPointFormatter = (field, dataPoint) => {
        const numberFormat = MassDesc.dataPointNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
    tickFormatter = (field, dataPoint) => {
        const numberFormat = MassDesc.tickNumberFormats[field.unit];
        return numberFormat.format(dataPoint);
    };
}
