import { CellContext, ColumnDef, ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import { DataTableProps } from 'axil-web-ui';
import {
  AbstractDataField,
  isDateDataType,
  isNumericAbstractDataField
} from 'daydash-data-structures';
import { formatDataPoint } from 'daydash-unit-descs';
import React, { useMemo } from 'react';
import Linkify from 'react-linkify';
import { dataTypeConfig } from 'src/components/common/dataTypeConfig';

type HeaderWrapperProps = {
  children: JSX.Element;
  field: AbstractDataField;
};

function getColumnConfigFromDataField(
  field: AbstractDataField,
  helper: ColumnHelper<{
    [key: string]: any;
  }>,
  HeaderWrapper?: React.ComponentType<HeaderWrapperProps>
): ColumnDef<
  {
    [key: string]: any;
  },
  any
> {
  let textAlign: 'start' | 'end' | 'center' = 'start';
  if (isNumericAbstractDataField(field) && !isDateDataType(field.type)) {
    textAlign = 'end';
  }
  const wrapperStyles: React.CSSProperties = {
    textAlign,
    display: 'inline-block',
    whiteSpace: 'normal',
    maxWidth: 360,
    fontSize: '1rem'
  };
  const CellRender = (
    props: CellContext<
      {
        [key: string]: any;
      },
      any
    >
  ) => {
    const cellWrapperStyles = {
      ...wrapperStyles
    };
    const value = props.getValue();
    let formattedVal: string | number | JSX.Element;
    if (field.type === 'category' || field.type === 'duration') {
      formattedVal = formatDataPoint(field, value);
    } else if (isNumericAbstractDataField(field)) {
      formattedVal = formatDataPoint(field, value);
      cellWrapperStyles.whiteSpace = 'nowrap';
    } else if (field.type === 'string') {
      // Makes urls clickable
      formattedVal = (
        <Linkify
          componentDecorator={(href, text) => (
            <a href={href} target="_blank" rel="noreferrer">
              {text}
            </a>
          )}>
          {value}
        </Linkify>
      );
    } else {
      formattedVal = value;
    }
    return <span style={cellWrapperStyles}>{formattedVal}</span>;
  };
  const Icon = dataTypeConfig[field.type].Icon;

  // const typeLabel = dataTypeConfig[field.type].label;
  // const unitLabel =
  //   dataTypeConfig[field.type].unitOptions?.find(unit => unit.value === field.unit)?.label ?? null;
  return helper.accessor(field.key, {
    cell: CellRender,
    header: () => {
      if (HeaderWrapper) {
        return (
          <HeaderWrapper field={field}>
            <span style={{ ...wrapperStyles, whiteSpace: 'nowrap' }}>
              {/* TODO: Add a tooltip later once we use the new HTML popover attributes 
  so we can break out of overflow hidden containers */}
              <Icon className="mr-1 inline-block size-4 align-middle" />
              {field.label}
            </span>
          </HeaderWrapper>
        );
      }
      return (
        <span style={{ ...wrapperStyles, whiteSpace: 'nowrap' }}>
          <Icon className="mr-1 inline-block size-3 align-middle" />
          {field.label}
        </span>
      );
    }
  });
}

export function useAbstractDataFieldTableColumns(
  fields: AbstractDataField[] | null,
  HeaderWrapper?: React.ComponentType<HeaderWrapperProps>
) {
  return useMemo<DataTableProps<any>['columns'] | null>(() => {
    if (!fields) return null;
    const helper = createColumnHelper<{ [key: string]: any }>();
    return fields.map(field => getColumnConfigFromDataField(field, helper, HeaderWrapper));
  }, [fields, HeaderWrapper]);
}
