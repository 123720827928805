import { format as dateFormat } from 'date-fns';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import { getMillisecondsForField } from '../utils/date';
export class BaseFieldTypeDesc {
    tickFormatter = (field, dataPoint) => {
        return dataPoint;
    };
    dataPointFormatter = (field, dataPoint) => {
        return dataPoint;
    };
    getAxisLabel = (field, combined) => {
        // A bit of a hack to work around awkward class implementation
        let mainLabel = combined
            ? this.dataLabel
            : field.label;
        if (isFunction(mainLabel))
            mainLabel = mainLabel(field);
        return field.unit ? `${mainLabel} (${field.unit})` : mainLabel;
    };
    getUnitLabelOptions = () => {
        const unitLabels = this.unitLabels;
        return Object.entries(unitLabels).map(([value, label]) => {
            return { value, label };
        });
    };
}
const dateTimeFields = [
    'dateTime',
    'date',
    'hour',
    'week',
    'month',
    'year'
];
export const getDateFromDateTimeField = (dataPoint, field) => {
    if (!dateTimeFields.includes(field.type)) {
        throw new Error('Not a dateTime field!');
    }
    // Days are stored as UTC time at midnight. We need to keep the same day, but switch UTC to the user's timezone
    const val = isPlainObject(dataPoint) ? get(dataPoint, field.key) : dataPoint;
    return new Date(getMillisecondsForField(Number(val), field));
};
export const getDateFormatter = (format) => (field, dataPoint) => {
    const date = getDateFromDateTimeField(dataPoint, field);
    // Sometimes, animations throw invalid dates. Just render nothing in this case
    if (isNaN(date))
        return '';
    return dateFormat(date, format);
};
