import { Loading } from 'axil-web-ui';
import React from 'react';

interface WidgetSpinnerProps {}

function WidgetSpinner(props: WidgetSpinnerProps) {
  return (
    <div className="flex h-full flex-col items-center justify-center p-4">
      <Loading size={64} color="primary" />
    </div>
  );
}

export default WidgetSpinner;
