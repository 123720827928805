import {
  Background,
  Controls,
  Edge,
  Handle,
  Node,
  NodeProps,
  NodeTypes,
  Position,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
  useStore as useReactFlowStore
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { cn, Table } from 'axil-web-ui';
import {
  AllGrouping,
  BaseLayerConfig,
  DataSourceConfigGrouping,
  groupingSchema,
  LayerConfig
} from 'daydash-data-structures';
import React, { createElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { dataTypeConfig } from 'src/components/common/dataTypeConfig';
import { useThemeMode } from 'src/components/ThemeProvider';
import { getAggName, getGroupByField } from 'src/core/dataFetching';
import { useDataFetch } from 'src/hooks';
import { useAbstractDataFieldTableColumns } from 'src/hooks/tables';
import './layerVisualizer.css';
import { isEqual } from 'lodash';

type NodeData = { layers: [BaseLayerConfig, ...LayerConfig[]]; idx: number };

export interface LayerVisualizerProps {
  layers: [BaseLayerConfig, ...LayerConfig[]] | null;
  selectedLayer: number;
  onSelectLayer: (layer: number) => void;
}

// const getHandleId = (nodeId: string, field: AbstractDataField) => `${nodeId}:::${field.name}`;

// type NodeHandle = NonNullable<Node['handles']>[number];

function LayerRenderer({ data: nodeData, id, selected }: NodeProps<Node<NodeData>>) {
  const { layers } = nodeData;
  const renderingInitialized = useNodesInitialized();
  const reactFlow = useReactFlow<Node<NodeData>>();
  const nodeCount = useReactFlowStore(s => s.nodes).length;
  const tableBodyElement = useRef<HTMLTableSectionElement>(null);
  const [measured, setMeasured] = useState(false);
  useEffect(() => {
    if (!measured && renderingInitialized) setMeasured(true);
  }, [renderingInitialized]);
  const enabled = useMemo(() => {
    if (layers.length === 0) return false;
    if (!layers[0].sectionId) return false;
    // TODO: Check this as part of the form system later
    return layers
      .slice(1)
      .every(layer => groupingSchema.safeParse((layer as LayerConfig).grouping).success);
  }, [layers]);

  const { data, isFetched } = useDataFetch(enabled ? layers : null, {
    selected: 'all',
    pageSize: 100
  });
  const fields = data?.fields ?? null;
  const columns = useAbstractDataFieldTableColumns(data?.fields ?? []);
  useEffect(() => {
    if (measured) reactFlow.fitView();
  }, [enabled, measured, isFetched]);
  if (!enabled) {
    return (
      <div
        className={cn(
          'bg-base-300 w-fit overflow-hidden rounded-lg p-4',
          selected ? 'outline-primary outline outline-2 outline-offset-1' : null,
          !measured ? 'invisible' : null
        )}>
        <p className="text-base-content">{`Grouping layer ${nodeData.idx}`}</p>
      </div>
    );
  }
  if (!data || !columns) return null;
  return (
    <Table
      zebra
      className={cn(
        'bg-base-300 w-fit rounded-lg p-4',
        selected ? 'outline-primary outline outline-2' : null,
        !measured ? 'invisible' : null
      )}>
      <tbody ref={tableBodyElement}>
        {fields?.map((field, idx) => (
          <tr key={field.name} data-field-name={field.name}>
            <td
              className={cn(
                'relative', // Needed to correctly position the handles
                idx === 0 ? 'rounded-t-lg' : null,
                idx === fields.length - 1 ? 'rounded-b-lg' : null
              )}>
              {nodeData.idx > 0 ? (
                <Handle
                  type="target"
                  position={Position.Left}
                  isConnectable={false}
                  id={`target:::${field.name}`}
                />
              ) : null}
              <div className="flex items-center gap-1">
                <span className="text-base-content">{field.label}</span>
                {createElement(dataTypeConfig[field.type].Icon, { width: 16, height: 16 })}
              </div>
              <Handle
                type="source"
                position={Position.Right}
                isConnectable={false}
                className={cn(nodeData.idx >= nodeCount - 1 ? 'invisible' : null)}
                id={`source:::${field.name}`}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const initialNodeFromLayer = (
  layers: [BaseLayerConfig, ...LayerConfig[]],
  idx: number,
  position?: { x: number; y: number }
): Node<NodeData> => ({
  id: String(idx),
  type: 'layerRenderer',
  position: position ?? { x: 0, y: 0 },
  selectable: true,
  draggable: true,
  data: {
    layers: layers.slice(0, idx + 1) as NodeData['layers'],
    idx
  }
});

const initialEdgeFromAggField = (
  layerId: string,
  prevLayerId: string,
  aggField: DataSourceConfigGrouping['aggregateFields'][number]
) => {
  const aggFieldName = getAggName(aggField);
  const edgeId = `${prevLayerId}:::${layerId}:::${aggField.fieldName}:::${aggFieldName}`;
  return {
    id: edgeId,
    source: prevLayerId,
    target: layerId,
    sourceHandle: `source:::${aggField.fieldName}`,
    // Taken from the core grouping component to derive field names. We need something better here though
    targetHandle: `target:::${aggFieldName}`,
    animated: true
  };
};

const initialEdgeFromGroupByField = (
  layerId: string,
  prevLayerId: string,
  grouping: Exclude<DataSourceConfigGrouping, AllGrouping>
) => {
  const groupByField = getGroupByField(grouping, null);
  const edgeId = `${prevLayerId}:::${layerId}:::${grouping.fieldName}:::${groupByField.name}`;
  return {
    id: edgeId,
    source: prevLayerId,
    target: layerId,
    sourceHandle: `source:::${grouping.fieldName}`,
    targetHandle: `target:::${groupByField.name}`,
    animated: true
  };
};

const nodeTypes: NodeTypes = { layerRenderer: LayerRenderer };

/**
 * Used to keep the selected layer in sync with the react flow selection
 */
function useSelectionSync(
  nodes: Node<NodeData>[],
  selectedLayer: number,
  onSelectLayer: (layer: number) => void
) {
  const nodeCount = nodes.length;
  const addSelectedNodes = useReactFlowStore(s => s.addSelectedNodes);
  const resetSelectedElements = useReactFlowStore(s => s.resetSelectedElements);
  const currentSelectedNodeId = useMemo(() => nodes.find(node => node.selected)?.id, [nodes]);
  useEffect(() => {
    if (!currentSelectedNodeId) return;
    if (String(selectedLayer) === currentSelectedNodeId) return;
    onSelectLayer(Number(currentSelectedNodeId));
  }, [currentSelectedNodeId]);
  useEffect(() => {
    if (String(selectedLayer) === currentSelectedNodeId || selectedLayer >= nodeCount) return;
    resetSelectedElements();
    addSelectedNodes([String(selectedLayer)]);
  }, [selectedLayer, nodeCount]);
}

/**
 * Sync the react flow nodes with the layer config
 */
function useLayerNodeSync(
  layers: [BaseLayerConfig, ...LayerConfig[]] | null,
  nodes: Node<NodeData>[]
) {
  const reactFlow = useReactFlow<Node<NodeData>>();
  useEffect(() => {
    const nodeIdToNode = new Map(nodes.map(n => [n.id, n]));
    layers?.forEach((layer, idx) => {
      if (nodeIdToNode.has(String(idx))) {
        const node = nodeIdToNode.get(String(idx));
        const newNodeData = {
          layers: layers.slice(0, idx + 1) as NodeData['layers'],
          idx
        };
        if (!isEqual(node?.data, newNodeData)) {
          reactFlow.updateNodeData(String(idx), newNodeData);
        }
      } else {
        const prevNode = reactFlow.getNode(String(idx - 1));
        const position =
          prevNode && prevNode.measured?.width
            ? {
                x: prevNode.position.x + (prevNode.measured?.width ?? 100) + 50,
                y: prevNode.position.y
              }
            : { x: idx * 200, y: 0 };
        reactFlow.addNodes(initialNodeFromLayer(layers, idx, position));
      }
    });
    const seenNodeIds = new Set<string>();
    nodeIdToNode.forEach((node, id) => {
      // Do the extra seenNodeIds check to delete dupes. There are some timing issues with adding nodes in react-flow
      if (!layers || layers.length <= Number(id) || seenNodeIds.has(id)) {
        reactFlow.deleteElements({ nodes: [{ id }] });
      }
      seenNodeIds.add(id);
    });
  }, [reactFlow, layers, nodes]);
}

function useLayerEdgeSync(layers: [BaseLayerConfig, ...LayerConfig[]] | null) {
  const reactFlow = useReactFlow<Node<NodeData>>();
  useEffect(() => {
    const allEdges = reactFlow.getEdges();
    const checkedEdgeIds = new Set<string>();
    const currentEdgeIds = new Set(allEdges.map(e => e.id));
    try {
      const newEdges: Edge[] = [];
      layers?.forEach((layer, idx) => {
        if (idx === 0) return;
        const grouping = (layer as LayerConfig).grouping;
        if (!grouping) return;
        const aggFields = grouping.aggregateFields;
        if (!aggFields) return;
        aggFields.forEach(field => {
          if (field.enabled === false) return;
          const edge = initialEdgeFromAggField(String(idx), String(idx - 1), field);
          if (currentEdgeIds.has(edge.id)) {
            checkedEdgeIds.add(edge.id);
          } else {
            newEdges.push(edge);
          }
        });
        if (grouping.type !== 'all') {
          const edge = initialEdgeFromGroupByField(String(idx), String(idx - 1), grouping);
          if (currentEdgeIds.has(edge.id)) {
            checkedEdgeIds.add(edge.id);
          } else {
            newEdges.push(edge);
          }
        }
      });
      reactFlow.addEdges(newEdges);
      allEdges.forEach(edge => {
        if (!checkedEdgeIds.has(edge.id)) {
          reactFlow.deleteElements({ edges: [{ id: edge.id }] });
        }
      });
    } catch (e) {
      // Adding a try catch here because the grouping could be partial and cause errors during form editing
      console.error(e);
    }
  }, [reactFlow, layers]);
}

const getBoundingBoxForNode = (
  position: { x: number; y: number },
  width: number,
  height: number
) => {
  return {
    left: position.x,
    top: position.y,
    right: position.x + width,
    bottom: position.y + height,
    width,
    height
  };
};
function useAutoLayout(nodes: Node<NodeData>[]) {
  const reactFlow = useReactFlow<Node<NodeData>>();
  const renderingInitialized = useNodesInitialized();
  useLayoutEffect(() => {
    if (!renderingInitialized) return;
    const upToDateBounds = new Map<number, ReturnType<typeof getBoundingBoxForNode>>();
    [...nodes]
      .sort((a, b) => a.data.idx - b.data.idx)
      .forEach((node, idx) => {
        upToDateBounds.set(
          idx,
          getBoundingBoxForNode(
            node.position,
            node.measured?.width ?? 100,
            node.measured?.height ?? 100
          )
        );
        if (idx === 0) return; // Skip the first node
        const prevBounds = upToDateBounds.get(idx - 1);
        const bounds = upToDateBounds.get(idx);
        if (!prevBounds || !bounds) throw new Error('Invalid bounds');
        if (prevBounds.right > bounds.left) {
          const newPosition = { x: prevBounds.right + 100, y: bounds.top };
          reactFlow.updateNode(node.id, { position: newPosition });
          upToDateBounds.set(idx, getBoundingBoxForNode(newPosition, bounds.width, bounds.height));
        }
      });
    requestAnimationFrame(() => {
      reactFlow.fitView();
    });
  }, [renderingInitialized, reactFlow]);
}
function LayerVisualizer({ layers, selectedLayer, onSelectLayer }: LayerVisualizerProps) {
  const themeMode = useThemeMode();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<NodeData>>(
    layers?.map((_, idx) => initialNodeFromLayer(layers, idx)) ?? []
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);
  useSelectionSync(nodes, selectedLayer, onSelectLayer);
  useLayerNodeSync(layers, nodes);
  useLayerEdgeSync(layers);
  useAutoLayout(nodes);
  return (
    <div className="h-full w-full">
      <ReactFlow<Node<NodeData>>
        colorMode={themeMode}
        nodeTypes={nodeTypes}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        fitView
        attributionPosition="bottom-right">
        <Background className="bg-transparent" />
        <Controls showInteractive={false} position="bottom-right" />
      </ReactFlow>
    </div>
  );
}

// Just a wrapper to get the react flow provider in there
export default function LayerVisualizerWrapper(props: LayerVisualizerProps) {
  return (
    <ReactFlowProvider>
      <LayerVisualizer {...props} />
    </ReactFlowProvider>
  );
}
