import { Loading } from 'axil-web-ui';
import React, { useEffect, useState } from 'react';

export interface PageLoaderProps {
  title?: string;
  delay?: number;
}

export default function PageLoader({ delay = 1000, title }: PageLoaderProps) {
  const [sufficientlyWaited, setSufficientlyWaited] = useState(false);
  useEffect(() => {
    const timeout = window.setTimeout(() => setSufficientlyWaited(true), delay);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);
  return sufficientlyWaited ? (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8">
      {title ? (
        <p className="m-4 text-balance text-center text-2xl font-bold leading-relaxed">{title}</p>
      ) : null}
      <Loading size={48} color="primary" />
    </div>
  ) : null;
}
