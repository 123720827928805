import { TextInput, ToggleGroup } from 'axil-web-ui';
import React, { useEffect, useMemo, useRef } from 'react';

import { useForm } from '@tanstack/react-form';

import { zodValidator } from '@tanstack/zod-form-adapter';
import { UserPreferences } from 'daydash-data-structures';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  currentUserAtom,
  removeProfileImageAtom,
  updateProfileImageAtom,
  updateUserAtom,
  userProfileImageAtom
} from 'src/atoms/user.atoms';
import { getFieldDisplayErrors } from 'src/utils/form';
import { z } from 'zod';
import SubmitButton from '../common/forms/SubmitButton';
import { userUpdateSchema } from './common';

const AppAppearanceOptions = [
  {
    label: 'Light',
    value: 'light'
  },
  {
    label: 'Dark',
    value: 'dark'
  },
  {
    label: 'Match System',
    value: 'system'
  }
] satisfies {
  label: string;
  value: UserPreferences['appearance'];
}[];

function ProfileImage() {
  const updateProfileImage = useSetAtom(updateProfileImageAtom);
  const profileImage = useAtomValue(userProfileImageAtom);
  const imageFileInputRef = useRef<HTMLInputElement>(null);
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) updateProfileImage(file);
  };
  if (!profileImage) {
    return null;
  }
  return (
    <>
      <button onClick={() => imageFileInputRef.current?.click()}>
        <img src={profileImage} alt="Profile Image" className="size-16 rounded-full" />
      </button>
      <input
        className="hidden"
        ref={imageFileInputRef}
        type="file"
        accept={'image/*'}
        onChange={handleFileInputChange}
      />
    </>
  );
}
function GeneralSettings() {
  const currentUser = useAtomValue(currentUserAtom);
  const updateUser = useSetAtom(updateUserAtom);
  const defaultValues = useMemo(() => userUpdateSchema.parse(currentUser), [currentUser]);
  const form = useForm<z.infer<typeof userUpdateSchema>, ReturnType<typeof zodValidator>>({
    defaultValues: defaultValues,
    validatorAdapter: zodValidator(),
    validators: { onChange: userUpdateSchema },
    onSubmit: ({ value }) => updateUser(userUpdateSchema.parse(value))
  });
  useEffect(() => {
    form.reset();
  }, [currentUser]);
  return (
    <div className="mx-auto w-full max-w-lg py-4">
      <h2 className="mb-8 text-2xl">General Settings</h2>
      <div className="flex items-center gap-6">
        <ProfileImage />
        <h4>{`Email: ${currentUser?.emailAddresses[0]}`}</h4>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate
        className="mb-8">
        <div className="my-8 flex flex-wrap justify-stretch gap-4">
          <form.Field name="firstName">
            {field => (
              <TextInput
                className="grow"
                name={field.name}
                label="First Name"
                error={getFieldDisplayErrors(field)}
                value={field.state.value ?? ''}
                onChange={e => field.handleChange(e.target.value)}
                onBlur={field.handleBlur}
              />
            )}
          </form.Field>
          <form.Field name="lastName">
            {field => (
              <TextInput
                className="grow"
                name={field.name}
                label="Last Name"
                error={getFieldDisplayErrors(field)}
                value={field.state.value ?? ''}
                onChange={e => field.handleChange(e.target.value)}
                onBlur={field.handleBlur}
              />
            )}
          </form.Field>
        </div>
        <div className="my-4">
          <form.Field name="preferences.appearance">
            {field => (
              <ToggleGroup
                options={AppAppearanceOptions}
                className="flex-wrap gap-2 sm:gap-4"
                optionContainerClassName="grow"
                label="Appearance"
                name="appearance"
                value={field.state.value ?? null}
                onSelect={val => field.handleChange(val as UserPreferences['appearance'])}
              />
            )}
          </form.Field>
        </div>
        <div className="py-4">
          <SubmitButton form={form} label="Save" />
        </div>
      </form>
      <p className="mh-auto w-full max-w-full">
        {`To change your email, please email us at `}
        <a
          target="_blank"
          href={`mailto:support@daydash.io?subject=Email%20change`}
          rel="noreferrer">
          support@daydash.io
        </a>
      </p>
    </div>
  );
}

export default GeneralSettings;
